import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	FormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../../_services'

import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ToastrService } from 'ngx-toastr'

import { DataSource } from '@angular/cdk/table'
import { ToolsService } from '../../../_services/tools.service'
import { DatePipe } from '@angular/common'

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { Globals } from '../../../globals/globals'

@Component({
	templateUrl: 'bank-entity.component.html',
	styleUrls: [
		'bank-entity.component.scss',
		'../campaigns/campaigns.component.scss',
		'../../reports/reports.scss',
		'../../administation/administration.scss',
	],
	providers: [DatePipe, Globals],
})
export class BankEntityComponent implements OnInit {
	permissions
	loading = false // for disable fast response click
	isLoadingDetailInfo = false
	currentBankEntity = null

	displayedColumns: string[] = [
		'Code',
		'Name',
		'Address',
		'PaymentSystem',
		'Actions',
	]

	displayedColumnsBBranch: string[] = ['Code', 'Name', 'Actions']

	dataSource = new MatTableDataSource() // array of datas
	dataSourceBBranch = new MatTableDataSource() // array of datas
	dialogBranch = null

	constructor(
		public dialog: MatDialog,
		public toastr: ToastrService,
		// Make service for post api datas
		public translate: TranslateService,
		private dataService: ToolsService,
		public route: ActivatedRoute
	) //private formBuilderBEntity: FormBuilder,

	{}

	FilterForm: UntypedFormGroup // Form initial
	formBuilderBEntity: UntypedFormGroup // Form initial
	formBuilderBranchEntity: UntypedFormGroup // Form initial
	//------------
	onBankEntityNewOrEdit(templateRef, obj) {
		this.formBuilderBEntity.controls['Name'].setValue(
			obj == null ? null : obj.Name
		)
		this.formBuilderBEntity.controls['Address'].setValue(
			obj == null ? null : obj.Address
		)
		this.formBuilderBEntity.controls['System'].setValue(
			obj == null ? null : obj.PaymentSystem
		)
		this.formBuilderBEntity.controls['Code'].setValue(
			obj == null ? null : obj.Code
		)
		this.formBuilderBEntity.controls['Key'].setValue(
			obj == null ? null : obj.Key
		)

		// if(obj === null)
		//    this.formBuilderBEntity.markAllAsTouched()

		this.dialog.open(templateRef, {})
	}

	//------------------------------------------ get list of records from service ----------------------------------
	getItems() {
		this.loading = true
		this.dataService
			.getBanks(this.FilterForm.value.Code, this.FilterForm.value.Name)
			.subscribe(
				(data) => {
					console.log(data)
					let datas = []
					if (data.ResultCode === 0 && data.ReturnObject !== null)
						datas = data.ReturnObject

					this.dataSource = new MatTableDataSource(datas) // array of datas
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	getBranchList(templateRef, row) {
		if (this.loading) return
		this.loading = true
		this.currentBankEntity = row.Key
		this.dataService.getBankBranch(row.Key).subscribe(
			(data) => {
				console.log(data)
				let datas = []
				if (data.ResultCode === 0 && data.ReturnObject !== null) {
					datas = data.ReturnObject
				}

				this.dataSourceBBranch = new MatTableDataSource(datas) // array of datas
				//---------- not create dialog window
				if (templateRef !== null) this.dialog.open(templateRef, {})
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log(error)
			}
		)
	}

	onBBranchEdit(templateRef, obj) {
		this.formBuilderBranchEntity.controls['Name'].setValue(
			obj == null ? null : obj.Name
		)
		this.formBuilderBranchEntity.controls['Code'].setValue(
			obj == null ? null : obj.Code
		)
		this.formBuilderBranchEntity.controls['Key'].setValue(
			obj == null ? null : obj.Key
		)

		// if(obj === null)
		//   this.formBuilderBranchEntity.markAllAsTouched()

		this.dialogBranch = this.dialog.open(templateRef, {})
	}

	//-------------- dialog handler
	CloseDialog(templateRef = null): void {
		if (templateRef == 'infoBranchDetails') {
			this.dialogBranch.close()
		} else this.dialog.closeAll()
	}
	//------------------ create or update bank entity
	onSaveObject() {
		if (this.formBuilderBEntity.valid) {
			//-------------- save data on the server, create request --------------------------
			this.dataService
				.postBanksNewUpdate(
					this.formBuilderBEntity.controls['Key'].value,
					this.formBuilderBEntity.controls['Code'].value,
					this.formBuilderBEntity.controls['Name'].value,
					this.formBuilderBEntity.controls['Address'].value,
					this.formBuilderBEntity.controls['System'].value
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.CloseDialog()
							let theme = ''
							this.translate
								.get('TOOLS.SERVICE_SUCCESS')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.success(data.ResultMessage, theme)
							this.loading = false
							this.getItems()
						} else {
							this.loading = false
							let theme = ''
							this.translate
								.get('TOOLS.SERVICE_ERROR')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.error(data.ResultMessage, theme)
						}
					},
					(error) => {
						this.loading = false
						console.log(error)
					}
				)
		}
	}
	//------------------ create or update Branch of bank
	onBranchSaveObject() {
		if (this.formBuilderBranchEntity.valid) {
			this.loading = true

			//-------------- save data on the server, create request --------------------------
			this.dataService
				.postBranckNewUpdate(
					this.currentBankEntity,
					this.formBuilderBranchEntity.controls['Key'].value,
					this.formBuilderBranchEntity.controls['Code'].value,
					this.formBuilderBranchEntity.controls['Name'].value
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.CloseDialog('infoBranchDetails')
							this.loading = false
							let theme = ''
							this.translate
								.get('TOOLS.SERVICE_SUCCESS')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.success(data.ResultMessage, theme)
							this.getBranchList(null, {
								Key: this.currentBankEntity,
							})
						} else {
							this.loading = false
							let theme = ''
							this.translate
								.get('TOOLS.SERVICE_ERROR')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.error(data.ResultMessage, theme)
						}
					},
					(error) => {
						this.loading = false
						console.log(error)
					}
				)
		}
	}
	//-------------------------- init of controller  ------------------------------
	ngOnInit() {
		console.log('Bank entity onInit!')
		this.FilterForm = new UntypedFormGroup({
			Code: new UntypedFormControl(''),
			Name: new UntypedFormControl(''),
		})

		this.formBuilderBEntity = new UntypedFormGroup({
			// IDNO: ["", [Validators.required, Validators.pattern(/^([0-9]{1,20})$/)]],
			Key: new UntypedFormControl(),
			Name: new UntypedFormControl('', [Validators.required]),
			Address: new UntypedFormControl('', [Validators.required]),
			System: new UntypedFormControl('', [Validators.required]),
			Code: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(/^([0-9]{1,2})$/),
			]),
		})

		this.formBuilderBranchEntity = new UntypedFormGroup({
			Key: new UntypedFormControl(),
			Name: new UntypedFormControl('', [Validators.required]),
			Code: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(/^([0-9]{1,4})$/),
			]),
		})

		//------------------ get data
		this.loading = true
		this.getItems()
	}

	/*--------- helps events---------------- */
	onKeyDownOnlyDigit(event) {
		let allowCodes = [
			8, 9, 17, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56,
			57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110,
		]

		if (allowCodes.indexOf(event.keyCode) == -1) {
			event.preventDefault()
		}
	}
	onlyNumeric(val, e) {
		let selectorId = e.target.id
		var inputValue = <HTMLInputElement>document.getElementById(selectorId)
		var _val = val.replace(/[^\d]/g, '')
		inputValue.value = _val
	}
}
