import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { AuthenticationService } from './authentication.service'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'

@Injectable()
export class NotificationService {
	constructor(
		private http: HttpClient,
		private authService: AuthenticationService
	) {}

	getNotificationData(
		StartDate,
		EndDate,
		PeriodType,
		Filter,
		search,
		PageCount,
		CurrentPage
	) {
		return this.http
			.get<any>(
				this.authService.basUrl +
					`/api/Notification/Gets?` +
					`pStartDate=` +
					StartDate +
					`&pEndDate=` +
					EndDate +
					`&pPeriodType=` +
					PeriodType +
					`&pCategory=` +
					Filter.Category +
					`&pStatus=` +
					Filter.Status +
					`&pType=` +
					Filter.Type +
					`&pBankCode=` +
					Filter.BankCode +
					`&pSourceType=` +
					Filter.NotificationSourceType +
					'&pTitle=' +
					search +
					`&pItemPerPage=` +
					PageCount +
					`&pCurrentPage=` +
					CurrentPage,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getBankCode() {
		return this.http
			.get<any>(this.authService.basUrl + `/api/merchant/getbanks`, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': this.GetLang(),
				},
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getCampaing(status, description, fetchPage, fetchCount, lang) {
		let languageKey: string = ''

		if (lang === 'tr') {
			languageKey = '69ee6082-77d3-417c-b6f7-c64ed1b5c691'
		} else if (lang === 'en') {
			languageKey = 'ae10158f-17cd-4974-ad98-c15774b2b3e8'
		} else if (lang === 'ru') {
			languageKey = 'a270d233-1c45-4550-b48f-68c7424303c2'
		}
		return this.http
			.get<any>(
				this.authService.basUrl +
					`/api/Campaign/Gets?&pStatus=${status}
					&pFetchPage=${fetchPage}
					&pFetchCount=${fetchCount}
					&pLanguageKey=${languageKey}`,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	createNotification(
		fileContent,
		fileName,
		category,
		sourceType,
		value,
		//status,
		type,
		bankCode,
		nameEn,
		textEn,
		nameTr,
		textTr,
		nameRu,
		textRu
	) {
		let body = {
			FileContent: fileContent,
			FileName: fileName,
			Category: category,
			SourceType: sourceType,
			Value: value,
			//Status: status,
			Type: type,
			BankCode: bankCode,
			NameEn: nameEn,
			TextEn: textEn,
			NameTr: nameTr,
			TextTr: textTr,
			NameRu: nameRu,
			TextRu: textRu,
		}
		return this.http
			.post<any>(
				this.authService.basUrl +
					`/api/Notification/CreateNotification`,
				body,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)

			.pipe(
				map((response) => {
					console.log(response)
					return response
				})
			)
	}

	saveNotification(
		id,
		type,
		category,
		sourceType,
		value,
		status,
		bankCode,
		fileContent,
		fileName,
		nameEn,
		textEn,
		nameTr,
		textTr,
		nameRu,
		textRu,
		userName
	) {
		let body = {
			Id: id,
			Type: type,
			Category: category,
			SourceType: sourceType,
			Value: value,
			Status: status,
			BankCode: bankCode,
			FileContent: fileContent,
			FileName: fileName,
			NameEn: nameEn,
			NameRu: nameRu,
			NameTr: nameTr,
			TextEn: textEn,
			TextRu: textRu,
			TextTr: textTr,
			UserName: userName,
		}
		return this.http
			.post<any>(
				this.authService.basUrl + `/api/Notification/SaveNotification`,
				body,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	exportExcel(
		StartDate,
		EndDate,
		PeriodType,
		Filter,
		PageCount,
		CurrentPage
	) {
		return this.http.get(
			this.authService.basUrl +
				`/api/Notification/ExportToExcel?` +
				`&pStartDate=` +
				StartDate +
				`&pEndDate=` +
				EndDate +
				`&pPeriodType=` +
				PeriodType +
				`&pCategory=` +
				Filter.Category +
				`&pType=` +
				Filter.Type +
				`&pSourceType=` +
				Filter.NotificationSourceType +
				`&pBankCode=` +
				Filter.BankCode +
				`&pStatus=` +
				Filter.Status +
				`&pTitle=` +
				Filter.SearchByHeader +
				`&pItemPerPage=` +
				PageCount +
				`&pCurrentPage=` +
				CurrentPage,
			{
				responseType: 'blob',
				headers: {
					'Accept-Language': this.GetLang(),
				},
			}
		)
	}

	editAction(id) {
		return this.http
			.get<any>(
				this.authService.basUrl +
					`/api/Notification/Get?` +
					`pNotificationId=` +
					id,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	ProcessSet(id, status) {
		// let body = {
		// 	Id: id,
		// 	Status: status,
		// }
		return this.http
			.post<any>(
				this.authService.basUrl +
					`/api/Notification/ProcessSet?
					pNotificationId=${id}&pStatus=${status}`,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	downloadAction(id) {
		return this.http.get(
			this.authService.basUrl +
				`/api/Notification/NotificationDetailsFile?id=${id}`,
			{
				responseType: 'blob',
				headers: {
					'Accept-Language': this.GetLang(),
				},
			}
		)
	}

	// getTestUser(id: number) {
	// 	return this.http
	// 		.get(
	// 			this.authService.basUrl +
	// 				`/api/notification/GetTestUsers/${id}`,
	// 			{
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					'Accept-Language': this.GetLang(),
	// 				},
	// 			}
	// 		)
	// 		.pipe(
	// 			map((response) => {
	// 				return response
	// 			})
	// 		)
	// }
	getCountUser(id: number) {
		return this.http
			.get(
				this.authService.basUrl +
					`/api/notification/GetUserCount/${id}`,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getNotificationReport(id: number) {
		return this.http.get(
			this.authService.basUrl +
				`/api/notification/GetNotificationReport/${id}`,
			{
				responseType: 'blob',
				headers: {
					'Accept-Language': this.GetLang(),
				},
			}
		)
	}

	//------------------------------------------- private functions
	private GetLang() {
		switch (localStorage.getItem('currentLang')) {
			case 'ru':
				return 'ru-RU'
			case 'tr':
				return 'tr-TR'
		}
		return 'en-US'
	}
}
