import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../_services";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { DateAdapter } from "@angular/material/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DataSource } from "@angular/cdk/table";
import { ReportsDatasService } from "../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
import { saveAs } from "file-saver";

/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../../globals/globals";

export interface Merchant {
  id: string;
  Name: string;
}

@Component({
  templateUrl: "deposit-operations.component.html",
  styleUrls: ["reports.scss"],
  providers: [DatePipe, Globals],
})
export class DepositOperationsComponent implements OnInit {
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api

  permissions;

  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  isDownloadingFile: boolean = false; // spinner
  Info;
  radioModel = 1;
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  allPageLoad: boolean = false;
  AccountCodes = [{ Code: "", Name: "" }];
  MerchantList: Merchant[] = [{ id: "", Name: "" }];
  filteredOptions: Observable<Merchant[]>;

  displayFn(user?: Merchant): string | undefined {
    return user ? user.Name : undefined;
  }

  private _filter(name: string): Merchant[] {
    const filterValue = name.toLowerCase();
    return this.MerchantList.filter(
      (option) => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayedColumns: string[] = [
    "Details",
    "FOID",
    "RegDate",
    "Expenses",
    "Receipt",
    "Balance",
  ];
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private reportsDataService: ReportsDatasService,
    private datePipe: DatePipe,
    public route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private globals: Globals
  ) {}

  FilterForm: UntypedFormGroup; // Form initial

  totalExpenses: Number = 0;
  totalReceipt: Number = 0;
  totalBalance: Number = 0;
  startBalance: Number = 0;

  AccountCodeGets() {
    this.loading = true;
    this.reportsDataService
      .getAccountCodes()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.AccountCodes = data.ReturnObject;
            this.FilterForm.patchValue({
              ["AccountCode"]: this.AccountCodes[0].Code,
            });
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.MerchantsGets();
        }
      );
  }

  MerchantsGets() {
    this.reportsDataService
      .getMerchantList()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.MerchantList = data.ReturnObject;
            this.filteredOptions =
              this.FilterForm.controls.MerchantId.valueChanges.pipe(
                startWith(this.MerchantList[0]),
                map((value) =>
                  typeof value === "string" ? value : value.Name
                ),
                map((name) =>
                  name
                    ? this._filter(name.toString())
                    : this.MerchantList.slice()
                )
              );
            this.FilterForm.controls.MerchantId.setValue(this.MerchantList[0]);
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
          this.getFirstDatas(this.radioModel);
        }
      );
  }

  makePDF() {
    if (this.loading === false && this.isDownloadingFile === false) {
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );
      this.isDownloadingFile = true;
      this.reportsDataService
        .getStatementsPDF(
          this.FilterForm.value.AccountCode,
          start,
          finish,
          this.radioModel,
          this.paginationItemPerPage,
          this.currentPage + 1,
          this.FilterForm.value.MerchantId.id
        )
        .pipe(first())
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(
              res,
              "DepositOperationsReport_" +
                this.datePipe.transform(
                  this.FilterForm.controls.dateTo.value,
                  "yyyy-MM-dd"
                ) +
                "." +
                "pdf",
              { type: "application/pdf" }
            );
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  getFirstDatas(period) {
    if (this.FilterForm.valid) {
      this.loading = true;
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );
      this.radioModel = period;
      this.reportsDataService
        .getStatementsDatas(
          this.FilterForm.value.MerchantId.id,
          this.FilterForm.value.AccountCode,
          start,
          finish,
          period,
          this.paginationItemPerPage,
          this.currentPage + 1
        )
        .pipe(first())
        .subscribe(
          (data) => {
            console.log(data);
            console.log(this.dataSource.data);
            let datas = [];
            this.paginationItems = 0;
            this.totalExpenses = 0;
            this.totalReceipt = 0;
            this.totalBalance = 0;
            this.startBalance = 0;
            if (data.ResultCode === 0) {
              if (data.ReturnObject !== null) {
                datas = data.ReturnObject.Statement;
                this.totalExpenses = data.ReturnObject.Turnover.TotalExpenses;
                this.totalReceipt = data.ReturnObject.Turnover.TotalReceipt;
                this.totalBalance = data.ReturnObject.Turnover.EndBalance;
                this.startBalance = data.ReturnObject.Turnover.BeginBalance;
                this.paginationItems = data.ReturnObject.Fetch.Cnt;
              }
            }
            this.translate
              .get("REPORT.BEFORE_BALANCE")
              .subscribe((total_translate) => {
                datas.unshift({
                  AccountId: "",
                  Amount: "",
                  BeginBalance: this.startBalance,
                  Description: "",
                  EndBalance: "",
                  Expenses: null,
                  FOId: null,
                  Receipt: null,
                  RegDate: "",
                  Title: total_translate,
                  Footer: 1,
                });
              });

            this.translate
              .get("REPORT.TOTAL_BALANCE")
              .subscribe((total_translate) => {
                datas.push({
                  AccountId: "",
                  Amount: "",
                  BeginBalance: this.totalBalance,
                  Description: "",
                  EndBalance: "",
                  Expenses: null,
                  FOId: null,
                  Receipt: null,
                  RegDate: "",
                  Title: total_translate,
                  Footer: 1,
                });
              });

            if (datas.length > 2) {
              this.translate
                .get("REPORT.TOTAL_EXPENSES_RECEIPT")
                .subscribe((total_translate) => {
                  datas.push({
                    AccountId: "",
                    Amount: "",
                    BeginBalance: null,
                    Description: "",
                    EndBalance: "",
                    Expenses: this.totalExpenses,
                    FOId: null,
                    Receipt: this.totalReceipt,
                    RegDate: "",
                    Title: total_translate,
                    Footer: 1,
                  });
                });
            }

            this.dataSource = new MatTableDataSource(datas); // array of datas
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.log(error);
          }
        );
    }
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }

  ngOnInit() {
    // console.log(this.permissions);
    this.paginationItemPerPage = 10;
    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      AccountCode: new UntypedFormControl("", Validators.required),
      MerchantId: new UntypedFormControl("", Validators.required),
    }); // for header Filter
    this.AccountCodeGets();

    let ls = new SecureLS(); // for Secure Local storage
    if (ls.get("info") !== null) {
      this.Info = ls.get("info");
    }
    this.permissions =
      ls.get("permissions")[this.route.data["value"].pathCheck];
  }
}
