import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms"
import { Observable } from "rxjs"
import { map, startWith, first } from "rxjs/operators"
import { AlertService, UserService, ProviderService } from "../../../_services"
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator"
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table"
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog"
import { DataSource } from "@angular/cdk/table"
import { ReportsDatasService } from "../../../_services/reportsdata.service"
import { DatePipe } from "@angular/common"
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls"
import { TransactionService } from "../../../_services/transaction.service"
import { TranslateService } from "@ngx-translate/core"
import { saveAs } from "file-saver"
import { ToastrService } from "ngx-toastr"
import { Globals } from "../../../globals/globals"

import { ActivatedRoute } from "@angular/router"

@Component({
  templateUrl: "./acquiring-report.component.html",
  styleUrls: ["../transactions.scss"],
  providers: [DatePipe, Globals],
})
export class AcquiringReportComponent implements OnInit {
  public paginationItems // for display pagination from api
  public paginationItemPerPage // for display pagination from api
  allPageLoad: boolean = false
  isDownloadingFile: boolean = false
  permissions
  modalDatas = {
    RegistrationDate: "",
    InternalID: "",
    Status: 0,
    Fee: 0,
    Amount: 0,
    MerchantName: "",
    Description: "",
    PaidDate: null,
    MobilePhone: "",
    RRN: "",
    PaymentMethod: 0,
    ProductType: 0,
  }
  // Amount: 4500;
  // CardNumber: null;
  // Description: "Description";
  // InternalID: null;
  // MerchantName: "Goriunov SRL";
  // MobilePhone: "37379300123";
  // OrderNumber: "00000000084";
  // PaidDate: null;
  // PaymentMethod: 0;
  // ProductType: 1;
  // RRN: null;
  // RegistrationDate: "2020-11-24T12:59:32.367";
  // Status: 1;
  modalTransactionsList = []

  moneyProviderList = [{ ProviderId: 0, ProviderName: "ALL" }]

  radioModel = 1
  public currentPage = 0
  isLoadingDetailInfo: boolean = true // spinner
  Info
  loading = false // for disable fast response click
  pageEvent // for fix error of pageEvent in Visual Studio
  panelOpenState = true
  // For change pages
  minDate = new Date(2000, 0, 1) // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
  events: string[] = [] // Date picker
  currentTime = new Date()
  AccountCodes
  refundError = ""
  displayedColumns: string[] = [
    "Date",
    "OrderNumber",
    "TransactionType",
    "PayMethod",
    "Description",
    "Amount",
    "Status",
  ]
  dataSource = new MatTableDataSource() // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private datePipe: DatePipe,
    private transactionService: TransactionService,
    public toastr: ToastrService,
    private globals: Globals,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {}

  FilterForm: UntypedFormGroup // Form initial
  RefundForm: UntypedFormGroup

  CloseDialog(): void {
    this.dialog.closeAll()
  }

  getFirstDatas(period) {
    this.loading = true
    this.radioModel = period
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    )
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    )
    let filter_status = null
    if (this.FilterForm.value.Status !== "null") {
      filter_status = this.FilterForm.value.Status
    }

    // this.transactionService
    //   .getsStatement(
    //     start,
    //     finish,
    //     period,
    //     this.FilterForm.value.OrderNumber,
    //     this.FilterForm.value.Amount,
    //     this.FilterForm.value.Status,
    //     this.FilterForm.value.PaymentType,
    //     this.paginationItemPerPage,
    //     this.currentPage + 1
    //   )
    //   .subscribe(
    //     (data) => {
    //       let datas = [];
    //       this.paginationItems = 0;
    //       if (data.ResultCode === 0) {
    //         if (data.ReturnObject !== null) {
    //           datas = data.ReturnObject.Sales;
    //           this.paginationItems = data.ReturnObject.Fetch.Cnt;
    //         }
    //       }
    //       this.dataSource = new MatTableDataSource(datas); // array of datas
    //       this.loading = false;
    //     },
    //     (error) => {
    //       this.loading = false;
    //       console.log(error);
    //     },
    //     () => {
    //       this.allPageLoad = true;
    //     }
    //   );
    this.allPageLoad = true
    this.loading = false
    this.dataSource = new MatTableDataSource(this.getTestData())
  }
  getTestData() {
    let tempArray = []
    for (let i = 0; i < 10; i++) {
      tempArray.push({
        Date: "04-01-21" + i,
        OrderNumber: i,
        Type: "Type" + i,
        PayMethod: "Method" + i,
        Description: "Description" + i,
        Amount: Math.random() > 0.5 ? +i + 10 : "-" + i + "10",
        Status: "Status" + i,
      })
    }
    return tempArray
  }

  // makeRefund() {
  //   if (this.RefundForm.valid === true) {
  //     this.loading = true;
  //     this.refundError = "";
  //     console.log(this.RefundForm.value);
  //     let Type = this.RefundForm.value.Type;
  //     this.transactionService
  //       .makeRefund(
  //         this.modalDatas.Id,
  //         Type,
  //         Math.round(Number(this.RefundForm.value.Amount) * 100),
  //         this.RefundForm.value.Comment
  //       )
  //       .pipe(first())
  //       .subscribe(
  //         (data) => {
  //           console.log(data);
  //           if (data.ResultCode === 0) {
  //             let theme = "";
  //             let body = "";
  //             this.translate
  //               .get("REPORT.THEME_SUCCESS_REFUND")
  //               .subscribe((text) => {
  //                 theme = text;
  //               });
  //             this.translate
  //               .get("REPORT.BODY_SUCCESS_REFUND")
  //               .subscribe((text) => {
  //                 body = text;
  //               });
  //             this.toastr.success(body, theme);
  //             this.CloseDialog();
  //             this.getFirstDatas(this.radioModel);
  //           } else {
  //             let theme = "";
  //             data.ResultMessage === null
  //               ? (this.refundError = "")
  //               : (this.refundError = data.ResultMessage);
  //             this.translate
  //               .get("REPORT.THEME_ERROR_REFUND")
  //               .subscribe((text) => {
  //                 theme = text;
  //               });
  //             this.toastr.error(this.refundError, theme);
  //           }
  //           this.loading = false;
  //         },
  //         (error) => {
  //           this.loading = false;
  //           this.toastr.error();
  //           console.log(error);
  //         },
  //         () => {
  //           this.allPageLoad = true;
  //         }
  //       );
  //   }
  // }

  getMoneyProviders() {
    this.transactionService.getMoneyProviders().subscribe(
      (data) => {
        console.log(data)
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.moneyProviderList = data.ReturnObject
          }
        }
        this.loading = false
      },
      (error) => {
        this.loading = false
        console.log(error)
      },
      () => {
        this.FilterForm.patchValue({
          ["PaymentType"]: this.moneyProviderList[0].ProviderId,
        })
        this.getFirstDatas(this.radioModel)
      }
    )
  }

  convertOnBlur(searchValue, form_group) {
    if (searchValue > 0) {
      this[form_group].patchValue({
        ["Amount"]: parseFloat(searchValue).toFixed(2),
      })
      searchValue = parseFloat(searchValue).toFixed(2)
    }
  }

  openDetailsModal(templateRef, ROW) {
    this.refundError = ""
    this.isLoadingDetailInfo = false
    // this.RefundForm.reset();
    this.dialog.open(templateRef, {
      panelClass: "payments_table_modal",
    })

    this.modalDatas = ROW
    // this.transactionService
    //   .getAdvanceTransaction(Id)
    //   .subscribe(
    //     (data) => {
    //       console.log(data);
    //       if (data.ResultCode === 0) {
    //         this.modalDatas = data.ReturnObject.Acquring;
    //         this.modalTransactionsList = data.ReturnObject.Transactions;
    //         this.RefundForm.removeControl("Amount");
    //         this.RefundForm.removeControl("Type");
    //         this.RefundForm.removeControl("Comment");
    //         if (this.modalDatas.Status !== 3) {
    //           this.RefundForm.addControl("Amount", new FormControl(""));
    //           this.RefundForm.addControl("Type", new FormControl(""));
    //           this.RefundForm.addControl("Comment", new FormControl(""));
    //           this.RefundForm.disable();
    //         } else {
    //           this.RefundForm.addControl(
    //             "Type",
    //             new FormControl(this.refundList[0].value)
    //           );
    //           this.RefundForm.addControl(
    //             "Comment",
    //             new FormControl("", Validators.required)
    //           );
    //           this.RefundForm.addControl(
    //             "Amount",
    //             new FormControl(
    //               (this.modalDatas.TotalAmount / 100).toFixed(2),
    //               Validators.required
    //             )
    //           );
    //           this.RefundForm.controls.Amount.disable();
    //           this.RefundForm.controls.Type.enable();
    //           this.RefundForm.controls.Comment.enable();
    //           // Initialize form rules
    //         }
    //       } else {
    //         this.CloseDialog();
    //       }
    //       this.isLoadingDetailInfo = false;
    //     },
    //     (error) => {
    //       this.isLoadingDetailInfo = false;
    //       console.log(error);
    //     }
    //   );
  }

  // changeTypeRefund(type) {
  //   console.log(type);
  //   if (type === 1) {
  //     this.RefundForm.removeControl("Amount");
  //     this.RefundForm.addControl(
  //       "Amount",
  //       new FormControl("", [
  //         Validators.required,
  //         Validators.min(1),
  //         Validators.max(this.modalDatas.TotalAmount / 100),
  //       ])
  //     );
  //   } else {
  //     this.RefundForm.removeControl("Amount");
  //     this.RefundForm.addControl(
  //       "Amount",
  //       new FormControl((this.modalDatas.TotalAmount / 100).toFixed(2))
  //     ); // Initialize form rules
  //     this.RefundForm.controls.Amount.disable();
  //   }
  // }

  download(format) {
    // if (this.loading === false && this.isDownloadingFile === false) {
    //   this.isDownloadingFile = true;
    //   let start = this.datePipe.transform(
    //     this.FilterForm.controls.dateFrom.value,
    //     "yyyy-MM-ddT00:00:00"
    //   );
    //   let finish = this.datePipe.transform(
    //     this.FilterForm.controls.dateTo.value,
    //     "yyyy-MM-ddT23:59:59"
    //   );
    //   this.isDownloadingFile = true;
    //   this.transactionService
    //     .getsOrderHistoryFile(
    //       start,
    //       finish,
    //       this.radioModel,
    //       this.FilterForm.value.Code,
    //       this.FilterForm.value.Phone,
    //       this.FilterForm.value.Status,
    //       null,
    //       format
    //     )
    //     .subscribe(
    //       (res) => {
    //         this.isDownloadingFile = false;
    //         saveAs(res, "AcquiringReport_" + this.datePipe.transform(
    // this.FilterForm.controls.dateTo.value,
    //  "yyyy-MM-dd"
    //   ) + "." + format, {
    //           type:
    //             "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //         });
    //       },
    //       (error) => {
    //         console.log(error);
    //         this.isDownloadingFile = false;
    //       }
    //     );
    // }
  }

  statusLists = [
    {
      text: "All",
      value: "null",
      color: "",
    },
    {
      text: "New",
      value: 0,
      color: "badge-warning",
    },
    {
      text: "Paid",
      value: 1,
      color: "badge-success",
    },
    {
      text: "Send",
      value: 2,
      color: "badge-primary",
    },
    {
      text: "Refund",
      value: 3,
      color: "badge-danger",
    },
    {
      text: "Cancel",
      value: 4,
      color: "badge-info",
    },
  ]

  refundList = [
    {
      text: "Full",
      value: 0,
      color: "badge-info",
    },
    //{
    //    text: 'Partial',
    //    value: 1,
    //    color: 'badge-warning'
    //},
  ]

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex
    this.paginationItemPerPage = e.pageSize
    this.getFirstDatas(this.radioModel)
  }

  ngOnInit() {
    this.paginationItemPerPage = 10
    let ls = new SecureLS() // for Secure Local storage    
    this.permissions = ls.get("permissions")[this.route.data["value"].pathCheck];

    this.translate.get("REPORT.ORDER_STS_ALL").subscribe((text) => {
      this.statusLists[0].text = text
    })
    this.translate.get("REPORT.STATUS_STS_NEW").subscribe((text) => {
      this.statusLists[1].text = text
    })
    this.translate.get("REPORT.STATUS_STS_PAID").subscribe((text) => {
      this.statusLists[2].text = text
    })
    this.translate.get("REPORT.STATUS_STS_SEND").subscribe((text) => {
      this.statusLists[3].text = text
    })
    this.translate.get("REPORT.STATUS_STS_EXPIRED").subscribe((text) => {
      this.statusLists[4].text = text
    })
    this.translate.get("REPORT.STATUS_STS_CANCEL").subscribe((text) => {
      this.statusLists[5].text = text
    })
    this.translate.get("REPORT.REFUND_FULL_TEXT").subscribe((text) => {
      this.refundList[0].text = text
    })

    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      Status: new UntypedFormControl(this.statusLists[0].value, Validators.required),
      OrderNumber: new UntypedFormControl(""),
      Amount: new UntypedFormControl(""),
      PaymentType: new UntypedFormControl("", Validators.required),
    }) // for header Filter
    // this.FilterForm.value.Code,
    //   this.FilterForm.value.Phone,
    // this.RefundForm = this.formBuilder.group({
    //   Type: ["", [Validators.required]],
    //   Amount: [null, [Validators.required, Validators.min(1)]],
    //   Comment: ["", [Validators.required]],
    // });
    this.getMoneyProviders()
    // this.getFirstDatas(this.radioModel);
  }
}
