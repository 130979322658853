import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../../_services";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DataSource } from "@angular/cdk/table";
import { ReportsDatasService } from "../../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TransactionService } from "../../../_services/transaction.service";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../../globals/globals";

import { ActivatedRoute } from "@angular/router";

@Component({
  templateUrl: "./order-history.component.html",
  styleUrls: ["../transactions.scss"],
  providers: [DatePipe, Globals],
})
export class OrderHistoryComponent implements OnInit {
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  allPageLoad: boolean = false;
  isDownloadingFile: boolean = false;
  permissions;
  modalDatas = {};

  modalTransactionsList = [];

  moneyProviderList = [];

  radioModel = 1;
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  Info;
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  AccountCodes;
  refundError = "";
  displayedColumns: string[] = [
    "Date",
    "OrderNumber",
    "MerchantName",
    "MerchantCode",
    "MobileNumber",
    "Description",
    "InstallmentNumber",
    "Amount",
    "Fee",
    "PayMethod",
    "SalePaymentType",
    "Status",
    "ProductType",
    "PaidDate",
  ];
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private datePipe: DatePipe,
    private transactionService: TransactionService,
    public toastr: ToastrService,
    private globals: Globals,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {}

  FilterForm: UntypedFormGroup; // Form initial
  RefundForm: UntypedFormGroup;

  CloseDialog(): void {
    this.dialog.closeAll();
  }
  public totalAmounts: number = 0;
  public totalFee: number = 0;

  getMerchants() {
    this.transactionService.getMerchants().subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.merchantList = data.ReturnObject;
          }
        }
        this.translate.get("REPORT.FILTER_MERCHANT_ALL").subscribe((text) => {
          this.merchantList.unshift({
            id: "null",
            Name: text,
            Description: "",
            Key: "",
          });
        });
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      },
      () => {
        this.FilterForm.patchValue({
          ["Merchant"]: this.merchantList[0].id,
        });
        this.getFirstDatas(this.radioModel);
      }
    );
  }
  getFirstDatas(period) {
    this.loading = true;
    this.radioModel = period;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    let filter_status = null;
    if (this.FilterForm.value.Status !== "null") {
      filter_status = this.FilterForm.value.Status;
    }

    this.transactionService
      .getsOrderHistory(
        start,
        finish,
        period,
        this.FilterForm.value.Code,
        this.FilterForm.value.Phone,
        this.FilterForm.value.Status,
        this.FilterForm.value.ProductType,
        this.FilterForm.value.PaymentType,
        this.FilterForm.value.Merchant,
        this.FilterForm.value.SalePayTypeList,
        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .subscribe(
        (data) => {
          let datas = [];
          this.paginationItems = 0;
          this.totalAmounts = 0;
          this.totalFee = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Orders;
              this.paginationItems = data.ReturnObject.Fetch.Cnt;
              this.totalAmounts = data.ReturnObject.TotalAmounts;
              this.totalFee = data.ReturnObject.TotalFee;
            }
          }
          this.dataSource = new MatTableDataSource(datas); // array of datas
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
        }
      );
  }

  // makeRefund() {
  //   if (this.RefundForm.valid === true) {
  //     this.loading = true;
  //     this.refundError = "";
  //     console.log(this.RefundForm.value);
  //     let Type = this.RefundForm.value.Type;
  //     this.transactionService
  //       .makeRefund(
  //         this.modalDatas.Id,
  //         Type,
  //         Math.round(Number(this.RefundForm.value.Amount) * 100),
  //         this.RefundForm.value.Comment
  //       )
  //       .pipe(first())
  //       .subscribe(
  //         (data) => {
  //           console.log(data);
  //           if (data.ResultCode === 0) {
  //             let theme = "";
  //             let body = "";
  //             this.translate
  //               .get("REPORT.THEME_SUCCESS_REFUND")
  //               .subscribe((text) => {
  //                 theme = text;
  //               });
  //             this.translate
  //               .get("REPORT.BODY_SUCCESS_REFUND")
  //               .subscribe((text) => {
  //                 body = text;
  //               });
  //             this.toastr.success(body, theme);
  //             this.CloseDialog();
  //             this.getFirstDatas(this.radioModel);
  //           } else {
  //             let theme = "";
  //             data.ResultMessage === null
  //               ? (this.refundError = "")
  //               : (this.refundError = data.ResultMessage);
  //             this.translate
  //               .get("REPORT.THEME_ERROR_REFUND")
  //               .subscribe((text) => {
  //                 theme = text;
  //               });
  //             this.toastr.error(this.refundError, theme);
  //           }
  //           this.loading = false;
  //         },
  //         (error) => {
  //           this.loading = false;
  //           this.toastr.error();
  //           console.log(error);
  //         },
  //         () => {
  //           this.allPageLoad = true;
  //         }
  //       );
  //   }
  // }

  convertOnBlur(searchValue, form_group) {
    if (searchValue > 0) {
      this[form_group].patchValue({
        ["Amount"]: parseFloat(searchValue).toFixed(2),
      });
      searchValue = parseFloat(searchValue).toFixed(2);
    }
  }

  openDetailsModal(templateRef, Id) {
    this.refundError = "";
    this.isLoadingDetailInfo = true;
    // this.RefundForm.reset();
    this.dialog.open(templateRef, {
      panelClass: "payments_table_modal",
    });
    this.modalDatas = new Object();
    this.transactionService.getOrderItem(Id).subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          this.modalDatas = data.ReturnObject;
        } else {
          this.CloseDialog();
        }
        this.isLoadingDetailInfo = false;
      },
      (error) => {
        this.isLoadingDetailInfo = false;
        console.log(error);
      }
    );
  }

  // changeTypeRefund(type) {
  //   console.log(type);
  //   if (type === 1) {
  //     this.RefundForm.removeControl("Amount");
  //     this.RefundForm.addControl(
  //       "Amount",
  //       new FormControl("", [
  //         Validators.required,
  //         Validators.min(1),
  //         Validators.max(this.modalDatas.TotalAmount / 100),
  //       ])
  //     );
  //   } else {
  //     this.RefundForm.removeControl("Amount");
  //     this.RefundForm.addControl(
  //       "Amount",
  //       new FormControl((this.modalDatas.TotalAmount / 100).toFixed(2))
  //     ); // Initialize form rules
  //     this.RefundForm.controls.Amount.disable();
  //   }
  // }

  download(format) {
    if (this.loading === false && this.isDownloadingFile === false) {
      this.isDownloadingFile = true;
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );

      this.isDownloadingFile = true;
      this.transactionService
        .getsOrderHistoryFile(
          start,
          finish,
          this.radioModel,
          this.FilterForm.value.Code,
          this.FilterForm.value.Phone,
          this.FilterForm.value.Status,
          this.FilterForm.value.ProductType,
          this.FilterForm.value.PaymentType,
          this.FilterForm.value.Merchant,
          this.FilterForm.value.SalePayTypeList,
          format
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(
              res,
              "OrderHistory_" +
                this.datePipe.transform(
                  this.FilterForm.controls.dateTo.value,
                  "yyyy-MM-dd"
                ) +
                "." +
                format,
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }
            );
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  salePayTypeList = [
    {
      text: "All",
      value: 0, 
    },
    {
      text: "Sale",
      value: 1, 
    },
    {
      text: "Installment",
      value: 2, 
    },
    {
      text: "Account",
      value: 3, 
    },
    {
      text: "Bonus",
      value: 4, 
    }
  ];

  // Paid = 1,
  //         Send = 2,
  //         Expired = 3,
  //         Cancel = 4,
  //         Refund = 5
  statusLists = [
    {
      text: "All",
      value: "null",
      color: "",
    },
    // {
    //   text: "New",
    //   value: 0,
    //   color: "badge-success",
    // },
    {
      text: "Paid",
      value: 1,
      color: "badge-success",
    },
    {
      text: "Send",
      value: 2,
      color: "badge-warning",
    },
    {
      text: "Refund",
      value: 3,
      color: "badge-primary",
    },
    {
      text: "Cancel",
      value: 4,
      color: "badge-info",
    },
    {
      text: "Refund",
      value: 5,
      color: "badge-info",
    },
    {
      text: "PartialRefund",
      value: 6,
      color: "badge-info",
    },
  ];
  productTypeList = [
    {
      text: "All",
      value: "null",
      color: "",
    },
    {
      text: "Sale",
      value: 2,
      color: "badge-primary",
    },
    {
      text: "Invoice",
      value: 1,
      color: "badge-success",
    },
  ];
  whatBadge(id) {
    for (let i = 0; i < this.statusLists.length; i++) {
      if (this.statusLists[i].value == id) {
        return this.statusLists[i].color;
      }
    }
  }
  whatProduct(id) {
    for (let i = 0; i < this.productTypeList.length; i++) {
      if (this.productTypeList[i].value == id) {
        return this.productTypeList[i].color;
      }
    }
  }
  // New = 0, - verde
  // Paid = 1, - verde
  // Send = 2, - portocaliu
  // Expired = 3, - albastru
  // Cancel = 4 - sur
  refundList = [
    {
      text: "Full",
      value: 0,
      color: "badge-info",
    },
    //{
    //    text: 'Partial',
    //    value: 1,
    //    color: 'badge-warning'
    //},
  ];

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }

  ngOnInit() {
    this.paginationItemPerPage = 10;

    this.translate.get("REPORT.ORDER_STS_ALL").subscribe((text) => {
      this.statusLists[0].text = text;
    });
    // this.translate.get("REPORT.STATUS_STS_NEW").subscribe((text) => {
    //   this.statusLists[1].text = text;
    // });
    this.translate.get("REPORT.STATUS_STS_PAID").subscribe((text) => {
      this.statusLists[1].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_SEND").subscribe((text) => {
      this.statusLists[2].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_EXPIRED").subscribe((text) => {
      this.statusLists[3].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_CANCEL").subscribe((text) => {
      this.statusLists[4].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_FULLREFUND").subscribe((text) => {
      this.statusLists[5].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_PARTREFUND").subscribe((text) => {
      this.statusLists[6].text = text;
    });

    this.translate.get("REPORT.REFUND_FULL_TEXT").subscribe((text) => {
      this.refundList[0].text = text;
    });
    this.translate.get("REPORT.ORDER_TYPE_ALL").subscribe((text) => {
      this.productTypeList[0].text = text;
    });
    this.translate.get("REPORT.ORDER_TYPE_0").subscribe((text) => {
      this.productTypeList[1].text = text;
    });
    this.translate.get("REPORT.ORDER_TYPE_1").subscribe((text) => {
      this.productTypeList[2].text = text;
    });
    
    this.translate.get("REPORT.SALE_PAY_TYPE_0").subscribe((text) => {
      this.salePayTypeList[0].text = text;
    });
    this.translate.get("REPORT.SALE_PAY_TYPE_1").subscribe((text) => {
      this.salePayTypeList[1].text = text;
    });
    this.translate.get("REPORT.SALE_PAY_TYPE_2").subscribe((text) => {
      this.salePayTypeList[2].text = text;
    });
    this.translate.get("REPORT.SALE_PAY_TYPE_3").subscribe((text) => {
      this.salePayTypeList[3].text = text;
    });
    this.translate.get("REPORT.SALE_PAY_TYPE_4").subscribe((text) => {
      this.salePayTypeList[4].text = text;
    });

    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      Status: new UntypedFormControl(this.statusLists[0].value, Validators.required),
      Code: new UntypedFormControl(""),
      Phone: new UntypedFormControl(""),
      PaymentType: new UntypedFormControl(""),
      ProductType: new UntypedFormControl(this.productTypeList[0].value),
      SalePayTypeList: new UntypedFormControl(this.salePayTypeList[0].value),
      Merchant: new UntypedFormControl(""),
    }); // for header Filter
    
    this.getMoneyProviders();

  }
  getMoneyProviders() {
    this.transactionService.getMoneyProviders().subscribe(
      (data) => {
        console.log(data);
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.moneyProviderList = data.ReturnObject;
          }
        }
        this.translate.get("REPORT.FILTER_MPROVIDERS_ALL").subscribe((text) => {
          this.moneyProviderList.unshift({
            ID: "null",
            Name: text,
          });
        });
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      },
      () => {
        this.FilterForm.patchValue({
          ["PaymentType"]: this.moneyProviderList[0].ID,
        });
        this.getMerchants();
        this.getFirstDatas(this.radioModel);
      }
    );
  } 
  public merchantList: Merchant[] = [];
}


interface Merchant {
  Key: string;
  id: number | string;
  Name: string;
  Description: string;
}
