import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { saveAs } from 'file-saver'
import {
	ApiNotificationFilter,
	IAppType,
	ICustomerType,
	appType,
	customerType,
	notificationType,
	INotificationType,
	DateRange,
	DateRangeTypesEnum,
	DefaultDateRangeTypes,
	DateOUT,
	IStatus,
	status,
	PagingInfo,
} from '../../../_models/api-notification.model'
import { ApiNotificationSharedService } from '../../../_services/api-notification-shared.service'
import { ApinotificationService } from '../../../_services/api-notification.service'

@Component({
	selector: 'app-api-notification',
	templateUrl: './api-notification.component.html',
	styleUrls: ['./api-notification.component.scss'],
	providers: [DatePipe],
})
export class ApiNotificationComponent implements OnInit {
	currentDate = new Date(new Date().setHours(0, 0, 0))
	finalDate = new Date(new Date().setHours(23, 59, 59))
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	FilterForm: FormGroup
	radioModel = 1
	ApiNotificationFilter: ApiNotificationFilter
	AppType: IAppType[] = appType
	CustomerType: ICustomerType[] = customerType
	NotificationType: INotificationType[] = notificationType
	Status: IStatus[] = status
	loading = false
	isDownloadingFile: boolean = false
	DateRangeArray: DateRange[]
	selectedPeriod: DateRange
	DateRange?: (Date | undefined | boolean)[]
	DateRangeTypesEnum: DateRangeTypesEnum[]
	PreSelectedType: DateRangeTypesEnum | null
	pageEvent
	DateOUT: DateOUT
	public paginationItemPerPage = 20
	public paginationItems // for display pagination from api
	public currentPage = 0
	apiNotData: any

	constructor(
		private sharedService: ApiNotificationSharedService,
		private service: ApinotificationService
	) {
		this.sharedService.pagingInfo$.subscribe((data: PagingInfo) => {
			this.paginationItemPerPage = data?.ItemsPerPage
			this.paginationItems = data?.TotalItems
		})
		this.sharedService.loading$.subscribe((data) => {
			this.loading = data
		})
	}

	get dateFrom() {
		return this.FilterForm.get('dateFrom') as FormControl
	}
	get dateTo() {
		return this.FilterForm.get('dateTo') as FormControl
	}
	get appType() {
		return this.FilterForm.get('AppType') as FormControl
	}
	get notificationType() {
		return this.FilterForm.get('NotificationType') as FormControl
	}
	get customerType() {
		return this.FilterForm.get('CustomerType') as FormControl
	}
	get status() {
		return this.FilterForm.get('Status') as FormControl
	}
	get notificationUser() {
		return this.FilterForm.get('NotificationUser') as FormControl
	}
	get header() {
		return this.FilterForm.get('Header') as FormControl
	}
	get periodType() {
		return this.FilterForm.get('PeriodType') as FormControl
	}

	ngOnInit(): void {
		this.DateRangeTypesEnum =
			this.DateRangeTypesEnum ?? DefaultDateRangeTypes

		this.PreSelectedType = !this.PreSelectedType
			? DateRangeTypesEnum.LAST7
			: this.PreSelectedType

		this.DateRangeArray = DateRange.GetList().filter((x) =>
			this.DateRangeTypesEnum.includes(x.Type)
		)

		this.selectedPeriod = !!this.PreSelectedType
			? (DateRange.GetList().find(
					(x) => x.Type === this.PreSelectedType
			  ) as DateRange)
			: DateRange.GetRangeType(DateRangeTypesEnum.LAST7)

		if (this.selectedPeriod.Range) {
			this.DateRange = [
				this.selectedPeriod.Range.DateFrom,
				this.selectedPeriod.Range.DateTo,
			]
		}

		this.FilterForm = new FormGroup({
			dateFrom: new FormControl({
				value: this.currentDate,
				disabled: true,
			}),
			dateTo: new FormControl({ value: this.finalDate, disabled: true }),
			AppType: new FormControl(''),
			NotificationType: new FormControl(''),
			CustomerType: new FormControl(''),
			Status: new FormControl(''),
			NotificationUser: new FormControl(''),
			Header: new FormControl(''),
			PeriodType: new FormControl(0),
		})
		this.getFilterData()
	}

	getFilterData(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
			this.periodType.setValue(this.radioModel)
			this.currentDate = this.dateFrom.value.setHours(0, 0, 0, 0)
			this.finalDate = this.dateTo.value.setHours(23, 59, 59, 999)
		}
		this.ApiNotificationFilter = new ApiNotificationFilter(
			this.FilterForm.getRawValue(),
			this.paginationItemPerPage,
			this.currentPage + 1
		)
		console.log('this.ApiNotificationFilter', this.FilterForm)
		this.sharedService.setApiNotificationOUT(this.ApiNotificationFilter)
	}

	public selectPeriod(period: DateRange, radioModel) {
		this.loading = true
		this.radioModel = radioModel

		if (period.Range) {
			this.DateRange = [period.Range.DateFrom, period.Range.DateTo]
		}
		// period.Range.RadioModel = radioModel

		this.selectedPeriod = period
		this.currentDate = this.selectedPeriod.Range.DateFrom
		this.finalDate = this.selectedPeriod.Range.DateTo
		// this.dateFrom.setValue(this.currentDate)
		// this.dateTo.setValue(this.finalDate)
		this.periodType.setValue(radioModel)
		this.getFilterData()
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFilterData()
	}

	exportExcel() {
		this.loading = true
		let fileName = `Api Notification_${this.ApiNotificationFilter.Date.DateTo}`
		this.service
			.exportExcel(this.ApiNotificationFilter)
			.subscribe((data) => {
				this.isDownloadingFile = false
				this.loading = false
				saveAs(data, fileName + '.' + 'xlsx', {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
			})
	}
}
