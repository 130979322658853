import { Component, OnInit } from '@angular/core'
import { ApiNotificationSharedService } from '../../../../_services/api-notification-shared.service'
import { SubscriptionLike } from 'rxjs'
import {
	ApiNotificationFilter,
	ApiNotificationList,
	IDisplayedColumns,
	IStatus,
	status,
	displayedColumns,
} from '../../../../_models/api-notification.model'
import { ApinotificationService } from '../../../../_services/api-notification.service'
import { MatTableDataSource } from '@angular/material/table'
import { DatePipe } from '@angular/common'

@Component({
	selector: 'app-api-notification-list',
	templateUrl: './api-notification-list.component.html',
	styleUrls: ['./api-notification-list.component.scss'],
	providers: [DatePipe],
})
export class ApiNotificationListComponent implements OnInit {
	ApiNotificationFilter: ApiNotificationFilter
	ApiNotificationList: ApiNotificationList
	subscription_apiNotificationOUT$: SubscriptionLike
	dataSource = new MatTableDataSource() // array of datas
	DisplayedColumns: IDisplayedColumns[] = displayedColumns
	Status: IStatus[] = status
	displayedColumns: string
	constructor(
		private sharedService: ApiNotificationSharedService,
		private service: ApinotificationService,
		private datePipe: DatePipe
	) {
		this.subscription_apiNotificationOUT$ =
			this.sharedService.apiNotificationOUT$.subscribe((data) => {
				this.ApiNotificationFilter = data
				this.getList()
			})
	}
	ngOnInit(): void {}

	getList() {
		this.service
			.getApiNotificationList(this.ApiNotificationFilter)
			.subscribe({
				next: (data) => {
					console.log('DATA', data)
					// if (!!data.NotificationReportRegistries) {
					this.ApiNotificationList = new ApiNotificationList(data)
					this.dataSource = new MatTableDataSource(
						this.ApiNotificationList.Items
					)
					this.sharedService.setPagingInfo(
						this.ApiNotificationList.PagingInfo
					)
					console.log(
						'this.ApiNotificationList',
						this.ApiNotificationList
					)
					// }
				},
				complete: () => {
					this.sharedService.setLoading(false)
				},
			})
	}
}
