import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';
import { ProfileService } from '../_services/profile.service';
import { first } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanActivate {

	constructor(private profileService: ProfileService, private router: Router, private authService: AuthenticationService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const expectedRole = route.data.expectedRole;
		// decode the token to get its payload
		// const tokenPayload = decode(token);

		// if (this.authService.isTokenExpired() || tokenPayload.role !== expectedRole) {
		// this.router.navigate(['/dashboard']);
		// return false;
		// }
		// return true;
		
		// V cookie doljen biti prava zashifrovani. Nikak inace
     
		this.profileService.getProfile()
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					return true;
				},
				error => {
					console.log(error);
					this.router.navigate(['/dashboard']);
					return false;
				});
		return true;
        
        this.router.navigate(['/dashboard']);
        return true;
	}
}