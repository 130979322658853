export interface INotification {
	Id: ''
	Category: number
	SourceType: number
	BankCode: number
	Value: string
	FileName: string
	FileContent: string
	NameEn: string
	NameRu: string
	NameTr: string
	Status: number
	TextEn: string
	TextRu: string
	TextTr: string
	Type: number
	UserName: string
}

export class AllNotification implements INotification {
	Id: ''
	Category: number
	SourceType: number
	BankCode: number
	Value: string
	FileName: string
	FileContent: string
	NameEn: string
	NameRu: string
	NameTr: string
	Status: number
	TextEn: string
	TextRu: string
	TextTr: string
	Type: number
	UserName: string
	constructor(data: any) {
		this.Id = data.Id
		this.Category = data.Category
		this.SourceType = data.SourceType
		this.BankCode = data.BankCode
		this.Value = data.Value
		this.FileName = data.FileName
		this.FileContent = data.FileContent
		this.NameEn = data.NameEn
		this.NameRu = data.NameRu
		this.NameTr = data.NameTr
		this.Status = data.Status
		this.TextEn = data.TextEn
		this.TextRu = data.TextRu
		this.TextTr = data.TextTr
		this.Type = data.Type
		this.UserName = data.UserName
	}
}
