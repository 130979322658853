import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
	FormControl,
	FormGroup,
	UntypedFormGroup,
	Validators,
} from '@angular/forms'
import { ToolsService } from '../../../_services/tools.service'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { saveAs } from 'file-saver'
import { TranslateService } from '@ngx-translate/core'
import { SendNotificationDataService } from '../../../_services/send-notification.shared.service'
import { error } from 'console'
import { NotificationService } from '../../../_services/notification.service'

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	providers: [DatePipe],
})
export class NotificationComponent implements OnInit {
	FilterForm: FormGroup
	currentTime = new Date()
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	loading = false
	radioModel = 1
	isLoadingDetailInfo: boolean = true
	public paginationItemPerPage = 10
	public paginationItems // for display pagination from api
	public currentPage = 0
	isDownloadingFile: boolean = false
	dataSource = new MatTableDataSource() // array of datas
	notificationData: null
	UserTest: string = '-'
	CountUser: string = '-'
	edit = true
	startTest = true
	send = true
	pauseCont = true
	cancel = true
	downloadDet = true
	downloadRep = true
	Id = 0
	status = ''
	statusNumber = 0
	pageEvent

	bankAll = {
		Name: 'TOOLS.BANK_ALL',
		Code: '',
		Key: '',
	}

	bankList = [
		// {
		// 	Name: 'TOOLS.BANK_ALL',
		// 	Code: '00',
		// 	Key: '000',
		// },
		this.bankAll,
		{
			Name: '',
			Code: '',
			Key: '',
		},
	]
	NotificationStatus = [
		{
			text: 'TOOLS.NA_STATUS_ALL',
			value: '',
			color: 'badge-info',
		},
		{
			text: 'TOOLS.NA_STATUS_UNK',
			value: 0,
			color: 'badge-info',
		},
		{
			text: 'TOOLS.NA_STATUS_CREATED',
			value: 1,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_SENDTEST',
			value: 2,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_PREPCOMPL',
			value: 3,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_PROGRESS',
			value: 4,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_SEND',
			value: 5,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_CLOSE',
			value: 6,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_COMPLETED',
			value: 7,
			color: 'badge-success',
		},
		{
			text: 'TOOLS.NA_STATUS_STOP',
			value: 8,
			color: 'badge-danger',
		},
		{
			text: 'TOOLS.NA_STATUS_ERROR',
			value: 6,
			color: 'badge-danger',
		},
	]
	NotificationType = [
		{
			text: 'TOOLS.NOT_TYPE_0',
			value: '',
		},
		{
			text: 'TOOLS.NOT_TYPE_1',
			value: 1,
		},
		{
			text: 'TOOLS.NOT_TYPE_2',
			value: 2,
		},
	]
	NotificationCategory = [
		{
			text: 'TOOLS.CATEGORY_0_ALL',
			value: '',
		},
		{
			text: 'TOOLS.CATEGORY_1',
			value: 1,
		},
		{
			text: 'TOOLS.CATEGORY_2',
			value: 2,
		},
	]
	NotificationSourceType = [
		{
			text: 'TOOLS.SOURCE_TYPE_0',
			value: '',
		},
		{
			text: 'TOOLS.SOURCE_TYPE_1',
			value: 1,
		},
		{
			text: 'TOOLS.SOURCE_TYPE_2',
			value: 2,
		},
		{
			text: 'TOOLS.SOURCE_TYPE_3',
			value: 3,
		},
	]
	displayedColumns: string[] = [
		'ID',
		'SourceType',
		'CreationDate',
		'SentDate',
		'Category',
		'Type',
		'HeaderPreview',
		'TextPreview',
		'BankCode',
		'UserName',
		'Status',
		'Progress',
		'Action',
	]

	statusLists = [
		{
			text: 'TOOLS.NA_STATUS_UNK',
			value: 0,
			color: 'badge-info',
		},
		{
			text: 'TOOLS.NA_STATUS_CREATED',
			value: 1,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_SENDTEST',
			value: 2,
			color: 'badge-primary',
		},
		{
			text: 'TOOLS.NA_STATUS_PREPCOMPL',
			value: 3,
			color: 'badge-primary',
		},
		{
			text: 'TOOLS.NA_STATUS_PROGRESS',
			value: 4,
			color: 'badge-primary',
		},
		{
			text: 'TOOLS.NA_STATUS_SEND',
			value: 5,
			color: 'badge-warning',
		},
		{
			text: 'TOOLS.NA_STATUS_CLOSE',
			value: 6,
			color: 'badge-danger',
		},
		{
			text: 'TOOLS.NA_STATUS_COMPLETED',
			value: 7,
			color: 'badge-success',
		},
		{
			text: 'TOOLS.NA_STATUS_STOP',
			value: 8,
			color: 'badge-dark',
		},
		{
			text: 'TOOLS.NA_STATUS_SENDTEST_COMPL',
			value: 9,
			color: 'badge-success',
		},
		{
			text: 'TOOLS.NA_STATUS_ERROR',
			value: 10,
			color: 'badge-danger',
		},
	]
	constructor(
		private datePipe: DatePipe,
		private notificationServ: NotificationService,
		public translate: TranslateService,
		private notification: SendNotificationDataService,
		public dialog: MatDialog
	) {}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}

	OpenDialog(templateRef, id, status, statusNumber) {
		this.statusNumber = statusNumber
		this.status = status
		this.Id = id
		console.log(this.status)
		this.isLoadingDetailInfo = false

		this.dialog.open(templateRef, {
			// panelClass: 'modal_transaction',
			minHeight: '380px',
			width: '38vw',
		})
		switch (this.statusNumber) {
			case 2:
				this.editAction(this.Id)
				// this.notificationServ.getTestUser(this.Id).subscribe({
				// 	next: (data) => {
				// 		this.UserTest
				// 	},
				// })
				break
			case 5:
				this.notificationServ.getCountUser(this.Id).subscribe({
					next: (data: number) => {
						console.log('data', data)
						this.CountUser = data.toString()
					},
				})
		}
	}
	CloseDialog() {
		this.dialog.closeAll()
	}

	getFirstDatas(period?) {
		// if (
		// 	/^[\s\S]*\S[\s\S]*$/.test(
		// 		this.FilterForm.controls.SearchByHeader.value
		// 	)
		// ) {
		if (typeof period === 'number') {
			this.radioModel = period
		}
		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddT00:00:00'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddT23:59:59'
		)
		//let period = 0
		let filter = this.FilterForm.value
		let searchAfter = this.FilterForm.controls.SearchByHeader.value.replace(
			/\s+$/,
			''
		)
		let search = searchAfter.replace(/^\s+/, '')
		// let search = searchAfter + searchBefore
		//  let search = /^[\s\S]*\S[\s\S]*$/.test(
		// 	this.FilterForm.controls.SearchByHeader.value
		// )

		this.notificationServ
			.getNotificationData(
				start,
				finish,
				this.radioModel,
				filter,
				search,
				this.paginationItemPerPage,
				this.currentPage + 1
			)
			.subscribe(
				(data) => {
					console.log('notification data', data)
					if (data.ResultCode === 0) {
						this.notificationData = data.ReturnObject.Notifications
						this.paginationItems =
							data.ReturnObject.Paging.TotalItems
						this.paginationItemPerPage =
							data.ReturnObject.Paging.ItemsPerPage
					}
					this.dataSource = new MatTableDataSource(
						this.notificationData
					)
					console.log('this.displayedColumns', this.displayedColumns)

					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
		console.log('pauseContInit', this.pauseCont)
		// }
	}

	getBankCode() {
		this.notificationServ.getBankCode().subscribe((data) => {
			this.bankList = data.ReturnObject
			console.log('bankCode', this.bankList)
			this.bankList.unshift({ Name: 'TOOLS.BANK_ALL', Code: '', Key: '' })
		})
	}

	ngOnInit(): void {
		this.getBankCode()
		this.FilterForm = new FormGroup({
			// Dealer: new FormControl(), //, [this.dealerDropValidator(this.options)]),
			dateFrom: new FormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new FormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			NotificationSourceType: new FormControl(''),
			Category: new FormControl(''),
			Type: new FormControl(''),
			BankCode: new FormControl(''),
			Status: new FormControl(''),
			SearchByHeader: new FormControl(''),

			// UserName: new FormControl(''),
			//Status: new FormControl(this.filterStates[0].Value),
		})
		this.getFirstDatas()
	}
	exportExcel(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}
		this.isDownloadingFile = true
		this.loading = true

		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddT00:00:00'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddT23:59:59'
		)
		let filter = this.FilterForm.value
		let fileName = `Notification_${finish}`
		this.notificationServ
			.exportExcel(
				start,
				finish,
				this.radioModel,
				filter,
				this.paginationItemPerPage,
				this.currentPage + 1
			)
			.subscribe(
				(data) => {
					this.isDownloadingFile = false
					this.loading = false
					saveAs(data, fileName + '.' + 'xlsx', {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
				},
				(error) => {
					console.log(error)
					this.loading = false
					this.isDownloadingFile = false
				}
			)
	}

	// -----------------ACTION

	editAction(id) {
		this.notificationServ.editAction(id).subscribe((data) => {
			console.log('edit', id)
			this.UserTest = data.ReturnObject.TestUser
			console.log('this.UserTest', this.UserTest)
			this.notification.SendNotificData(data.ReturnObject)
			console.log('afterEdit', data.ReturnObject)
			this.pauseCont = false
			console.log('pauseCont', this.pauseCont)
		})
	}

	ProcessSet(id, status) {
		this.notificationServ.ProcessSet(id, status).subscribe((data) => {
			console.log('startTest', data)
			this.CloseDialog()
			this.getFirstDatas()
		})
	}

	downloadAction(id) {
		let fileName = 'details_file'
		this.notificationServ.downloadAction(id).subscribe((data) => {
			saveAs(data, fileName + '.' + 'xlsx', {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			})
			console.log('download', data)
		}),
			(error) => {
				console.log('error', error)
			}
	}

	downloadReport(id: number) {
		let fileName = 'user_notification_report_file'
		this.notificationServ.getNotificationReport(id).subscribe({
			next: (data) => {
				saveAs(data, fileName + '.' + 'xlsx', {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
				console.log('report file', data)
			},
		}),
			(error) => {
				console.log('error', error)
			}
	}

	action(status) {
		// console.log('status', status)
		// if (status == 1 || status == 0 || status == 4) {
		// 	// this.translate.get('TOOLS.EDIT').subscribe((text) => {
		// 	// 	let edit = text
		// 	// 	console.log('edit', edit)
		// 	// })
		// 	this.edit = false
		// 	this.startTest = false
		// 	this.send = true
		// 	this.cancel = false
		// } else if (status == 2) {
		// 	this.edit = false
		// 	this.send = false
		// 	this.startTest = true
		// } else if (status == 0 || status == 5) {
		// 	this.cancel = true
		// }
		switch (status) {
			case 0:
				this.edit = false
				this.startTest = false
				this.downloadRep = true
				break
			case 1:
				this.edit = false
				this.startTest = false
				this.cancel = false
				this.downloadRep = true
				this.pauseCont = true
				this.send = true
				break
			case 2:
				this.edit = true
				this.cancel = true
				this.downloadRep = true
				this.pauseCont = false
				this.send = true
				this.startTest = true
				break
			case 3:
				this.edit = true
				this.cancel = true
				this.downloadRep = true
				this.pauseCont = false
				this.send = true
				this.startTest = true

				break
			case 4:
				this.edit = true
				this.startTest = true
				this.cancel = true
				this.downloadRep = true
				this.pauseCont = false
				this.send = true
				break
			case 5:
				this.cancel = true
				this.startTest = true
				this.edit = true
				this.downloadRep = true
				this.pauseCont = true
				this.send = true
				break
			case 6:
				this.startTest = true
				this.edit = false
				this.cancel = true
				this.downloadRep = true
				this.pauseCont = true
				this.send = true
				break
			case 7:
				this.edit = false
				this.cancel = true
				this.pauseCont = true
				this.startTest = true
				this.send = true
				this.downloadRep = false
				break
			case 8:
				this.pauseCont = true
				this.startTest = true
				this.edit = true
				this.cancel = false
				this.send = true
				this.downloadRep = true
				break
			case 9:
				this.edit = false
				this.cancel = false
				this.send = false
				this.pauseCont = true
				this.startTest = true
				this.downloadRep = false
				break
			case 10:
				this.startTest = true
				this.edit = false
				this.cancel = false
				this.downloadRep = false
				this.pauseCont = true
				this.send = true
				break
		}
	}
}
