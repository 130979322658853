import { Injectable } from '@angular/core'
import {
	ApiNotificationFilter,
	PagingInfo,
} from '../_models/api-notification.model'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ApiNotificationSharedService {
	apiNotificationOUT$ = new Subject<ApiNotificationFilter>()
	setApiNotificationOUT(data: ApiNotificationFilter) {
		this.apiNotificationOUT$.next(data)
	}

	pagingInfo$ = new Subject<PagingInfo>()
	setPagingInfo(data: PagingInfo) {
		this.pagingInfo$.next(data)
	}

	loading$ = new Subject<boolean>()
	setLoading(data: boolean) {
		this.loading$.next(data)
	}
}
