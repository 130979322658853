import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';


@Injectable()

export class MainService {

    constructor(private http: HttpClient, private authService: AuthenticationService) {

    }
    getProductPage() {
        return this.http.get<any>(this.authService.basUrl + `/api/profile/Get`)
            .pipe(map(response => {
                if (this.authService.checkTokenResponse()) {
                    return response;
                }
            }));
    }


    getRoles(){
        return this.http.get<any>(this.authService.basUrl + `/api/profile/RightGets`)
        .pipe(map(response => {
            if (this.authService.checkTokenResponse()) {
                return response;
            }
        }));
    }

}