import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms"
import { Observable } from "rxjs"
import { map, startWith, first } from "rxjs/operators"
import { AlertService, UserService, ProviderService } from "../../../_services"
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator"
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table"
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog"
import { DataSource } from "@angular/cdk/table"
import { ReportsDatasService } from "../../../_services/reportsdata.service"
import { DatePipe } from "@angular/common"
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls"

import { CustomersService } from "../../../_services/customers.service"
import { TransactionService } from "../../../_services/transaction.service"
import { TranslateService } from "@ngx-translate/core"
import { saveAs } from "file-saver"
import { ToastrService } from "ngx-toastr"
import { Globals } from "../../../globals/globals"

import { ActivatedRoute } from "@angular/router"

@Component({
  templateUrl: "./customers-report.component.html",
  styleUrls: [
    "../../transactions/transactions.scss",
    "./customers-report.scss",
  ],
  providers: [DatePipe, Globals],
})
export class CustomersReportComponent implements OnInit {
  public paginationItems // for display pagination from api
  public paginationItemPerPage // for display pagination from api
  allPageLoad: boolean = false
  isDownloadingFile: boolean = false
  permissions

  block: boolean = false
  ModalInfoKey: string = ""
  ModalInfoStatus: number = 0
  message: string = ""
  messageName: string = ""
  customerNumber: string = ""
  nameSurname: string = ""
  radioModel = 1
  public currentPage = 0
  isLoadingDetailInfo: boolean = true // spinner
  Info
  loading = false // for disable fast response click
  pageEvent // for fix error of pageEvent in Visual Studio
  panelOpenState = true
  // For change pages
  minDate = new Date(2000, 0, 1) // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
  events: string[] = [] // Date picker
  currentTime = new Date()
  AccountCodes

  displayedColumns: string[] = [
    "Date",
    "CustomerNumber",
    "Name",
    "PhoneNumber",
    "Email",
    "Address",
    "CardNumber",
    "Language",
    "Device",
    "Status",
    
  ]
  dataSource = new MatTableDataSource() // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private datePipe: DatePipe,
    private transactionService: TransactionService,
    private customersService: CustomersService,
    public toastr: ToastrService,
    private globals: Globals,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {}

  FilterForm: UntypedFormGroup // Form initial

  CloseDialog(): void {
    this.dialog.closeAll()
  }
  getFirstDatas(period) {
    this.loading = true
    this.radioModel = period
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    )
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    )
    let _ff = this.FilterForm.value
    this.customersService
      .getsCustomers(
        start,
        finish,
        period,
        _ff.CustomerNumber,
        _ff.PhoneNumber,
        _ff.StatusType == "null" ? "" : _ff.StatusType,
        _ff.IdCardNumber,
        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .subscribe(
        (data) => {
          let datas = []
          this.paginationItems = 0

          if (data && data.ReturnObject !== null) {
            datas = data.ReturnObject.Customers
            this.paginationItems = data.ReturnObject.Pagination.TotalItems
          } else {
            datas = []
            this.paginationItems = 1
          }
          this.dataSource = new MatTableDataSource(datas) // array of datas
          this.loading = false
        },
        (error) => {
          this.loading = false
          console.log(error)
        },
        () => {
          this.allPageLoad = true
        }
      )
  }

  changeStatus(templateRef, row) {
    this.customerNumber = `${row.Login}?`

    if (row.Name !== "" && row.Name !== null) {
      this.nameSurname = row.Name
    } else {
      this.nameSurname = row.Login
    }

    if (row.Status === 1) {
      this.message = this.messageList[0].text
      this.messageName = this.messageNameList[0].text
      this.block = true
    } else {
      this.message = this.messageList[1].text
      this.messageName = ""
      this.nameSurname = ""
      this.block = false
    }

    this.ModalInfoStatus = row.Status
    this.ModalInfoKey = row.Key
    this.openModal(templateRef)
  }

  openModal(templateRef) {
    this.isLoadingDetailInfo = false
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_customers",
    })
  }

  changesConfrim(status: number, key: string) {
    if (status !== 1) {
      status = 1
    } else {
      status = 0
    }
    this.customersService.changeStatus(key, status).subscribe(
      (data) => {
        let datas = []
        this.paginationItems = 0

        if (data !== null && data === "Success") {
          this.CloseDialog()
          let body = ""
          let theme = ""
          if (status !== 1) {
            this.translate
              .get("CUSTOMER.BODY_EDITCUSTOMER_SUCCESSBLOCK")
              .subscribe((text) => {
                body = text
              })
          } else {
            this.translate
              .get("CUSTOMER.BODY_EDITCUSTOMER_SUCCESSUNBLOCK")
              .subscribe((text) => {
                body = text
              })
          } // Text in component
          if (status === 1) {
            this.translate
              .get("CUSTOMER.BODY_LBL_EDITCUSTOMER_SUCCESS_BLOCK")
              .subscribe((text) => {
                theme = text
              })
          } else {
            this.translate
              .get("CUSTOMER.BODY_LBL_EDITCUSTOMER_SUCCESS_UNBLOCK")
              .subscribe((text) => {
                theme = text
              })
          } // Text in component
          this.toastr.success(body, theme)
          this.loading = false
          this.getFirstDatas(this.radioModel)
        } else {
          let theme = ""
          this.translate
            .get("MERCHANT.THEME_EDITMERCHANT_ERROR")
            .subscribe((text) => {
              theme = text
            }) // Text in component
          this.toastr.error(theme, theme)
        }
        this.loading = false
      },
      (error) => {
        this.loading = false
        console.log(error)
      },
      () => {
        this.allPageLoad = true
      }
    )
  }
  download(format) {
    if (this.loading === false && this.isDownloadingFile === false) {
      this.isDownloadingFile = true
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      )
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      )
      let _ff = this.FilterForm.value

      this.isDownloadingFile = true
      this.customersService
        .getsCustomersFile(
          start,
          finish,
          this.radioModel,
          _ff.CustomerNumber,
          _ff.PhoneNumber,
          _ff.StatusType == "null" ? "" : _ff.StatusType,
          _ff.IdCardNumber,
          format
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false
            saveAs(
              res,
              "CustomersReport_" +
                this.datePipe.transform(
                  this.FilterForm.controls.dateTo.value,
                  "yyyy-MM-dd"
                ) +
                "." +
                format,
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }
            )
          },
          (error) => {
            console.log(error)
            this.isDownloadingFile = false
          }
        )
    }
  }

  statusLists = [
    {
      text: "All",
      value: "null",
    },
    {
      text: "Blocked",
      value: 0,
    },
    {
      text: "SUCCESS",
      value: 1,
    },
  ]

  statusRow = [
    {
      text: "Blocked",
      value: 0,
      color: "badge-warning",
    },
    {
      text: "SUCCESS",
      value: 1,
      color: "badge-success",
    },
  ]

  messageList = [
    {
      text: "Block",
    },
    {
      text: "Unblock",
    },
  ]

  BlockedList = [
    {
      text: "true",
    },
    {
      text: "false",
    },
  ]

  messageNameList = [
    {
      text: "Able",
    },
    {
      text: "Enable",
    },
  ]

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex
    this.paginationItemPerPage = e.pageSize
    this.getFirstDatas(this.radioModel)
  }

  ngOnInit() {
    this.paginationItemPerPage = 10
    let ls = new SecureLS() // for Secure Local storage
    this.permissions = ls.get("permissions")[this.route.data["value"].pathCheck]
    if (this.permissions.write === true) {
      this.displayedColumns.push("Block")
    }


    this.translate.get("CUSTOMER.STATUS_ALL").subscribe((text) => {
      this.statusLists[0].text = text
    })

    this.translate.get("CUSTOMER.STATUS_BLOCKED").subscribe((text) => {
      this.statusLists[1].text = text
    })
    this.translate.get("CUSTOMER.STATUS_SUCCESS").subscribe((text) => {
      this.statusLists[2].text = text
    })

    this.translate.get("CUSTOMER.STATUSROW_BLOCKED").subscribe((text) => {
      this.statusRow[0].text = text
    })

    this.translate.get("CUSTOMER.STATUSROW_SUCCESS").subscribe((text) => {
      this.statusRow[1].text = text
    })

    this.translate.get("CUSTOMER.STATUS_BLOCK_MESSAGE").subscribe((text) => {
      this.messageList[0].text = text
    })

    this.translate.get("CUSTOMER.STATUS_UNBLOCK_MESSAGE").subscribe((text) => {
      this.messageList[1].text = text
    })
    this.translate.get("CUSTOMER.STATUS_ABLE_MESSAGE").subscribe((text) => {
      this.messageNameList[0].text = text
    })

    this.translate.get("CUSTOMER.STATUS_ENABLE_MESSAGE").subscribe((text) => {
      this.messageNameList[1].text = text
    })

    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      PhoneNumber: new UntypedFormControl(""),
      CustomerNumber: new UntypedFormControl(""),
      IdCardNumber: new UntypedFormControl(""),
      StatusType: new UntypedFormControl(""),
    })

    this.FilterForm.patchValue({
      ["StatusType"]: this.statusLists[0].value,
    })

    this.getFirstDatas(this.radioModel)
  }
}
