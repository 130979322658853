import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import {
	AbstractControl,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { AllNotification } from '../../../_models/notification'
import { SendNotificationDataService } from '../../../_services/send-notification.shared.service'
import { ToolsService } from '../../../_services/tools.service'
import { NotificationService } from '../../../_services/notification.service'

@Component({
	selector: 'app-new-notification',
	templateUrl: './new-notification.component.html',
	styleUrls: ['./new-notification.component.scss'],
	providers: [],
})
export class NewNotificationComponent implements OnInit {
	isDownloadingFile: boolean = false
	CreateNotification: FormGroup
	FileForm: FormGroup
	disableSelect: FormControl
	isLoadingDetailInfo: boolean = true
	currentLang: string = ''
	campaingFilter = false
	status: any
	create = false
	isLoading = false
	isUploading: boolean = false
	fileToUpload: string | null = null
	fileNoUplaod = 'No file upload...'
	newNot = true

	bank = false
	file = false
	deleteFile = false
	_name = ''
	id = ''
	fileName = ''
	fileContent = ''
	keyParams = ''
	hasValueChanged = false

	notificationData: AllNotification

	campaingList: [
		{
			CardName: ''
			Key: ''
		}
	]
	bankList: [
		{
			Name: ''
			Code: ''
			Key: ''
		}
	]

	NotificationSourceType = [
		{
			text: 'TOOLS.SOURCE_TYPE_1',
			value: 1,
		},
		{
			text: 'TOOLS.SOURCE_TYPE_2',
			value: 2,
		},
		{
			text: 'TOOLS.SOURCE_TYPE_3',
			value: 3,
		},
	]

	NotificationType = [
		{
			text: 'TOOLS.NOT_TYPE_1',
			value: 1,
		},
		{
			text: 'TOOLS.NOT_TYPE_2',
			value: 2,
		},
	]
	NotificationCategory = [
		{
			text: 'TOOLS.CATEGORY_1',
			value: 1,
		},
		{
			text: 'TOOLS.CATEGORY_2',
			value: 2,
		},
	]
	constructor(
		private notificationServer: NotificationService,
		public dialog: MatDialog,
		public translate: TranslateService,
		public toastr: ToastrService,
		private notification: SendNotificationDataService,
		private router: Router,
		private Activited: ActivatedRoute,
		private changeDetector: ChangeDetectorRef
	) {}
	ngOnInit(): void {
		this.notification.notification$.subscribe((data) => {
			this.notificationData = new AllNotification(data)
			this.notification.saveNotoficationData('notification', data)
			console.log('data', this.notificationData)
			this.status = this.notificationData.Status
			this.isLoading = true
			this.disableSelect = new FormControl(false)
			this.CreateNotification = new FormGroup({
				NotificationCategory: new FormControl(
					this.notificationData.Category,
					[Validators.required]
				),
				//Status: new FormControl(''),
				NotificationType: new FormControl(this.notificationData.Type, [
					Validators.required,
				]),
				NotificationSourceType: new FormControl(
					this.notificationData.SourceType,
					[Validators.required]
				),
				BankCode: new FormControl(this.notificationData.BankCode, []),
				Campaing: new FormControl(this.notificationData.Value, []),
				InputNameEn: new FormControl(this.notificationData.NameEn, [
					Validators.required,
				]),
				InputDescriptionEn: new FormControl(
					this.notificationData.TextEn,
					[Validators.required]
				),
				InputNameRu: new FormControl(this.notificationData.NameRu, [
					Validators.required,
				]),
				InputDescriptionRu: new FormControl(
					this.notificationData.TextRu,
					[Validators.required]
				),
				InputNameTr: new FormControl(this.notificationData.NameTr, [
					Validators.required,
				]),
				InputDescriptionTr: new FormControl(
					this.notificationData.TextTr,
					[Validators.required]
				),
				// FileUpload: new FormControl('', [Validators.required]),
			})

			if (this.status != 1 && this.status != 9) {
				this.CreateNotification.controls[
					'NotificationSourceType'
				].disable()
				this.CreateNotification.controls[
					'NotificationCategory'
				].disable()
				this.CreateNotification.controls['NotificationType'].disable()
				this.CreateNotification.controls['BankCode'].disable()
				this.CreateNotification.controls['Campaing'].disable()
			}
			this.FileForm = new FormGroup({
				FileContent: new FormControl(
					this.notificationData.FileContent,
					[]
				),
				FileName: new FormControl(this.notificationData.FileName, []),
			})
			this.fileName = this.notificationData.FileName
			this.fileContent = this.notificationData.FileContent
			console.log('filetoUload', this.fileName)

			if (
				this.CreateNotification.controls['NotificationType'].value === 2
			) {
				this.campaing()
				this.campaingFilter = true
			}
			if (
				this.CreateNotification.controls['NotificationSourceType']
					.value === 1
			) {
				this.bank = true
				this.file = false
			} else if (
				this.CreateNotification.controls['NotificationSourceType']
					.value === 2
			) {
				this.file = true
				this.bank = false
			}
		})
		// this.Activited.params.subscribe((data) => {
		// 	this.keyParams = data.id
		// })

		if (this.notificationData == null) {
			this.CreateNotification = new FormGroup({
				NotificationCategory: new FormControl('', [
					Validators.required,
				]),
				//Status: new FormControl(''),
				NotificationType: new FormControl('', [Validators.required]),
				NotificationSourceType: new FormControl('', [
					Validators.required,
				]),
				BankCode: new FormControl('', []),
				Campaing: new FormControl('', []),
				InputNameEn: new FormControl('', [Validators.required]),
				InputDescriptionEn: new FormControl('', [Validators.required]),
				InputNameTr: new FormControl('', [Validators.required]),
				InputDescriptionTr: new FormControl('', [Validators.required]),
				InputNameRu: new FormControl('', [Validators.required]),
				InputDescriptionRu: new FormControl('', [Validators.required]),
				// FileUpload: new FormControl(
				// 	'',
				// 	this.bank ? [] : [Validators.required]
				// ),
			})

			this.FileForm = new FormGroup({
				FileContent: new FormControl('', []),
				FileName: new FormControl('', []),
			})

			this.notificationServer.getBankCode().subscribe((data) => {
				this.bankList = data.ReturnObject
				console.log('bankCode', this.bankList)
			})
			this.Activited.params.subscribe((data) => {
				this.keyParams = data.id
			})

			if (this.router.url === '/notification/new-notification') {
				this.newNot = true
				this.status = 1
				this.isLoading = true
			} else if (
				this.router.url ===
				`/notification/edit-notification/${this.keyParams}`
			) {
				this.newNot = false
				this.create = true
			}
		}
		if (
			(this.router.url === '/notification/view-notification/',
			this.keyParams)
		) {
			this.updatePage()
		}
		// this.campaing()
	}

	updatePage() {
		console.log('1111', this.notificationData)
		this.notificationData =
			this.notification.getStorageNotoficationData('notification')
		if (this.notificationData) {
			this.status = this.notificationData.Status
			this.isLoading = true
			this.disableSelect = new FormControl(false)
			this.CreateNotification = new FormGroup({
				NotificationCategory: new FormControl(
					this.notificationData.Category,
					[Validators.required]
				),
				//Status: new FormControl(''),
				NotificationType: new FormControl(this.notificationData.Type, [
					Validators.required,
				]),
				NotificationSourceType: new FormControl(
					this.notificationData.SourceType,
					[Validators.required]
				),
				BankCode: new FormControl(this.notificationData.BankCode, []),
				Campaing: new FormControl(this.notificationData.Value, []),
				InputNameEn: new FormControl(this.notificationData.NameEn, [
					Validators.required,
				]),
				InputDescriptionEn: new FormControl(
					this.notificationData.TextEn,
					[Validators.required]
				),
				InputNameRu: new FormControl(this.notificationData.NameRu, [
					Validators.required,
				]),
				InputDescriptionRu: new FormControl(
					this.notificationData.TextRu,
					[Validators.required]
				),
				InputNameTr: new FormControl(this.notificationData.NameTr, [
					Validators.required,
				]),
				InputDescriptionTr: new FormControl(
					this.notificationData.TextTr,
					[Validators.required]
				),
			})

			if (this.status != 1 && this.status != 9) {
				this.CreateNotification.controls[
					'NotificationSourceType'
				].disable()
				this.CreateNotification.controls[
					'NotificationCategory'
				].disable()
				this.CreateNotification.controls['NotificationType'].disable()
				this.CreateNotification.controls['BankCode'].disable()
				this.CreateNotification.controls['Campaing'].disable()
			}
			this.FileForm = new FormGroup({
				FileContent: new FormControl(
					this.notificationData.FileContent,
					[]
				),
				FileName: new FormControl(this.notificationData.FileName, []),
			})
			this.fileName = this.notificationData.FileName
			this.fileContent = this.notificationData.FileContent

			if (
				this.CreateNotification.controls['NotificationType'].value === 2
			) {
				this.campaing()
				this.campaingFilter = true
			}
			if (
				this.CreateNotification.controls['NotificationSourceType']
					.value === 1
			) {
				this.bank = true
				this.file = false
			} else if (
				this.CreateNotification.controls['NotificationSourceType']
					.value === 2
			) {
				this.file = true
				this.bank = false
			}
		}
	}

	CloseWindow() {
		window.location.href = '/#/notification'
	}

	onChange() {
		this.create = false
		this.hasValueChanged = true
		console.log('work')
	}

	// ngAfterContentChecked(): void {
	// 	this.changeDetector.detectChanges()
	// }

	OpenDialog(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			// panelClass: 'modal_transaction',
			minHeight: '380px',
			width: '38vw',
		})
	}
	CloseDialog() {
		this.dialog.closeAll()
	}

	validateXLSXFormat(
		control: AbstractControl
	): { [key: string]: any } | null {
		const file = control.value

		if (file) {
			const fileName = file
			const fileType = fileName.slice(
				((fileName.lastIndexOf('.') - 1) >>> 0) + 2
			)

			if (fileType.toLowerCase() === 'xlsx') {
				return null
			}
		}

		return { invalidXLSXFormat: true }
	}

	CheckCampaing($event) {
		const campaing = this.CreateNotification.get('Campaing') as FormControl
		if ($event === 2) {
			this.campaing()
			this.campaingFilter = true
			campaing.setValidators([Validators.required])
		} else {
			campaing.clearValidators()
			campaing.setValue('')
			this.campaingFilter = false
		}
		campaing.updateValueAndValidity()
	}

	CheckMaling($event) {
		const bankCode = this.CreateNotification.get('BankCode') as FormControl
		const fileName = this.FileForm.get('FileName') as FormControl
		if ($event === 1) {
			this.getBank()
			this.bank = true
			this.file = false
			bankCode.setValidators([Validators.required])
			fileName.clearValidators()
		} else if ($event === 2) {
			bankCode.clearValidators()
			this.bank = false
			this.file = true
			fileName.setValidators([this.validateXLSXFormat])
			bankCode.setValue('')
		} else {
			bankCode.clearValidators()
			fileName.clearValidators()
			this.bank = false
			this.file = false
			bankCode.setValue('')
		}
		bankCode.updateValueAndValidity()
		fileName.updateValueAndValidity()
	}

	campaing() {
		if (localStorage.getItem('currentLang')) {
			this.currentLang = localStorage.getItem('currentLang')
		} else {
			this.currentLang = this.translate.getBrowserLang()
		}
		let status = 1
		let description = null
		let fetchPage = 1
		let fetchCount = 10000
		this.notificationServer
			.getCampaing(
				status,
				description,
				fetchPage,
				fetchCount,

				this.currentLang
			)
			.subscribe((data) => {
				console.log('campaing', data)
				this.campaingList = data.ReturnObject.Items
			})
	}

	getBank() {
		this.notificationServer.getBankCode().subscribe((data) => {
			this.bankList = data.ReturnObject
			console.log('bankCode', this.bankList)
		})
	}

	CreateNewNotification() {
		if (this.CreateNotification.valid && this.FileForm.valid) {
			this.create = true
			console.log('worrk new notification', this.CreateNotification.value)
			this.notificationServer
				.createNotification(
					this.FileForm.controls['FileContent'].value,
					this.FileForm.controls['FileName'].value,
					this.CreateNotification.controls['NotificationCategory']
						.value,
					this.CreateNotification.controls['NotificationSourceType']
						.value,

					this.CreateNotification.controls['Campaing'].value,
					//this.CreateNotification.controls['Status'].value,
					this.CreateNotification.controls['NotificationType'].value,
					this.CreateNotification.controls['BankCode'].value,
					this.CreateNotification.controls['InputNameEn'].value,
					this.CreateNotification.controls['InputDescriptionEn']
						.value,
					this.CreateNotification.controls['InputNameTr'].value,
					this.CreateNotification.controls['InputDescriptionTr']
						.value,
					this.CreateNotification.controls['InputNameRu'].value,
					this.CreateNotification.controls['InputDescriptionRu'].value
				)
				.subscribe(
					(data) => {
						window.location.href = '/#/notification'
						console.log('data notifiaction', data)
						let body = ''
						let theme = ''
						this.translate
							.get('TOOLS.CREATE_SUCCESS_MESSAGE')
							.subscribe((text) => {
								body = text
							})
						this.translate
							.get('TOOLS.CREATE_SUCCESS')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.success(body, theme)
					},
					(error) => {
						console.log(error)
						let body = ''
						let theme = ''
						this.translate
							.get(error.error.Message)
							.subscribe((text) => {
								body = text
							})
						this.translate
							.get('TOOLS.ERROR_FILE')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(body, theme)
					}
				)
		} else if (
			!this.FileForm.valid &&
			this.FileForm.value.FileContent == ''
		) {
			this.create = false
			let body = ''
			let theme = ''
			this.translate
				.get('TOOLS.CREATE_ERROR_FORMAT')
				.subscribe((text) => {
					body = text
				})
			this.translate.get('TOOLS.CREATE_ERROR').subscribe((text) => {
				theme = text
			})
			this.toastr.error(body, theme)
		} else {
			let body = ''
			let theme = ''
			this.translate
				.get('TOOLS.CREATE_ERROR_MESSAGE')
				.subscribe((text) => {
					body = text
				})
			this.translate.get('TOOLS.CREATE_ERROR').subscribe((text) => {
				theme = text
			})
			this.toastr.error(body, theme)
		}
	}

	SaveNotification() {
		if (this.CreateNotification.valid && this.FileForm.valid) {
			this.create = true
			let status = ''
			this.id = this.notificationData.Id
			let userName = ''
			this.notificationServer
				.saveNotification(
					this.id,
					this.CreateNotification.controls['NotificationType'].value,
					this.CreateNotification.controls['NotificationCategory']
						.value,
					this.CreateNotification.controls['NotificationSourceType']
						.value,
					this.CreateNotification.controls['Campaing'].value,
					status,
					this.CreateNotification.controls['BankCode'].value,
					// this.fileToUpload,
					this.FileForm.controls['FileContent'].value,
					this.FileForm.controls['FileName'].value,
					// this._name,
					this.CreateNotification.controls['InputNameEn'].value,
					this.CreateNotification.controls['InputDescriptionEn']
						.value,
					this.CreateNotification.controls['InputNameTr'].value,
					this.CreateNotification.controls['InputDescriptionTr']
						.value,
					this.CreateNotification.controls['InputNameRu'].value,
					this.CreateNotification.controls['InputDescriptionRu']
						.value,
					userName
				)
				.subscribe(
					(data) => {
						window.location.href = '/#/notification'
						let body = ''
						let theme = ''
						this.translate
							.get('TOOLS.SAVE_SUCCESS_MESSAGE')
							.subscribe((text) => {
								body = text
							})
						this.translate
							.get('TOOLS.SAVE_SUCCESS')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.success(body, theme)
					},
					(error) => {
						console.log(error)
						let body = ''
						let theme = ''
						this.translate
							.get(error.error.Message)
							.subscribe((text) => {
								body = text
							})
						this.translate
							.get('TOOLS.ERROR_FILE')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(body, theme)
					}
				)
		} else if (
			!this.FileForm.valid &&
			this.FileForm.value.FileContent != ''
		) {
			this.create = false
			let body = ''
			let theme = ''
			this.translate.get('TOOLS.SAVE_ERROR_FORMAT').subscribe((text) => {
				body = text
			})
			this.translate.get('TOOLS.SAVE_ERROR').subscribe((text) => {
				theme = text
			})
			this.toastr.error(body, theme)
		} else {
			let body = ''
			let theme = ''
			this.translate
				.get('TOOLS.SAVE_ERROR_MESSAGE_')
				.subscribe((text) => {
					body = text
				})
			this.translate.get('TOOLS.SAVE_ERROR').subscribe((text) => {
				theme = text
			})
			this.toastr.error(body, theme)
		}
	}

	DeleteFile() {
		this.fileName = ''
		this.fileNoUplaod = 'No file upload...'
		this.deleteFile = false
	}

	handleFileInput(files: FileList, target) {
		this.fileToUpload = null
		this.isUploading = true
		let fileSize = 5.995 * 1024 * 1024
		if (files && files[0]) {
			let reader: any
			this._name = files[0].name
			let file = files[0]
			if (this._name.length < 3 || this._name.length > 128) {
				let theme = ''
				let body = ''
				this.translate
					.get('TOOLS.THEME_FILEUPLOAD_FAIL')
					.subscribe((text) => {
						theme = text
					})
				this.translate
					.get(
						!this.FileForm.valid
							? 'TOOLS.BODY_FILEUPLOAD_LENGTH_ER'
							: 'TOOLS.ERROR_FORMAT'
					)
					.subscribe((text) => {
						body = text
					})
				this.toastr.error(body, theme)
				// } else if (file.size > fileSize) {
				// 	let theme = ''
				// 	let body = ''
				// 	this.translate
				// 		.get('TOOLS.THEME_FILE_SIZE')
				// 		.subscribe((text) => {
				// 			theme = text
				// 		})
				// 	this.translate
				// 		.get('TOOLS.THEME_FILE_SIZE_TEXT')
				// 		.subscribe((text) => {
				// 			body = text
				// 		})
				// 	this.toastr.error(body, theme)
			} else {
				reader = new FileReader()
				reader.readAsDataURL(files[0]) // read file as data url
				reader.onload = (event) => {
					this.fileToUpload = event.target.result.split(',')[1]
					this.fileName = this._name
					this.fileContent = this.fileToUpload

					if (this.fileName == this._name) {
						this.create = false
						this.hasValueChanged = true
						this.deleteFile = true
					}
					if (!this.fileToUpload) {
						// let theme = ''
						// let body = ''
						// this.translate
						// 	.get('TOOLS.FILEUNOPLOAD_ERROR')
						// 	.subscribe((text) => {
						// 		theme = text
						// 	})
						// this.translate
						// 	.get('TOOLS.FILEUNOPLOAD_ERROR_TEXT')
						// 	.subscribe((text) => {
						// 		body = text
						// 	})
						// this.toastr.error(body, theme)
						return false
					} else {
						this.fileNoUplaod = ''
					}
				}
			}
			target.value = ''
		}
	}
}
