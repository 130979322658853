import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DataSource } from '@angular/cdk/table';
import { ReportsDatasService } from '../../_services/reportsdata.service';
import { AdministrationService } from '../../_services/administration.service';
import { DatePipe } from '@angular/common';
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export interface ItemList {
    Value: string;
    Name: string;
}

export interface CityList {
    Value: string;
    Name: string;
}

export interface SaleList {
    Value: string;
    Name: string;
}

// For custom Error Validations Messages
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}

@Component({
    templateUrl: 'users.component.html',
    styleUrls: ['administration.scss'],
    providers: [DatePipe]
})



export class UsersComponent implements OnInit {
    matcher = new MyErrorStateMatcher(); // for my Error state | Colors and other
    permissions;

    public paginationItems; // for display pagination from api
    public paginationItemPerPage; // for display pagination from api

    public transactionCurrentID;
    public transactionCurrentDealerName;
    public transactionCurrentServiceName;
    public currentPage = 0;
    isLoadingDetailInfo: boolean = true; // spinner

    // 

    // 

    //-------------------------------------
    providersList;
    Transactions_Datas;
    saleListOptions: SaleList[];
    citiesListOptions: CityList[];
    dealerListOptions: ItemList[]; // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];
    loading = false; // for disable fast response click
    pageEvent; // for fix error of pageEvent in Visual Studio
    panelOpenState = true;
    // For change pages

    services: ItemList[];

    account = ''; // Account input
    refStan = ''; // refStan input
    serviceSelected = ''; // Service selected
    userLogin = ''; // User Login input
    changePasswordError = '';
    events: string[] = []; // Date picker
    dealerFControl = new UntypedFormControl(); // Dealer
    createUserError = ''; // Validation on User


    dealerFilteredOptions: Observable<ItemList[]>; // dealer
    citiesFilteredOptions: Observable<CityList[]>; // cities
    saleFilteredOptions: Observable<SaleList[]>; // salePoints
    currentTime = new Date();
    //----------------- list of columns 
    displayedColumns: string[] = ['UserId', 'UserName', 'Login', 'UserEmail', 'UserMobile', 'UserStatus', 'Operations'];
    dataSource = new MatTableDataSource(); // array of datas
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog,
        // Make service for post api datas
        private datePipe: DatePipe,
        private reportsDataService: ReportsDatasService,
        private providerService: ProviderService,
        private administrationService: AdministrationService,
        private formBuilder: UntypedFormBuilder,
        public toastr: ToastrService,
        public route: ActivatedRoute,
        private translate: TranslateService
    ) {
        this.ChangePassword = this.formBuilder.group({
            'Password': ['', [Validators.required, Validators.pattern(/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,16}$/), Validators.minLength(8), Validators.maxLength(16)]],
            'RepeatPassword': ['', [Validators.required]]
        }, { validator: this.checkPasswords });
        // Assign the data to the data source for the table to render
        // this.dataSource = new MatTableDataSource(users);
    }
    editUserError = '';
    FilterForm: UntypedFormGroup; // Form initial
    StatusForm: UntypedFormGroup; // Form initial
    submitted = false;
    CreateUser: UntypedFormGroup;
    EditForm: UntypedFormGroup; // Form initial
    ChangePassword: UntypedFormGroup; // Form initial
    //dealerDropValidator(controls): ValidatorFn {
    //    return (control: AbstractControl): { [key: string]: boolean } | null => {
    //        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
    //            return { 'dealer': true };
    //        }
    //        return null;
    //    };
    //}

    checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group and check repeat password | Custom validation
        let password = group.controls.Password.value;
        let repeatpassword = group.controls.RepeatPassword.value;

        return password === repeatpassword ? null : { notSame: true }
    }

    // rolesList = [{ value: 0, viewValue: 'Admin' }, { value: 1, viewValue: 'User' }];
    rolesList = [];

    States = [
        { Key: 0, Name: 'Blocked' },
        { Key: 1, Name: 'Active' },
    ];

    FilterStates = [
        { Key: -1, Name: 'All' },
        { Key: 0, Name: 'Blocked' },
        { Key: 1, Name: 'Active' },
    ];
    //----------------------  
    public handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.paginationItemPerPage = e.pageSize;
        this.getFirstDatas();

    }

    getFirstDatas() {
        this.loading = true;
        // Take datas from form
        // let dealerId = this.dealerFControl.value === null ? null : this.dealerFControl.value.Value;
        let filter = this.FilterForm.value;
        let currentPage = this.currentPage + 1;
        // api for get Users
        this.administrationService.getUsersList(filter.UserName, filter.UserLogin, filter.UserEmail, filter.Status, currentPage, this.paginationItemPerPage)
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        if (data.ReturnObject !== null) {
                            this.Transactions_Datas = data.ReturnObject.Users;
                            this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	

                            //this.dataSource.sort = this.sort; // activate sort after insert datas
                            // this.dataSource.paginator = this.paginator; // activate paginator after insert datas
                            this.paginationItems = data.ReturnObject.Pagination.ItemTotal;
                        }
                        else {
                            this.paginationItems = 0;
                            this.Transactions_Datas = [];
                            this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log(error);
                });
    }

    onSubmit() {
        this.getFirstDatas();
    }

    displayDealer(item?: ItemList): string | undefined {
        return item ? item.Name : undefined;
    }


    private _filter(item: string): ItemList[] {
        const filterValue = item.toLowerCase();
        return this.dealerListOptions.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }

    //----------------------
    PaginationCurentPageReset(e: any) {
        this.currentPage = 0;
    }
    //------------------- private helpers 
    // convenience getter for easy access to form fields
    get f() { return this.FilterForm.controls; }


    editUser() {
        console.log(this.EditForm);
        this.markFormGroupTouched(this.EditForm);
        this.editUserError = '';
        if (this.EditForm.valid === true) {
            // Make again gets all users()
            this.loading = true;
            this.administrationService.editUser(this.modalDatas, this.EditForm.value, this.rolesList.length)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            console.log('User Datas Changed');
                            this.loading = false;
                            this.getFirstDatas();
                            this.CloseDialog();
                        }
                        else {
                            this.loading = false;
                            this.editUserError = data.ResultMessage;
                        }
                    },
                    error => {
                        this.loading = false;
                        console.log(error);
                    });

        }
    }

    changePassword() {
        this.markFormGroupTouched(this.ChangePassword);
        this.submitted = true;
        this.changePasswordError = '';
        console.log(this.ChangePassword);
        if (this.ChangePassword.valid === true) {
            this.loading = true;
            this.administrationService.changePassword(this.modalDatas.Id, this.ChangePassword.value.Password, this.ChangePassword.value.RepeatPassword)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            console.log('Change Password');
                            this.submitted = false;
                            this.toastr.success('Success', 'Password was changed!');
                            this.loading = false;
                            this.CloseDialog();
                        }
                        else {
                            this.loading = false;
                            data.ResultMessage === null ? this.changePasswordError = '' : this.changePasswordError = data.ResultMessage;
                            this.toastr.error(this.changePasswordError, 'Error');
                        }
                    },
                    error => {
                        this.loading = false;
                        console.log(error);
                    });
        }
    }
    modalDatas = {
        'UserId': '',
        'Id': ''
    };// for display datas in modal

    statusStates = [
        { value: false, viewValue: 'InActive' },
        { value: true, viewValue: 'Active' },
    ];

    CloseDialog(): void {
        this.dialog.closeAll();
    }

    openCreateUser(templateRef) {
        this.createUserError = '';
        // this.CreateUser.reset();
        this.markFormGroupUnTouched(this.CreateUser);
        if (this.loading === false) {
            this.rolesList = [];
            this.loading = true;
            this.openModal(templateRef);
            this.isLoadingDetailInfo = true;
            this.administrationService.getRolesUser()
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            this.rolesList = data.ReturnObject;
                            if (this.rolesList.length) {
                                let counter = 0;
                                this.rolesList.forEach(role => {
                                    role['Id'] = counter;
                                    this.CreateUser.removeControl('Key_' + role.Id);
                                    this.CreateUser.removeControl('Value_' + role.Id);
                                    this.CreateUser.addControl('Key_' + role.Id, new UntypedFormControl(role.Key)); // Initialize form rules
                                    this.CreateUser.addControl('Value_' + role.Id, new UntypedFormControl(role.IsOn)); // Initialize form rules
                                    counter++;
                                });
                            }
                            console.log(this.rolesList);
                        }
                        else {
                            this.createUserError = data.ResultMessage;
                        }
                        this.isLoadingDetailInfo = false;
                        this.loading = false;

                    },
                    error => {
                        this.isLoadingDetailInfo = false;
                        this.loading = false;
                        console.log(error);
                    });
            // Api for modal detail information
        }
    }

    openStatus(templateRef, row) {
        if (this.loading === false) {
            this.isLoadingDetailInfo = true;
            this.openModal(templateRef);
            this.modalDatas = row;
            this.StatusForm.patchValue({ ['Status']: Boolean(row.State) });
        }
    }

    openSettings(templateRef, row) {
        if (this.loading === false) {
            this.rolesList = [];
            this.loading = true;
            this.openModal(templateRef);
            this.isLoadingDetailInfo = true;
            this.administrationService.getUserInfo(row.Id)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            if (data.ReturnObject !== null) {
                                this.modalDatas = data.ReturnObject;
                                this.rolesList = data.ReturnObject.RoleList;
                                if (this.rolesList.length) {
                                    let counter = 0;
                                    this.rolesList.forEach(role => {
                                        role['Id'] = counter;
                                        this.EditForm.removeControl('Key_' + role.Id);
                                        this.EditForm.removeControl('Value_' + role.Id);
                                        this.EditForm.addControl('Key_' + role.Id, new UntypedFormControl(role.Key)); // Initialize form rules
                                        this.EditForm.addControl('Value_' + role.Id, new UntypedFormControl(role.IsOn)); // Initialize form rules
                                        counter++;
                                    });
                                }
                                console.log(this.rolesList);
                                this.editDatasPush(this.modalDatas, templateRef)
                            }
                            else {
                                this.isLoadingDetailInfo = false;
                            }
                            this.loading = false;
                        }
                        else {
                            this.loading = false;
                        }
                    },
                    error => {
                        this.isLoadingDetailInfo = false;
                        this.loading = false;
                        console.log(error);
                    });
            // Api for modal detail information
        }
    }

    editDatasPush(datas, templateRef) {
        this.EditForm.patchValue({ ['UserId']: datas.Info.UserId });
        this.EditForm.patchValue({ ['UserName']: datas.Info.UserName });
        this.EditForm.patchValue({ ['UserEmail']: datas.Info.UserEMail });
        this.EditForm.patchValue({ ['UserLogin']: datas.Info.Login });
        this.EditForm.patchValue({ ['UserMobile']: datas.Info.UserMobile });
        this.EditForm.patchValue({ ['Status']: datas.Info.UserStatus });
        // console.log(this.rolesList);
        // for (let i = 0; i < this.rolesList.length; i++) {
        // 	this.EditForm.patchValue({ ['Key_' + i]: this.rolesList[i].ItemKey });
        //     this.EditForm.patchValue({ ['Value_' + i]: this.rolesList[i].IsOn });
        // }
        console.log(this.EditForm.value);
        // console.log(this.rolesList);
        // console.log(this.rolesList);
        this.isLoadingDetailInfo = false;

        // this.EditForm.patchValue({ ['Roles']: datas.UserId });
    }

    openChangePassword(templateRef, row) {
        this.changePasswordError = '';
        this.submitted = false;
        if (this.loading === false) {
            this.isLoadingDetailInfo = true;
            this.ChangePassword.reset();
            this.openModal(templateRef);
            this.modalDatas = row;
        }
    }

    openModal(templateRef) {
        this.isLoadingDetailInfo = false;
        const dialogRef = this.dialog.open(templateRef, {
            panelClass: 'modal_transaction',
        });
    }

    /**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
    private markFormGroupTouched(formGroup: UntypedFormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }
    private markFormGroupUnTouched(formGroup: UntypedFormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsUntouched();

            if (control.controls) {
                this.markFormGroupUnTouched(control);
            }
        });
    }

    newUser() {
        console.log(this.CreateUser);
        this.markFormGroupTouched(this.CreateUser);
        this.createUserError = '';
        if (this.CreateUser.valid === true) {
            // Make again gets all users()
            this.loading = true;
            this.administrationService.createUser(this.CreateUser.value, this.rolesList.length)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            console.log('User Datas Changed');
                            this.loading = false;
                            this.CreateUser.reset();
                            this.CloseDialog();
                            this.getFirstDatas();
                        }
                        else {
                            this.loading = false;
                            this.editUserError = data.ResultMessage;
                        }
                    },
                    error => {
                        this.loading = false;
                        console.log(error);
                    });
        }
    }

    // @Input() permissions;
    ngOnInit() {
        // console.log(this.permissions);
        let ls = new SecureLS();
        this.permissions = ls.get('permissions')[this.route.data['value'].pathCheck];
        console.log(this.permissions);
        // if(this.permissions.actions.write === true){
        //     this.displayedColumns.push('Operations');
        // } 
        this.paginationItemPerPage = 10;
        this.translate.get('REPORT.ALL').subscribe((text) => { this.FilterStates[0].Name = text; }); // Text in component
        this.translate.get('REPORT.BLOCKED').subscribe((text) => { this.FilterStates[1].Name = text; }); // Text in component
        this.translate.get('REPORT.ACTIVE').subscribe((text) => { this.FilterStates[2].Name = text; }); // Text in component
        this.translate.get('REPORT.BLOCKED').subscribe((text) => { this.States[0].Name = text; }); // Text in component
        this.translate.get('REPORT.ACTIVE').subscribe((text) => { this.States[1].Name = text; }); // Text in component
        //this.InitDealerFilter();
        this.FilterForm = new UntypedFormGroup({
            'UserName': new UntypedFormControl(''),
            'UserLogin': new UntypedFormControl(''),
            'UserEmail': new UntypedFormControl(''),
            'Status': new UntypedFormControl(this.FilterStates[0].Key)
        }); // for header Filter

        this.StatusForm = new UntypedFormGroup({
            'Status': new UntypedFormControl('', Validators.required),
        });

        this.CreateUser = this.formBuilder.group({
            'UserName': ['', Validators.required],
            'UserEmail': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
            'UserLogin': ['', Validators.required],
            'UserMobile': ['', Validators.required]
        });

        this.EditForm = this.formBuilder.group({
            'UserName': ['', Validators.required],
            'UserEmail': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
            'UserLogin': [{ value: '', disabled: true }, Validators.required],
            'UserMobile': ['', Validators.required],
            'Status': ['', Validators.required]
            // 'UserLogin': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern(/^[a-zA-Z0-9]*$/)]],
            // 'UserMobile': ['', [Validators.required, Validators.pattern(/^373[67][0-9]{7}$/)]],
            // 'Roles':['', Validators.required]
        });

        // this.ChangePassword = new FormGroup({
        //     'Password': new FormControl('', [Validators.required, Validators.minLength(6)]),
        //     'RepeatPassword': new FormControl('', Validators.required),
        // });
        // for Status modal form
        this.getFirstDatas();
    }
}