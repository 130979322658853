import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { DataSource } from '@angular/cdk/table'
import { ReportsDatasService } from '../../../_services/reportsdata.service'
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { Globals } from '../../../globals/globals'

@Component({
	templateUrl: './transac-report.component.html',
	styleUrls: ['../reports.scss'],
	providers: [DatePipe, Globals],
})
export class TransacReportComponent implements OnInit {
	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		public translate: TranslateService,
		private reportsDataService: ReportsDatasService,
		private datePipe: DatePipe,
		public route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private globals: Globals
	) {}
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	permissions

	modalDatas: DetailedInfo = {}
	radioModel = 1
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner
	isDownloadingFile: boolean = false // spinner
	Info
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	// For change pages
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	currentTime = new Date()
	allPageLoad: boolean = false

	displayedColumns: string[] = [
		'SaleType',
		'BankID',
		'OrderNumber',
		'OperationDate',
		'MoneyProvider',
		'MerchantName',
		'PayDate',
		'CardNumber',
		'InstallmentNumber',
		'SalePaymentType',
		'OrderAmount',
		'Fee',
		'CPMerchantID',
		'MerchantID',
		'Details',
		'Status',
	]
	whatBadge(name) {
		for (let i = 0; i < this.statusLists.length; i++) {
			if (this.statusLists[i].value == name) {
				return this.statusLists[i].color
			}
		}
	}
	whatText(name) {
		for (let i = 0; i < this.statusLists.length; i++) {
			if (this.statusLists[i].value == name) {
				return this.statusLists[i].text
			}
		}
	}
	salePayTypeList = [
		{
			text: 'All',
			value: 0,
		},
		{
			text: 'Sale',
			value: 1,
		},
		{
			text: 'Installment',
			value: 2,
		},
		{
			text: 'Account',
			value: 3,
		},
		{
			text: 'Bonus',
			value: 4,
		},
	]
	statusLists = [
		{
			text: 'All',
			color: '',
			value: 'null',
		},
		{
			text: 'Init',
			color: 'badge-primary',
			value: 0,
		},
		{
			text: 'Pending',
			color: 'badge-warning',
			value: 1,
		},
		{
			text: 'Canceled',
			color: 'badge-danger',
			value: 2,
		},
		{
			text: 'Approved',
			color: 'badge-success',
			value: 3,
		},
		{
			text: 'Declined',
			color: 'badge-secondary',
			value: 4,
		},
		{
			text: 'Reversed',
			color: 'badge-secondary',
			value: 5,
		},
		{
			text: 'Problem',
			color: 'badge-danger',
			value: 6,
		},
		{
			text: 'Closed',
			color: 'badge-secondary',
			value: 7,
		},
	]

	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator

	FilterForm: UntypedFormGroup // Form initial

	filterStatusLists = [
		{
			text: 'All',
			value: 'null',
			color: '',
		},
		{
			text: 'Init',
			value: 0,
			color: 'badge-warning',
		},
		{
			text: 'Pay',
			value: 3,
			color: 'badge-success',
		},
		{
			text: 'Close',
			value: 6,
			color: 'badge-secondary',
		},
		{
			text: 'Refund',
			value: 4,
			color: 'badge-danger',
		},
		{
			text: 'Refund_partial',
			value: 7,
			color: 'badge-danger',
		},
	]

	MerchantsGets() {
		this.reportsDataService.getMerchantList().subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					if (data.ReturnObject !== null) {
						this.merchantList = data.ReturnObject
					}
				}
				this.translate
					.get('REPORT.FILTER_MERCHANT_ALL')
					.subscribe((text) => {
						this.merchantList.unshift({
							id: 'null',
							Name: text,
							Description: '',
							Key: '',
						})
					})
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log(error)
			},
			() => {
				this.FilterForm.patchValue({
					['Merchant']: this.merchantList[0].id,
					['Status']: this.statusLists[0].value,
				})
				this.allPageLoad = true
				this.getFirstDatas(this.radioModel)
			}
		)
	}
	download(format) {
		if (this.loading === false && this.isDownloadingFile === false) {
			let start = this.datePipe.transform(
				this.FilterForm.controls.dateFrom.value,
				'yyyy-MM-ddT00:00:00'
			)
			let finish = this.datePipe.transform(
				this.FilterForm.controls.dateTo.value,
				'yyyy-MM-ddT23:59:59'
			)
			this.isDownloadingFile = true
			this.reportsDataService
				.getPaymentFile(
					start,
					finish,
					this.radioModel,
					this.FilterForm.value.ProductCode,
					this.FilterForm.value.Merchant,
					this.FilterForm.value.Status,
					this.FilterForm.value.BankID,
					this.FilterForm.value.CardNumber,
					this.FilterForm.value.SalePayTypeList,
					this.FilterForm.value.MerchantCode,

					this.FilterForm.value.RRN,

					format
				)
				.subscribe(
					(res) => {
						this.isDownloadingFile = false
						saveAs(
							res,
							'TransactionReport_' +
								this.datePipe.transform(
									this.FilterForm.controls.dateTo.value,
									'yyyy-MM-dd'
								) +
								'.' +
								format,
							{
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							}
						)
					},
					(error) => {
						console.log(error)
						this.isDownloadingFile = false
					}
				)
		}
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	openInfo(templateRef, id, checkid) {
		this.loading = true
		this.isLoadingDetailInfo = true
		this.dialog.open(templateRef, {})
		this.modalDatas.CheckoutInfo = null
		this.modalDatas.PaymentInfo = null
		this.reportsDataService.getTransactionItem(id, checkid).subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					this.modalDatas.CheckoutInfo =
						data.ReturnObject.CheckoutModel ?? null
					this.modalDatas.PaymentInfo =
						data.ReturnObject.PaymentReportModel ?? null
				}
				this.isLoadingDetailInfo = false
				this.loading = false
			},
			(error) => {
				this.loading = false
				this.isLoadingDetailInfo = false
				console.log(error)
			}
		)
	}

	getFirstDatas(period) {
		this.loading = true
		this.radioModel = period
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddT00:00:00'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddT23:59:59'
		)
		// Status: new FormControl(""),
		//   BankID: new FormControl(""),
		//   CardNumber: new FormControl(""),
		//   RRN: new FormControl(""),
		this.reportsDataService
			.getTransactionsDatas(
				start,
				finish,
				period,
				this.paginationItemPerPage,
				this.currentPage + 1,
				this.FilterForm.value.ProductCode,
				this.FilterForm.value.Merchant,
				this.FilterForm.value.Status,
				this.FilterForm.value.BankID,
				this.FilterForm.value.CardNumber,
				this.FilterForm.value.SalePayTypeList,
				this.FilterForm.value.MerchantCode,
				this.FilterForm.value.RRN
			)
			.subscribe(
				(data) => {
					let datas = []
					datas = data.ReturnObject.Reports
					this.totalAmount = 0
					this.totalFees = 0
					this.paginationItems = 0
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.Reports
							this.paginationItems = data.ReturnObject.Fetch.Cnt
							this.totalAmount =
								data.ReturnObject.Turnover.TotalAmounts
							this.totalFees =
								data.ReturnObject.Turnover.TotalFees
						}
					}
					this.dataSource = new MatTableDataSource(datas) // array of datas
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}
	totalAmount: number = 0
	totalFees: number = 0
	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}
	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}

	ngOnInit() {
		let ls = new SecureLS() // for Secure Local storage
		if (ls.get('info') !== null) {
			this.Info = ls.get('info')
		}
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]

		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),

			Merchant: new UntypedFormControl(''),
			ProductCode: new UntypedFormControl(''),
			Status: new UntypedFormControl(''),
			BankID: new UntypedFormControl(''),
			CardNumber: new UntypedFormControl(''),
			RRN: new UntypedFormControl(''),
			SalePayTypeList: new UntypedFormControl(
				this.salePayTypeList[0].value
			),
			MerchantCode: new UntypedFormControl(''),
		}) // for header Filter
		this.translate.get('REPORT.STATUS_ALL_TEXT').subscribe((text) => {
			this.statusLists[1].text = text
		})
		this.translate.get('REPORT.STATUS_INIT_TEXT').subscribe((text) => {
			this.statusLists[1].text = text
		})
		this.translate.get('REPORT.STATUS_PENDING_TEXT').subscribe((text) => {
			this.statusLists[2].text = text
		})
		this.translate.get('REPORT.STATUS_CANCELED_TEXT').subscribe((text) => {
			this.statusLists[3].text = text
		})
		this.translate.get('REPORT.STATUS_APPROVED_TEXT').subscribe((text) => {
			this.statusLists[4].text = text
		})
		this.translate.get('REPORT.STATUS_DECLINED_TEXT').subscribe((text) => {
			this.statusLists[5].text = text
		})
		this.translate.get('REPORT.STATUS_REVERSED_TEXT').subscribe((text) => {
			this.statusLists[6].text = text
		})
		this.translate.get('REPORT.STATUS_PROBLEM_TEXT').subscribe((text) => {
			this.statusLists[7].text = text
		})
		this.translate.get('REPORT.STATUS_CLOSED_TEXT').subscribe((text) => {
			this.statusLists[8].text = text
		})

		this.translate.get('REPORT.SALE_PAY_TYPE_0').subscribe((text) => {
			this.salePayTypeList[0].text = text
		})
		this.translate.get('REPORT.SALE_PAY_TYPE_1').subscribe((text) => {
			this.salePayTypeList[1].text = text
		})
		this.translate.get('REPORT.SALE_PAY_TYPE_2').subscribe((text) => {
			this.salePayTypeList[2].text = text
		})
		this.translate.get('REPORT.SALE_PAY_TYPE_3').subscribe((text) => {
			this.salePayTypeList[3].text = text
		})
		this.translate.get('REPORT.SALE_PAY_TYPE_4').subscribe((text) => {
			this.salePayTypeList[4].text = text
		})

		// (Init = 0),
		//   (Pending = 1),
		//   (Canceled = 2),
		//   (Approved = 3),
		//   (Declined = 4),
		//   (Reversed = 5),
		//   (Problem = 6),
		//   (Closed = 7);
		this.MerchantsGets()
	}
	public merchantList: Merchant[] = []
}

interface Merchant {
	Key: string
	id: number | string
	Name: string
	Description: string
}
export interface PaymentInfo {
	BankResponse: string
	MerchantName: string
	Status: string
	OrderNumber: string
	Amount: number
	PaymentDate: Date
	Fee: number
	BankID: string
	PaymentMethod: string
	CardNumber: string
	Payer: string
	Description: string
}
export interface CheckoutInfo {}
export interface DetailedInfo {
	PaymentInfo?: PaymentInfo
	CheckoutInfo?
}
