import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../_services";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DataSource } from "@angular/cdk/table";
import { ReportsDatasService } from "../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
import { saveAs } from "file-saver";

/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../../globals/globals";

export interface Merchant {
  id: string;
  Name: string;
}

@Component({
  templateUrl: "withdraw-requests.component.html",
  styleUrls: ["reports.scss"],
  providers: [DatePipe, Globals],
})
export class WithdrawRequestsComponent implements OnInit {
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  saveWithdrawError = "";
  permissions;
  radioModel = 1;
  modalDatas = {
    ID: "",
    MerchantName: "",
    OperationAccountingTypeName: "",
    Registered: "",
    Confirmed: "",
    Fee: "",
    Amount: "",
    Status: 0,
    Processes: "",
    CurrencyCode: "TRY",
    BankDetails: {
      AccountName: "",
      AccountNumber: "",
      BankBranch: "",
      BankCode: "",
      CountryName: "",
      IBAN: "",
      TVA: "",
    },
  };
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  isDownloadingFile: boolean = false; // spinner
  Info;
  loading = false; // for disable fast response click
  disableButtonSave = true; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  allPageLoad: boolean = false;
  MerchantList: Merchant[] = [{ id: "", Name: "" }];

  displayedColumns: string[] = [
    "Id",
    "MerchantName",
    "Date",
    "Amount",
    "CustomField",
    "Fee",
    "Status",
  ];
  filteredOptions: Observable<Merchant[]>;

  displayFn(user?: Merchant): string | undefined {
    return user ? user.Name : undefined;
  }

  private _filter(name: string): Merchant[] {
    const filterValue = name.toLowerCase();
    return this.MerchantList.filter(
      (option) => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  statusLists = [
    {
      text: "New",
      color: "badge-secondary",
      value: 0,
    },
    {
      text: "Initialized",
      color: "badge-light",
      value: 1,
    },
    {
      text: "InProgress",
      color: "badge-warning",
      value: 2,
    },
    {
      text: "Successful",
      color: "badge-success",
      value: 3,
    },
    {
      text: "Reject",
      color: "badge-danger",
      value: 3,
    },
  ];

  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private reportsDataService: ReportsDatasService,
    private datePipe: DatePipe,
    public route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private globals: Globals
  ) {}

  FilterForm: UntypedFormGroup; // Form initial
  EditForm: UntypedFormGroup;

  MerchantsGets() {
    this.reportsDataService
      .getMerchantList()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.MerchantList = data.ReturnObject;
            this.translate.get("REPORT.ALL_MERCHANTS_LBL").subscribe((text) => {
              this.MerchantList.unshift({
                id: "null",
                Name: text,
              });
            });
            this.filteredOptions =
              this.FilterForm.controls.MerchantId.valueChanges.pipe(
                startWith(""),
                map((value) =>
                  typeof value === "string" ? value : value.Name
                ),
                map((name) =>
                  name
                    ? this._filter(name.toString())
                    : this.MerchantList.slice()
                )
              );
            this.FilterForm.patchValue({
              ["MerchantId"]: this.MerchantList[0].id,
            });
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
          this.getFirstDatas(this.radioModel);
        }
      );
  }

  makePDF() {
    if (this.loading === false && this.isDownloadingFile === false) {
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );
      this.isDownloadingFile = true;
      this.reportsDataService
        .getWithdrawPDF(
          this.FilterForm.value.MerchantId.id,
          start,
          finish,
          this.radioModel,
          "pdf"
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(
              res,
              "WithdrawReport_" +
                this.datePipe.transform(
                  this.FilterForm.controls.dateTo.value,
                  "yyyy-MM-dd"
                ) +
                "." +
                "pdf",
              {
                type: "application/pdf",
              }
            );
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  openInfo(templateRef, id) {
    this.EditForm.reset();
    this.loading = true;
    this.disableButtonSave = true;
    this.isLoadingDetailInfo = true;
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_transaction",
    });
    this.EditForm.markAsUntouched();
    this.reportsDataService
      .getWithdrawInfo(id)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.modalDatas = data.ReturnObject;
            this.EditForm.patchValue({
              ["Description"]: data.ReturnObject.Note,
            });
            if (data.ReturnObject.Status === 1) {
              this.disableButtonSave = false;
              this.EditForm.patchValue({ ["RRN"]: "" });
              this.EditForm.enable();
            } else {
              this.EditForm.disable();
              this.disableButtonSave = true;
              this.EditForm.patchValue({ ["RRN"]: data.ReturnObject.RRN });
            }
          }
          this.isLoadingDetailInfo = false;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.isLoadingDetailInfo = false;
          console.log(error);
        }
      );
  }

  saveInfo(state) {
    this.EditForm.markAsTouched();
    this.saveWithdrawError = "";
    if (this.EditForm.valid === true) {
      console.log(this.EditForm.value);
      this.disableButtonSave = true;
      this.loading = true;
      this.reportsDataService
        .saveWithdrawInfo(
          this.modalDatas.ID,
          this.EditForm.value.RRN,
          this.EditForm.value.Description,
          state
        )
        .pipe(first())
        .subscribe(
          (data) => {
            console.log(data);
            if (data.ResultCode === 0) {
              this.CloseDialog();
              this.getFirstDatas(this.radioModel);
            } else {
              data.ResultMessage === null
                ? (this.saveWithdrawError = "")
                : (this.saveWithdrawError = data.ResultMessage);
            }
            this.disableButtonSave = false;
            this.loading = false;
          },
          (error) => {
            this.disableButtonSave = false;
            this.loading = false;
            console.log(error);
          }
        );
    }
  }

  getFirstDatas(period) {
    this.loading = true;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    this.radioModel = period;
    this.reportsDataService
      .getWithdrawDatas(
        this.FilterForm.value.MerchantId.id,
        start,
        finish,
        period,
        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          console.log(this.dataSource.data);
          let datas = [];
          this.paginationItems = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.List;
              this.paginationItems = data.ReturnObject.Fetch.Cnt;
            }
          }
          this.dataSource = new MatTableDataSource(datas); // array of datas
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }

  ngOnInit() {
    // console.log(this.permissions);

    let ls = new SecureLS(); // for Secure Local storage
    if (ls.get("info") !== null) {
      this.Info = ls.get("info");
    }
    this.permissions =
      ls.get("permissions")[this.route.data["value"].pathCheck];

    this.paginationItemPerPage = 10;
    this.translate.get("REPORT.STATUS_NEW").subscribe((text) => {
      this.statusLists[0].text = text;
    }); // Text in component
    this.translate.get("REPORT.STATUS_INITIALIZED").subscribe((text) => {
      this.statusLists[1].text = text;
    }); // Text in component
    this.translate.get("REPORT.STATUS_INPROGRESS").subscribe((text) => {
      this.statusLists[2].text = text;
    }); // Text in component
    this.translate.get("REPORT.STATUS_SUCCESSFUL").subscribe((text) => {
      this.statusLists[3].text = text;
    }); // Text in component
    this.translate.get("REPORT.WD_STATUS_REJECT").subscribe((text) => {
      this.statusLists[4].text = text;
    }); // Text in component
    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      MerchantId: new UntypedFormControl(""),
    }); // for header Filter
    this.EditForm = new UntypedFormGroup({
      Description: new UntypedFormControl("", Validators.required),
      RRN: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(/^([0-9]*)$/),
      ]),
    }); // for header Filter
    this.MerchantsGets();
  }
}
