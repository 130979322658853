import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
@Injectable()
export class DashboardService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}
  // WidgetData
  getTopWidgetData() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Dashboard/Widget`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // Transactions Datas
  getTransactionData() {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Dashboard/DashboardTransactions`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getMainChartDatas(period) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/DashboardMainByType?type=` +
          period
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getFile(date_from, date_to, currentPage, item_per_page, format) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/report/TransactionListToPDF?DateFrom=` +
          date_from +
          `&DateTo=` +
          date_to +
          "&Account&UserName&DealerId&CompanyId&Status&RefStan&SalePointId&OpType&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page,
        {
          responseType: "blob",
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/report/TransactionListToExcel?DateFrom=` +
        date_from +
        `&DateTo=` +
        date_to +
        "&Account&UserName&DealerId&CompanyId&Status&RefStan&SalePointId&OpType&CurPage=" +
        currentPage +
        "&ItemPerPage=" +
        item_per_page,
      {
        responseType: "blob",
      }
    );
  }
  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "en":
        return "en-US";
      case "ru":
        return "ru-RU";
    }
    return "tr-TR";
  }
}
