import { DatePipe } from '@angular/common'

export enum DateRangeTypesEnum {
	// CUSTOM = 'HOME.PERIOD',
	LASTDAY = 'HOME.DAY',
	LAST7 = 'HOME.WEEK',
	LAST30 = 'HOME.MONTH',
}
export const DefaultDateRangeTypes = [
	// DateRangeTypesEnum.CUSTOM,
	DateRangeTypesEnum.LASTDAY,
	DateRangeTypesEnum.LAST7,
	DateRangeTypesEnum.LAST30,
]

export interface IRange {
	DateFrom: Date
	DateTo: Date
	RadioModel: number
}

export interface IDateRange {
	Type: string
	RangeName: string
	Range?: IRange
	State: boolean
}

export class DateRange implements IDateRange {
	Type: DateRangeTypesEnum
	RangeName: string
	Range?: IRange
	State: boolean

	constructor(DateRangeType: DateRangeTypesEnum) {
		this.Type = DateRangeType
		this.RangeName = this._rangeName
		this.Range = this._range
	}

	public get _rangeName() {
		return `${this.Type}`
	}

	public get _range() {
		let range: IRange
		const starttoday = new Date(new Date().setHours(0, 0, 0, 0))
		switch (this.Type) {
			// case DateRangeTypesEnum.CUSTOM:
			// 	range = {
			// 		DateFrom: new Date(starttoday),
			// 		DateTo: new Date(),
			// 		RadioModel: 0,
			// 	}
			// 	break
			case DateRangeTypesEnum.LASTDAY:
				range = {
					DateFrom: new Date(
						starttoday.setDate(new Date().getDate() - 1)
					),
					DateTo: new Date(),
					RadioModel: 1,
				}
				break
			case DateRangeTypesEnum.LAST7:
				range = {
					DateFrom: new Date(
						starttoday.setDate(new Date().getDate() - 7)
					),
					DateTo: new Date(),
					RadioModel: 2,
				}
				break
			case DateRangeTypesEnum.LAST30:
				range = {
					DateFrom: new Date(
						starttoday.setDate(new Date().getDate() - 30)
					),
					DateTo: new Date(),
					RadioModel: 3,
				}
				break
		}

		range.DateFrom.setHours(0, 0, 0, 0)
		range.DateTo.setHours(23, 59, 59, 999)
		return range
	}

	static GetList(): DateRange[] {
		return [
			// new DateRange(DateRangeTypesEnum.CUSTOM),
			new DateRange(DateRangeTypesEnum.LASTDAY),
			new DateRange(DateRangeTypesEnum.LAST7),
			new DateRange(DateRangeTypesEnum.LAST30),
		]
	}
	static GetRangeType(Type: DateRangeTypesEnum): DateRange {
		return new DateRange(Type)
	}
}
export enum DisplayedColumnsEnum {
	ID = 'Id',
	CUSTOMER_CODE = 'ApplicationType',
	DATE_REG = 'DateReg',
	DATE_UPDATE = 'DateUpdate',
	PLATFORM_TYPE = 'AppType',
	NOTIFICATION_TYPE = 'NotificationType',
	USER = 'User',
	HEADER = 'Title',
	TEXT = 'Body',
	STATUS = 'State',
	// SENDOUT_STATUS = 'ResStatus',
	SENDOUT_MESSAGE = 'ResMessage',
}

export enum CustomerTypeEnum {
	ALL = 'TOOLS.CUSTOMER_ALL',
	USER_LOGIN = 'TOOLS.CUSTOMORE_1',
	PHONE_NUMBER = 'TOOLS.CUSTOMORE_2',
	ASSIGNED = 'TOOLS.CUSTOMORE_3',
}

export enum NotificationTypeEnum {
	ALL = 'TOOLS.NOTIFICATION_TYPE_ALL',
	FREE_TEXT = 'TOOLS.NOTIFICATION_TYPE_1',
	TRANSACTION = 'TOOLS.NOTIFICATION_TYPE_2',
	OTP = 'TOOLS.NOTIFICATION_TYPE_3',
	TRANSACTION_CODE = 'TOOLS.NOTIFICATION_TYPE_4',
}

export enum AppTypeEnum {
	ALL = 'TOOLS.CATEGORY_0_ALL',
	MERCHANT = 'TOOLS.CATEGORY_1',
	END_USER = 'TOOLS.CATEGORY_2',
}
export enum StatusEnum {
	ALL = 'TOOLS.NA_STATUS_ALL',
	STATUS_1 = 'TOOLS.STATUS_1',
	STATUS_2 = 'TOOLS.STATUS_2',
	STATUS_3 = 'TOOLS.STATUS_3',
}

export const displayedColumns: IDisplayedColumns[] = [
	DisplayedColumnsEnum.ID.toString(),

	DisplayedColumnsEnum.CUSTOMER_CODE,

	DisplayedColumnsEnum.DATE_REG,

	DisplayedColumnsEnum.DATE_UPDATE,

	DisplayedColumnsEnum.PLATFORM_TYPE,
	DisplayedColumnsEnum.NOTIFICATION_TYPE,
	DisplayedColumnsEnum.USER,
	DisplayedColumnsEnum.HEADER,
	DisplayedColumnsEnum.TEXT,
	DisplayedColumnsEnum.STATUS,
	// DisplayedColumnsEnum.SENDOUT_STATUS,
	DisplayedColumnsEnum.SENDOUT_MESSAGE,
]

export interface IDisplayedColumns {}

export const status: IStatus[] = [
	{
		text: StatusEnum.ALL,
		value: '',
		color: '',
	},
	{
		text: StatusEnum.STATUS_1,
		value: 1,
		color: 'badge-primary',
	},
	{
		text: StatusEnum.STATUS_2,
		value: 2,
		color: 'badge-danger',
	},
	{
		text: StatusEnum.STATUS_3,
		value: 3,
		color: 'badge-success',
	},
]

export interface IStatus {
	text: StatusEnum
	value: number | string
	color: string
}

export const appType: IAppType[] = [
	{
		text: AppTypeEnum.ALL,
		value: '',
	},
	{
		text: AppTypeEnum.MERCHANT,
		value: 1,
	},
	{
		text: AppTypeEnum.END_USER,
		value: 2,
	},
]

export interface IAppType {
	text: AppTypeEnum
	value: number | string
}

export const customerType: ICustomerType[] = [
	{
		text: CustomerTypeEnum.ALL,
		value: '',
	},
	{
		text: CustomerTypeEnum.USER_LOGIN,
		value: 1,
	},
	{
		text: CustomerTypeEnum.PHONE_NUMBER,
		value: 2,
	},
	{
		text: CustomerTypeEnum.ASSIGNED,
		value: 3,
	},
]

export interface ICustomerType {
	text: CustomerTypeEnum
	value: number | string
}

export const notificationType: INotificationType[] = [
	{
		text: NotificationTypeEnum.ALL,
		value: '',
	},
	{
		text: NotificationTypeEnum.FREE_TEXT,
		value: 1,
	},
	{
		text: NotificationTypeEnum.TRANSACTION,
		value: 2,
	},
	{
		text: NotificationTypeEnum.OTP,
		value: 3,
	},
	{
		text: NotificationTypeEnum.TRANSACTION_CODE,
		value: 4,
	},
]

export interface INotificationType {
	text: NotificationTypeEnum
	value: number | string
}

interface IDateOUT {
	DateFrom: string
	DateTo: string
}

export class DateOUT implements IDateOUT {
	DateFrom: string
	DateTo: string
	constructor(dataFrom: any, dataTo: any) {
		this.DateFrom = formatDateTime(dataFrom)
		this.DateTo = formatDateTime(dataTo)
	}
}

interface IApiNotificationFilter {
	Date: DateOUT
	AppType: number
	CustomerType: number
	NotificationType: number
	Status: number
	SearchHeader: string
	Header: string
	ItemsPerPage: number
	CurrentPage: number
	PeriodType: number
}
export class ApiNotificationFilter implements IApiNotificationFilter {
	Date: DateOUT
	AppType: number
	CustomerType: number
	NotificationType: number
	Status: number
	SearchHeader: string
	Header: string
	ItemsPerPage: number
	CurrentPage: number
	PeriodType: number
	constructor(data: any, itemsPage: number, currentPage: number) {
		this.Date = new DateOUT(data.dateFrom, data.dateTo)
		this.AppType = data.AppType
		this.CustomerType = data.CustomerType
		this.NotificationType = data.NotificationType
		this.SearchHeader = data.NotificationUser.trim()
		this.Header = data.Header.trim()
		this.Status = data.Status
		this.ItemsPerPage = itemsPage ?? 20
		this.CurrentPage = currentPage ?? 1
		this.PeriodType = data.PeriodType
	}
}

export interface IItems {
	AppType: number
	CustomerType: number
	User: number
	NotificationType: number
	Title: string
	Body: string
	State: number
	DateReg: string
	DateUpdate: string
	Id: string
	ResStatus: number
	ResMessage: string
	RefId: string
}

export class Items implements IItems {
	AppType: number
	CustomerType: number
	User: number
	NotificationType: number
	Title: string
	Body: string
	State: number
	DateReg: string
	DateUpdate: string
	Id: string
	ResStatus: number
	ResMessage: string
	RefId: string
	constructor(coreModel: any) {
		this.AppType = coreModel.ApplicationType
		this.CustomerType = coreModel.ToType
		this.User = coreModel.ToUser
		this.NotificationType = coreModel.NotificationType
		this.Title = coreModel.NotificationTitle
		this.Body = coreModel.NotificationBody
		this.State = coreModel.State
		this.DateReg = coreModel.DateRegistered
		this.DateUpdate = coreModel.DateUpdated
		this.Id = coreModel.Id
		this.ResStatus = coreModel.ResultStatus
		this.ResMessage = coreModel.ResultMessage
		this.RefId = coreModel.RefNumberPartner
	}
}

export interface IPagingInfo {
	CurrentPage: number
	ItemsPerPage: number
	TotalItems: number
	TotalPages: number
	TotalPagesInt: number
}
export class PagingInfo implements IPagingInfo {
	CurrentPage: number
	ItemsPerPage: number
	TotalItems: number
	TotalPages: number
	TotalPagesInt: number
	constructor(coreModel: any) {
		this.CurrentPage = coreModel?.CurrentPage
		this.ItemsPerPage = coreModel?.ItemsPerPage
		this.TotalItems = coreModel?.TotalItems
		this.TotalPages = coreModel?.TotalPages
		this.TotalPagesInt = coreModel?.TotalPagesInt
	}
}

export interface IApiNotificationList {
	Items: IItems[]
	PagingInfo: IPagingInfo
}

export class ApiNotificationList implements IApiNotificationList {
	Items: Items[] = []
	PagingInfo: PagingInfo
	constructor(coreModel: any) {
		if (!!coreModel) {
			coreModel.NotificationReportRegistries?.forEach((el) => {
				this.Items.push(new Items(el))
			})
			this.PagingInfo = new PagingInfo(coreModel.Paging)
		}
	}
}

function formatDateTime(date: string | null = ''): string {
	return new DatePipe('en').transform(date, 'yyyy-MM-ddTHH:mm:ss') || ''
}
