import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, first } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { Subject } from "rxjs";

@Injectable()
export class MerchantTemplateService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // get list of items
  getItems(
      user_name: string,
      name: string,
      item_per_page ,
      current_page 
      ) 
  {
    console.log("merchant-templates-gets", this.authService.basUrl);

    return this.http
      .get<any>(this.authService.basUrl + `/api/templates/gets?FetchPage=${current_page}&FetchCount=${item_per_page}&Name=${name}&UserName=${user_name}`, {
        headers: {
          "Accept-Language": this.GetLang()        
        },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }  

 getItem(id) 
 {
  console.log("merchant-templates-get", this.authService.basUrl);

  return this.http
    .get<any>(this.authService.basUrl + `/api/templates/get/${id}`, {
      headers: {
        "Accept-Language": this.GetLang()        
      },
    })
    .pipe(
      map((response) => {
        return response;
      })
    );
  }  

  saveSettings(id, name, description, work_style, commisList, instList) {

    console.log("merchant-templates-save", this.authService.basUrl);

    let body = {
      Name: name,
      Description: description,
      WorkStyle: work_style,
      CommissionSettings: commisList,
      InstallmentSettings: instList,
      Id: id == '0'? null: id
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/templates/save`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          return response
        })
      )
  }
//------------------------------------------- private functions 
  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
