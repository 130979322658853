import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http'

// used to create fake backend
import { fakeBackendProvider } from './_helpers'

import { AppComponent } from './app.component'
import { routing } from './app.routing'

import { AlertComponent } from './_directives'
import { AuthGuard } from './_guards'
import { JwtInterceptor, ErrorInterceptor } from './_helpers'
import {
	AlertService,
	AuthenticationService,
	UserService,
	PaymentService,
} from './_services'

import {
	LocationStrategy,
	HashLocationStrategy,
	DatePipe,
} from '@angular/common'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { environment } from './../environments/environment'

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
}

// Import containers

import { P404Component } from './views/error/404.component'
import { P500Component } from './views/error/500.component'
import { RegisterComponent } from './views/register/register.component'

import {
	AppAsideModule,
	AppBreadcrumbModule,
	AppHeaderModule,
	AppFooterModule,
	AppSidebarModule,
} from '@coreui/angular'

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { ChartsModule } from 'ng2-charts'
// Import 3rd party modules
import { InvoiceModule } from './views/invoice/invoice.module'

import { SettingsModule } from './views/settings/settings.module'
import { PaymentsModule } from './views/payments/payments.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ReportsModule } from './views/reports/reports.module'
import { ToastrModule } from 'ngx-toastr'
// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { from } from 'rxjs'
import { DefaultLayoutModule } from './containers/default-layout/default-layout.module'
import { DefaultLayoutComponent } from './containers'
import { ReportsDatasService } from './_services/reportsdata.service'
import { TransactionService } from './_services/transaction.service'

import { ProviderService } from './_services/provider.service'
import { CustomersService } from './_services/customers.service'
import { LoginModule } from './views/login/login.module'
import { LoginComponent } from './views/login/login.component'
import { AdministrationModule } from './views/administation/administration.module'
import { AdministrationService } from './_services/administration.service'
import { ProfileService } from './_services/profile.service'
import { MainService } from './_services/main.service'
import { DashboardService } from './_services/dashboard.service'
import { CustomPipeModule } from './_helpers/CustomPipes'
import { RoleGuard } from './_guards/role.guard'
import { PSPModule } from './views/psp/psp.module'
import { ToolsModule } from './views/tools/tools.module'
import { MessagingModule } from './views/messaging/messaging.module'
import { SalesModule } from './views/sales/sales.module'
import { TransactionsModule } from './views/transactions/transactions.module'
import { CustomersModule } from './views/customers/customers.module'
import { MerchantTemplateService } from './_services/merchant-templates.service'
import { ToolsService } from './_services/tools.service'

export function HttpLoaderFactory(httpClient: HttpClient) {
	//return new TranslateHttpLoader(httpClient);
	return new TranslateHttpLoader(
		httpClient,
		environment.apiUrl + '/home/lang/',
		'json'
	)
}
import { registerLocaleData } from '@angular/common'
import localeTr from '@angular/common/locales/tr'
import localeRu from '@angular/common/locales/ru'
import { OperationsLogComponent } from './views/customers/operations-log/operations-log.component'
import { CustomersReportComponent } from './views/customers/customers-report/customers-report.component'
import { NotificationService } from './_services/notification.service'
import { ApinotificationService } from './_services/api-notification.service'

registerLocaleData(localeTr)
registerLocaleData(localeRu)

@NgModule({
	imports: [
		BrowserModule,
		AppAsideModule,
		AppBreadcrumbModule.forRoot(),
		AppFooterModule,
		AppHeaderModule,
		AppSidebarModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		ChartsModule,
		ReactiveFormsModule,
		HttpClientModule,
		PaymentsModule,
		ReportsModule,
		SettingsModule,
		SalesModule,
		InvoiceModule,
		TransactionsModule,
		CustomersModule,
		routing,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		LoginModule,
		AdministrationModule,
		PSPModule,
		ToolsModule,
		MessagingModule,
		FormsModule,
		DatePipe,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		CustomPipeModule,
	],
	declarations: [
		AppComponent,
		AlertComponent,
		DefaultLayoutComponent,
		P404Component,
		P500Component,
		LoginComponent,
		RegisterComponent,
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		AuthGuard,
		RoleGuard,
		AlertService,
		AuthenticationService,
		ReportsDatasService,
		TransactionService,
		UserService,
		ProviderService,
		CustomersService,
		PaymentService,
		AdministrationService,
		ProfileService,
		MainService,
		DashboardService,
		MerchantTemplateService,
		ToolsService,
		NotificationService,
		ApinotificationService,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

		// provider used to create fake backend
		fakeBackendProvider,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
