import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms"
import { Observable } from "rxjs"
import { map, startWith, first } from "rxjs/operators"
import { AlertService, UserService, ProviderService } from "../../_services"
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table"
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator"
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog"
import { DataSource } from "@angular/cdk/table"
import { ReportsDatasService } from "../../_services/reportsdata.service"
import { DatePipe } from "@angular/common"
import { saveAs } from "file-saver"
import { ToastrService } from "ngx-toastr"

/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls"
import { TranslateService } from "@ngx-translate/core"

@Component({
  templateUrl: "roles-report.component.html",
  styleUrls: ["../reports/reports.scss"],
  providers: [DatePipe],
})
export class RolesReportComponent implements OnInit {
  public paginationItems // for display pagination from api
  public paginationItemPerPage // for display pagination from api
  saveRollesError = ""
  modalDatas = {
    Name: "",
    CodeNum: 0,
    Description: "",
    Key: "",
    Code: "",
    RightsGroups: [],
  }
  permissions
  new_role_flag: boolean = true
  public currentPage = 0
  isLoadingDetailInfo: boolean = true // spinner
  isDownloadingFile: boolean = false // spinner
  Info
  loading = false // for disable fast response click
  pageEvent // for fix error of pageEvent in Visual Studio
  panelOpenState = true
  // For change pages
  minDate = new Date(2000, 0, 1) // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
  events: string[] = [] // Date picker
  currentTime = new Date()
  allPageLoad: boolean = false

  orderByList = [
    // {
    //     text: 'Default',
    //     value: 0
    // },
    {
      text: "Code",
      value: 1,
    },
    {
      text: "CodeNum",
      value: 2,
    },
    {
      text: "NameDefault",
      value: 3,
    },
  ]

  displayedColumns: string[] = ["CodeNumber", "Code", "Name", "Description"]

  dataSource = new MatTableDataSource() // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private reportsDataService: ReportsDatasService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    public toastr: ToastrService
  ) {}

  ObjectSize(obj) {
    var size = 0,
      key
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++
    }
    return size
  }

  // Get the size of an object

  FilterForm: UntypedFormGroup // Form initial
  EditForm: UntypedFormGroup

  CloseDialog(): void {
    this.dialog.closeAll()
  }
  openInfo(templateRef, id) {
    this.EditForm.reset()
    this.saveRollesError = ""
    this.loading = true
    this.isLoadingDetailInfo = true
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_transaction",
    })
    if (this.ObjectSize(this.EditForm.controls) > 0) {
      for (let control in this.EditForm.controls) {
        this.EditForm.removeControl(control)
      }
    }
    if (id !== null) {
      this.new_role_flag = false
      this.reportsDataService
        .getRoleInfo(id)
        .pipe(first())
        .subscribe(
          (data) => {
            console.log(data)
            if (data.ResultCode === 0) {
              this.modalDatas = data.ReturnObject
              this.EditForm.addControl(
                "Name",
                new UntypedFormControl(this.modalDatas.Name, Validators.required)
              )
              this.EditForm.addControl(
                "Code",
                new UntypedFormControl(this.modalDatas.Code, Validators.required)
              )
              this.EditForm.addControl(
                "Description",
                new UntypedFormControl(this.modalDatas.Description)
              )
              this.EditForm.addControl(
                "CodeNum",
                new UntypedFormControl({
                  value: this.modalDatas.CodeNum,
                  disabled: true,
                })
              )
              this.EditForm.addControl(
                "Key",
                new UntypedFormControl({ value: this.modalDatas.Key, disabled: true })
              )
              this.modalDatas.RightsGroups.forEach((group) => {
                let counter = 0
                group.Rights.forEach((right) => {
                  right.Id = counter
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_RoleKey_" + right.Id,
                    new UntypedFormControl(right.RoleKey)
                  )
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_RightKey_" + right.Id,
                    new UntypedFormControl(right.RightKey)
                  )
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_RightName_" + right.Id,
                    new UntypedFormControl(right.RightName)
                  )
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_IsEnable_" + right.Id,
                    new UntypedFormControl(right.IsEnable)
                  )
                  counter++
                })
              })
            }
            console.log(this.EditForm)
            this.isLoadingDetailInfo = false
            this.loading = false
          },
          (error) => {
            this.loading = false
            this.isLoadingDetailInfo = false
            console.log(error)
          }
        )
    } else {
      this.new_role_flag = true
      this.reportsDataService
        .getRights()
        .pipe(first())
        .subscribe(
          (data) => {
            console.log(data)
            if (data.ResultCode === 0) {
              this.modalDatas = data.ReturnObject
              this.EditForm.addControl(
                "Name",
                new UntypedFormControl(this.modalDatas.Name, Validators.required)
              )
              this.EditForm.addControl(
                "Code",
                new UntypedFormControl(this.modalDatas.Code, Validators.required)
              )
              this.EditForm.addControl(
                "Description",
                new UntypedFormControl(
                  this.modalDatas.Description,
                  Validators.required
                )
              )
              this.EditForm.addControl(
                "CodeNum",
                new UntypedFormControl(this.modalDatas.CodeNum, Validators.required)
              )
              this.EditForm.addControl(
                "Key",
                new UntypedFormControl({ value: this.modalDatas.Key, disabled: true })
              )
              this.modalDatas.RightsGroups.forEach((group) => {
                let counter = 0
                group.Rights.forEach((right) => {
                  right.Id = counter
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_RoleKey_" + right.Id,
                    new UntypedFormControl(right.RoleKey)
                  )
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_RightKey_" + right.Id,
                    new UntypedFormControl(right.RightKey)
                  )
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_RightName_" + right.Id,
                    new UntypedFormControl(right.RightName)
                  )
                  this.EditForm.addControl(
                    "GroupId_" + group.GroupId + "_IsEnable_" + right.Id,
                    new UntypedFormControl(right.IsEnable)
                  )
                  counter++
                })
              })
            }
            console.log(this.EditForm)
            this.isLoadingDetailInfo = false
            this.loading = false
          },
          (error) => {
            this.loading = false
            this.isLoadingDetailInfo = false
            console.log(error)
          }
        )
    }
  }
  saveInfo() {
    if (this.permissions.write) {
      this.EditForm.markAsTouched()
      this.saveRollesError = ""

      let final_datas = []
      if (this.EditForm.valid) {
        if (this.ObjectSize(this.EditForm.controls) > 0) {
          for (let control in this.EditForm.controls) {
            if (this.EditForm.value[control] === true) {
              let target = control.split("_")
              let isEnable = this.EditForm.value[control]
              let rightKey =
                this.EditForm.value[
                  "GroupId_" + target[1] + "_RightKey_" + target[3]
                ]
              let rightName =
                this.EditForm.value[
                  "GroupId_" + target[1] + "_RightName_" + target[3]
                ]
              let roleKey =
                this.EditForm.value[
                  "GroupId_" + target[1] + "_RoleKey_" + target[3]
                ]
              // this.EditForm.value.
              final_datas.push({
                IsEnable: isEnable,
                RoleKey: roleKey,
                RightKey: rightKey,
                RightName: rightName,
              })
            }
          }
          console.log(final_datas)
          console.log(this.EditForm.value)
          this.loading = true
          this.reportsDataService
            .saveRoles(
              this.modalDatas,
              this.EditForm.value,
              final_datas,
              this.new_role_flag
            )
            .pipe(first())
            .subscribe(
              (data) => {
                console.log(data)
                if (data.ResultCode === 0) {
                  this.CloseDialog()
                  this.getFirstDatas()
                  let body = ""
                  let theme = ""
                  this.translate
                    .get("REPORT.BODY_ROLES_SUCCESS")
                    .subscribe((text) => {
                      body = text
                    }) // Text in component
                  this.translate
                    .get("REPORT.THEME_ROLES_SUCCESS")
                    .subscribe((text) => {
                      theme = text
                    }) // Text in component
                  this.toastr.success(body, theme)
                } else {
                  let theme = ""
                  this.translate
                    .get("REPORT.THEME_ROLES_ERROR")
                    .subscribe((text) => {
                      theme = text
                    }) // Text in component
                  data.ResultMessage === null
                    ? (this.saveRollesError = "")
                    : (this.saveRollesError = data.ResultMessage)
                  this.toastr.error(this.saveRollesError, theme)
                }
                this.loading = false
              },
              (error) => {
                let theme = ""
                this.translate
                  .get("REPORT.THEME_ROLES_ERROR")
                  .subscribe((text) => {
                    theme = text
                  }) // Text in component
                this.toastr.error(error, theme)
                this.loading = false
                console.log(error)
              }
            )
        }
      }
    } else {
      alert("Sorry you have no right to do this")
    }
  }

  getFirstDatas() {
    this.loading = true
    this.reportsDataService
      .getRoles(
        this.FilterForm.value.Code,
        this.FilterForm.value.OrderBy,
        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data)
          let datas = []
          this.paginationItems = 0
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Roles
              this.paginationItems = data.ReturnObject.Paging.TotalItems
            }
          }
          console.log(datas)
          this.dataSource = new MatTableDataSource(datas) // array of datas
          this.loading = false
        },
        (error) => {
          this.loading = false
          console.log(error)
        },
        () => {
          this.allPageLoad = true
        }
      )
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0
  }
  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex
    this.paginationItemPerPage = e.pageSize
    this.getFirstDatas()
  }

  ngOnInit() {
    let ls = new SecureLS() // for Secure Local storage
    this.permissions = ls.get("permissions")[this.route.data["value"].pathCheck]
    if (ls.get("info") !== null) {
      this.Info = ls.get("info")
    }
    // console.log(this.permissions);
    // this.translate.get('REPORT.ORDER_DEFAULT').subscribe((text) => { this.orderByList[0].text = text; }); // Text in component
    this.translate.get("REPORT.ORDER_CODE").subscribe((text) => {
      this.orderByList[0].text = text
    }) // Text in component
    this.translate.get("REPORT.ORDER_CODENUM").subscribe((text) => {
      this.orderByList[1].text = text
    }) // Text in component
    this.translate.get("REPORT.ORDER_NAMEDEFAULT").subscribe((text) => {
      this.orderByList[2].text = text
    }) // Text in component

    this.paginationItemPerPage = 10
    this.FilterForm = new UntypedFormGroup({
      Code: new UntypedFormControl(""),
      OrderBy: new UntypedFormControl(this.orderByList[0].value, Validators.required),
    }) // for header Filter
    this.EditForm = new UntypedFormGroup({}) // for header Filter
    this.getFirstDatas()
  }
}
