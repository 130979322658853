import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../_services'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs'
import { MatStepper } from '@angular/material/stepper'

import { DataSource } from '@angular/cdk/table'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { AdministrationService } from '../../_services/administration.service'
import { MerchantTemplateService } from '../../_services/merchant-templates.service'
import { DatePipe } from '@angular/common'
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { Globals } from '../../globals/globals'
import { LocalizedDatePipe } from '../../_helpers/custom.datepipe'
import { environment } from '../../../environments/environment'
import { type } from 'os'
import { Console } from 'console'

export interface ItemList {
	Value: string
	Name: string
}

export interface CityList {
	Value: string
	Name: string
}

export interface SaleList {
	Value: string
	Name: string
}

// For custom Error Validations Messages
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: UntypedFormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const invalidCtrl = !!(
			control &&
			control.invalid &&
			control.parent.dirty
		)
		const invalidParent = !!(
			control &&
			control.parent &&
			control.parent.invalid &&
			control.parent.dirty
		)

		return invalidCtrl || invalidParent
	}
}

@Component({
	templateUrl: 'merchants.component.html',
	styleUrls: ['./administration.scss'],
	providers: [DatePipe, Globals, LocalizedDatePipe],
})
export class MerchantsComponent implements OnInit {
	matcher = new MyErrorStateMatcher() // for my Error state | Colors and other
	permissions
	image_error = ''
	file_error = ''
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	fileName = ''
	activeTab = 0
	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner
	templateList = []
	//

	//

	//-------------------------------------
	providersList
	Transactions_Datas
	saleListOptions: SaleList[]
	citiesListOptions: CityList[]
	dealerListOptions: ItemList[] // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	// For change pages

	services: ItemList[]
	uploadNow = false // state when photo is uploading
	uploadNowFile = false // state when photo is uploading
	account = '' // Account input
	refStan = '' // refStan input
	serviceSelected = '' // Service selected
	userLogin = '' // User Login input
	changePasswordError = ''
	editTableUserError = ''
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	createMerchantError = '' // Validation on User
	MerchantStatusBeforeChanged = 0
	isCommissionSettingsExpanded: boolean = false
	isBranchSettingsExpanded: boolean = true
	isBankSettingsExpanded: boolean = true
	dealerFilteredOptions: Observable<ItemList[]> // dealer
	citiesFilteredOptions: Observable<CityList[]> // cities
	saleFilteredOptions: Observable<SaleList[]> // salePoints
	currentTime = new Date()
	baseUrl: string = environment.apiUrl
	//----------------- list of columns
	displayedColumns: string[] = [
		'Id',
		'Name',
		'Code',
		'MerchantBankCode',
		'MerchantBankID',
		'Description',
		'RegDate',
		'Status',
		'Operations',
	]
	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		private merchantTemplateService: MerchantTemplateService,
		private formBuilder: UntypedFormBuilder,
		public toastr: ToastrService,
		public translate: TranslateService,
		public route: ActivatedRoute,
		private globals: Globals,
		private localizedDate: LocalizedDatePipe
	) {
		// Assign the data to the data source for the table to render
		// this.dataSource = new MatTableDataSource(users);
	}

	step1: boolean = true
	step2: boolean = false
	modalTableData = {
		Login: '',
		UserEMail: '',
		UserMobile: '',
		UserName: '',
		UserTitle: '',
		UserStatus: '',
		UserId: '',
		Id: '',
		Language: '',
		RoleList: [],
	}
	modalDatas = {
		Status: 0,
		Id: '0',
		Key: '',
		Code: '',
		DailyTurnover: '0.0',
		Commissions: [],
		CommissionsSettings: [],
		Increment: new Date().getMilliseconds(),

	} // for display datas in modal
	jobsList = []
	bankList = []
	bankBranchList = []
	modalList = []
	finally_array = []
	editMerchantError = ''
	FilterForm: UntypedFormGroup // Form initial
	CreateMerchant: UntypedFormGroup
	TableUser: UntypedFormGroup
	EditForm: UntypedFormGroup // Form initial
	CommissionForm: UntypedFormGroup // Form initial
	selectedBankCode?= null
	selectedBank?= null
	selectedBranch = null
	statusList = [
		{ Value: 0, Name: 'Disable' },
		{ Value: 1, Name: 'Enable' },
	]
	commissionTypeList = [
		{ Value: 0, Name: 'No' },
		{ Value: 1, Name: '%' },
		{ Value: 2, Name: 'Fixed' },
	]




	previewImage(event) {
		let file = event.target.files[0]
		if (event.target.files.length) {
			this.image_error = ''
			this.uploadNow = true
			if (file.type !== 'image/png' && file.type != 'image/jpeg') {
				this.translate
					.get('PROFILE.IMG_FORMAT_ERROR')
					.subscribe((text) => {
						this.image_error = text
					}) // Text in component
				this.uploadNow = false
			} else if (file.size > 524288) {
				this.translate
					.get('PROFILE.IMG_SIZE_ERROR')
					.subscribe((text) => {
						this.image_error = text
					}) // Text in component
				this.uploadNow = false
			} else {
				let type = file.type
				let reader: any
				target: EventTarget
				reader = new FileReader()

				reader.readAsDataURL(event.target.files[0]) // read file as data url
				reader.onload = (event) => {
					// called once readAsDataURL is completed
					let code = event.target.result.split(',')[1]
					this.administrationService
						.saveImageMerchant(this.modalDatas.Key, type, code)
						.subscribe(
							(data) => {
								if (data.ResultCode === 0) {
									let body = ''
									let theme = ''
									this.translate
										.get('MERCHANT.BODY_LOGO_SUCCESS')
										.subscribe((text) => {
											body = text
										}) // Text in component
									this.translate
										.get('MERCHANT.THEME_LOGO_SUCCESS')
										.subscribe((text) => {
											theme = text
										}) // Text in component
									this.toastr.success(body, theme)
								}
							},
							(error) => {
								console.log(error)
								this.uploadNow = false
							},
							() => {
								this.uploadNow = false
									; (<HTMLImageElement>(
										document.getElementById('preview_merchant')
									)).src = 'data:' + type + ';base64,' + code
							}
						)
				}
			}
		}
	}

	previewDocument(event) {
		let file = event.target.files[0]
		if (event.target.files.length) {
			this.file_error = ''
			this.uploadNowFile = true
			if (file.size > 5242880) {
				this.translate
					.get('PROFILE.FILE_SIZE_ERROR')
					.subscribe((text) => {
						this.file_error = text
					}) // Text in component
				this.uploadNowFile = false
			} else {
				// let type = file.type;
				let type = file.type
				let name = file.name
				!type.length
					? (type = file.name.split('.').pop())
					: (type = file.type)
				let reader: any
				target: EventTarget
				reader = new FileReader()

				reader.readAsDataURL(event.target.files[0]) // read file as data url
				reader.onload = (event) => {
					// called once readAsDataURL is completed
					let code = event.target.result.split(',')[1]
					this.administrationService
						.saveFileMerchant(this.modalDatas.Key, name, type, code)
						.subscribe(
							(data) => {
								if (data.ResultCode === 0) {
									let body = ''
									let theme = ''
									this.translate
										.get('MERCHANT.BODY_CONTRACT_SUCCESS')
										.subscribe((text) => {
											body = text
										}) // Text in component
									this.translate
										.get('MERCHANT.THEME_CONTRACT_SUCCESS')
										.subscribe((text) => {
											theme = text
										}) // Text in component
									this.toastr.success(body, theme)
								}
							},
							(error) => {
								console.log(error)
								this.uploadNowFile = false
							},
							() => {
								this.uploadNowFile = false
							}
						)
				}
			}
		}
	}

	checkPasswords(group: UntypedFormGroup) {
		// here we have the 'passwords' group and check repeat password | Custom validation
		let password = group.controls.Password.value
		let repeatpassword = group.controls.RepeatPassword.value

		return password === repeatpassword ? null : { notSame: true }
	}

	// rolesList = [{ value: 0, viewValue: 'Admin' }, { value: 1, viewValue: 'User' }];
	rolesList = []

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas()
	}

	getFirstDatas() {
		this.loading = true
		let filter = this.FilterForm.value
		let currentPage = this.currentPage + 1
		this.dataSource = new MatTableDataSource([])
		this.administrationService
			.getMerchantList(
				filter.Code,
				filter.Name,
				filter.CardplusId,
				currentPage,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							// this.Transactions_Datas = data.ReturnObject.Users;
							this.dataSource = new MatTableDataSource(
								data.ReturnObject.List
							) // array of datas

							//this.dataSource.sort = this.sort; // activate sort after insert datas
							// this.dataSource.paginator = this.paginator; // activate paginator after insert datas
							this.paginationItems = data.ReturnObject.fetch.Cnt
						} else {
							this.paginationItems = 0
							this.Transactions_Datas = []
							this.dataSource = new MatTableDataSource(
								this.Transactions_Datas
							) // array of datas
						}
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)

	}


	onSubmit() {
		this.getFirstDatas()
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	editMerchant() {
		this.markFormGroupTouched(this.EditForm)
		this.editMerchantError = ''

	console.log( this.EditForm.controls['BankDetails_BranchName'])
		if (this.EditForm.valid === true) {
			// Make again gets all users()
			this.loading = true
			let sended_datas = []
			this.finally_array.forEach((final) => {
				if (final.Changed == 1 && final.Level[0] !== 'Commission') {
					sended_datas.push(final)
					final.Value = this.EditForm.value[final.Key]
				}
				if (final.Changed == 0 && final.Key === 'Status') {
					console.log('status', final)
					sended_datas.push(final)
					final.Value = this.MerchantStatusBeforeChanged
				}
				if (final.Changed == 1 && final.Key == 'BankDetails_Branch') {

					sended_datas.push({
						Key: 'BankDetails_BranchCode',
						Value: this.EditForm.controls['BankDetails_BranchCode'].value,
						Level: 'BankDetails_BranchCode'.split('_'),
						Changed: 0,
					})
					sended_datas.push({
						Key: 'BankDetails_BranchName',
						Value: this.EditForm.controls['BankDetails_BranchName'].value,
						Level: 'BankDetails_BranchName'.split('_'),
						Changed: 0,
					})

				}

				if (final.Changed == 1 && final.Key == 'BankDetails_Bank') {
					sended_datas.push({
						Key: 'BankDetails_Address',
						Value: this.EditForm.controls['BankDetails_Address'].value,
						Level: 'BankDetails_Address'.split('_'),
						Changed: 0,
					})
					sended_datas.push({
						Key: 'PaymentSystem',
						Value: this.EditForm.controls['PaymentSystem'].value,
						Level: 'PaymentSystem'.split('_'),
						Changed: 0,
					})
					sended_datas.push({
						Key: 'MerchantBankCode',
						Value: this.EditForm.controls['MerchantBankCode'].value,
						Level: 'MerchantBankCode'.split('_'),
						Changed: 0,
					})
				}
				if (final.Changed === 1 && final.Key === 'BankDetails_DailyTurnover') {
					final.Value = Number(this.EditForm.value[final.Key]) * 100
				}

			})
			if (sended_datas.length) {
				this.administrationService
					.editMerchant(
						sended_datas,
						this.modalDatas.Key,
						this.modalDatas.Code
					)
					.subscribe(
						(data) => {
							if (data.ResultCode === 0) {
								this.CloseDialog()
								let body = ''
								let theme = ''
								this.translate
									.get('MERCHANT.BODY_EDITMERCHANT_SUCCESS')
									.subscribe((text) => {
										body = text
									}) // Text in component
								this.translate
									.get('MERCHANT.THEME_EDITMERCHANT_SUCCESS')
									.subscribe((text) => {
										theme = text
									}) // Text in component
								this.toastr.success(body, theme)
								this.loading = false
								this.getFirstDatas()
							} else {
								this.loading = false
								data.ResultMessage === null
									? (this.editMerchantError = '')
									: (this.editMerchantError =
										data.ResultMessage)
								let theme = ''
								this.translate
									.get('MERCHANT.THEME_EDITMERCHANT_ERROR')
									.subscribe((text) => {
										theme = text
									}) // Text in component
								this.toastr.error(this.editMerchantError, theme)
							}
						},
						(error) => {
							this.loading = false
							console.log(error)
						}
					)
			} else {
				this.loading = false
			}
		} else {
			this.translate.get('MERCHANT.WRONG_FIELDS').subscribe((text) => {
				this.editMerchantError = text
			}) // Text in component
		}
	}

	saveCommision() {
		this.editMerchantError = ''
		this.loading = true
		let sended_datas = this.modalDatas.Commissions
		this.finally_array.forEach((final) => {
			if (final.Changed === 1 && final.Level[0] === 'Commission') {
				final.Value = this.EditForm.value[final.Key]
				sended_datas.find(
					(x) => x.ProviderId === Number(final.Level[2])
				)[final.Level[1]] =
					typeof this.EditForm.value[final.Key] === 'string'
						? Number(this.EditForm.value[final.Key]) * 100
						: final.Level[1] === 'BlockedDays' ? Number(this.EditForm.value[final.Key]) * 100 : this.EditForm.value[final.Key]
			}
		})
		let commissionChangedIds = this.modalDatas.CommissionsSettings.filter(
			(x) => x.changed == true
		).map((x) => x.InstallmentId),
			commissionSettings: CommissionSettingsItem[] = []
		commissionChangedIds.forEach((element) => {
			commissionSettings.push({
				InstallmentId: element,
				Value: this.EditForm.value['Commission_Setting_' + element]
					? this.EditForm.value[
						'Commission_Setting_' + element
					].replace(/\D+/g, '')
					: '0',
				Type: 1,
			})
		})

		this.markFormGroupTouched(this.EditForm)
		if (sended_datas.length && this.EditForm.valid) {
			this.administrationService
				.editCommission(
					sended_datas,
					commissionSettings,
					this.modalDatas.Id,
					this.EditForm.value['WorkStyle']
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.loading = false
							let body = ''
							let theme = ''
							this.translate
								.get('MERCHANT.BODY_COMMISSION_SUCCESS')
								.subscribe((text) => {
									body = text
								}) // Text in component
							this.translate
								.get('MERCHANT.THEME_COMMISSION_SUCCESS')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.success(body, theme)
						} else {
							this.loading = false
							data.ResultMessage === null
								? (this.editMerchantError = '')
								: (this.editMerchantError = data.ResultMessage)
							let theme = ''
							this.translate
								.get('MERCHANT.THEME_COMMISSION_ERROR')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.error(this.editTableUserError, theme)
						}
					},
					(error) => {
						this.loading = false
						console.log(error)
					}
				)
		} else {
			this.loading = false
		}
	}

	States = [
		{ value: 0, viewValue: 'InActive' },
		{ value: 1, viewValue: 'Active' },
	]

	Languages = [{ Key: '', Name: '' }]

	// Types = [{ Key: "", Description: "" }];

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	openCreateMerchant(templateRef) {
		this.CreateMerchant.reset()
		this.bankBranchList = []
		this.finally_array = []
		this.selectedBankCode = null
		this.createMerchantError = ''
		if (this.loading === false) {
			this.loading = true
			this.openModal(templateRef)
			this.isLoadingDetailInfo = true
			this.modalDatas = {
				Status: 0,
				Id: '0',
				Key: '',
				Code: '',
				DailyTurnover: '0.0',
				Commissions: [],
				CommissionsSettings: [],
				Increment: new Date().getMilliseconds(),

			}
			this.administrationService.getJobTitles()
				.subscribe((data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.jobsList = data.ReturnObject


						}
					}
					this.loading = false
				}, (error) => { },
					() => {
						this.administrationService.getBanks().subscribe((data) => {
							if (data.ResultCode === 0) {
								if (data.ReturnObject !== null) {
									this.bankList = data.ReturnObject
								}
								this.editDatasPush(null,false)
							}

						})
					})
			this.editDatasPush(null,false)
			this.isLoadingDetailInfo = false
			this.loading = false
		}
	}


	openUsers(templateRef, row) {
		if (this.loading === false) {
			this.loading = true
			this.modalList = []
			this.openModal(templateRef)
			this.isLoadingDetailInfo = true
			this.step1 = true
			this.step2 = false
			this.administrationService.getMerchantUsers(row.Key).subscribe(
				(data) => {
					console.log(data)
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = row
							this.modalList = data.ReturnObject.Users
						}
						this.isLoadingDetailInfo = false
						this.loading = false
					} else {
						this.loading = false
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					this.loading = false
					console.log(error)
				}
			)
		}
	}

	openEditUser(user_id) {
		this.editTableUserError = ''
		this.markFormGroupUnTouched(this.TableUser)
		this.rolesList = []
		this.administrationService.getLanguages().subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					this.Languages = data.ReturnObject
					console.log(this.Languages)
				}
			},
			(error) => {
				console.log(error)
			},
			() => {
				if (user_id === null) {
					this.administrationService.getRolesMerchant().subscribe(
						(data) => {
							console.log(data)
							if (data.ResultCode === 0) {
								this.rolesList = data.ReturnObject
								this.modalTableData = {
									Login: null,
									UserEMail: null,
									UserMobile: null,
									UserName: null,
									UserStatus: null,
									UserId: null,
									Id: null,
									Language: null,
									UserTitle: null,
									RoleList: [],
								}
								if (this.rolesList.length) {
									let counter = 0
									this.rolesList.forEach((role) => {
										role['Id'] = counter
										this.TableUser.removeControl(
											'Key_' + role.Id
										)
										this.TableUser.removeControl(
											'Value_' + role.Id
										)
										this.TableUser.addControl(
											'Key_' + role.Id,
											new UntypedFormControl(role.Key)
										) // Initialize form rules
										this.TableUser.addControl(
											'Value_' + role.Id,
											new UntypedFormControl(role.IsOn)
										) // Initialize form rules
										counter++
									})
								}
								this.TableUser.patchValue({
									['UserEMail']:
										this.modalTableData.UserEMail,
								})
								this.TableUser.patchValue({
									['UserMobile']:
										this.modalTableData.UserMobile,
								})
								this.TableUser.patchValue({
									['UserName']: this.modalTableData.UserName,
								})
								this.TableUser.patchValue({
									['Status']: this.modalTableData.UserStatus,
								})
								this.TableUser.patchValue({
									['Language']: this.modalTableData.Language,
								})

								this.TableUser.patchValue({
									['UserTitle']:
										this.modalTableData.UserTitle,
								})
								this.step1 = false
								this.step2 = true
							}
						},
						(error) => {
							console.log(error)
						}
					)
				} else {
					this.administrationService.getUser(user_id).subscribe(
						(data) => {
							console.log(data)
							if (data.ResultCode === 0) {
								this.modalTableData = data.ReturnObject.Info
								this.rolesList = data.ReturnObject.RoleList
								if (this.rolesList.length) {
									let counter = 0
									this.rolesList.forEach((role) => {
										role['Id'] = counter
										this.TableUser.removeControl(
											'Key_' + role.Id
										)
										this.TableUser.removeControl(
											'Value_' + role.Id
										)
										this.TableUser.addControl(
											'Key_' + role.Id,
											new UntypedFormControl(role.Key)
										) // Initialize form rules
										this.TableUser.addControl(
											'Value_' + role.Id,
											new UntypedFormControl(role.IsOn)
										) // Initialize form rules
										counter++
									})
								}
								this.TableUser.patchValue({
									['UserEMail']:
										this.modalTableData.UserEMail,
								})
								this.TableUser.patchValue({
									['UserMobile']:
										this.modalTableData.UserMobile,
								})
								this.TableUser.patchValue({
									['UserName']: this.modalTableData.UserName,
								})
								this.TableUser.patchValue({
									['Status']: this.modalTableData.UserStatus,
								})
								this.TableUser.patchValue({
									['Language']: this.modalTableData.Language,
								})

								this.TableUser.patchValue({
									['UserTitle']:
										this.modalTableData.UserTitle,
								})
								this.step1 = false
								this.step2 = true
								console.log(this.modalTableData.Language)
								console.log(this.Languages)
							}
						},
						(error) => {
							console.log(error)
						}
					)
				}
			}
		)
	}
	resendCredentials(row) {
		if (this.loading === false) {
			this.loading = true
			this.administrationService.resendCredentials(row.Id, this.modalDatas.Key).subscribe(
				(data) => {
					console.log(data)
					if (data.ResultCode === 0) {
						let theme = ''
						let body = ''
						this.translate
							.get('MERCHANT.THEME_RESEND_CREDEN_SUCCESS')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.success(body, theme)

						this.loading = false
					} else {
						this.loading = false
						let theme = ''
						this.translate
							.get('MERCHANT.THEME_RESEND_CREDEN_ERROR')
							.subscribe((text) => {
								theme = text
							}) // Text in component
						this.toastr.error(this.editTableUserError, theme)
					}
				},
				(error) => {
					let theme = ''
					this.translate
						.get('MERCHANT.THEME_RESEND_CREDEN_ERROR')
						.subscribe((text) => {
							theme = text
						}) // Text in component
					this.toastr.error(this.editTableUserError, theme)

					this.loading = false
					console.log(error)
				}
			)
		}
	}
	saveUserEdit() {
		this.editTableUserError = ''
		this.markFormGroupTouched(this.TableUser)
		if (this.TableUser.valid === true) {
			this.loading = true
			this.administrationService
				.saveUserEdit(
					this.TableUser.value,
					this.modalDatas.Key,
					this.modalTableData.Id,
					this.rolesList.length
				)
				.subscribe(
					(data) => {
						console.log(data)
						if (data.ResultCode === 0) {
							let body = ''
							let theme = ''
							this.translate
								.get('MERCHANT.THEME_ADDUSERS_SUCCESS')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							if (this.modalTableData.Id === null) {
								this.translate
									.get('MERCHANT.BODY_ADDNEWUSERS_SUCCESS')
									.subscribe((text) => {
										body = text
									}) // Text in component
							} else {
								this.translate
									.get('MERCHANT.BODY_ADDUSERS_SUCCESS')
									.subscribe((text) => {
										body = text
									}) // Text in component]
							}
							this.toastr.success(body, theme)
							this.administrationService
								.getMerchantUsers(this.modalDatas.Key)
								.subscribe(
									(data) => {
										console.log(data)
										if (data.ResultCode === 0) {
											if (data.ReturnObject !== null) {
												this.step2 = false
												this.step1 = true
												this.modalList =
													data.ReturnObject.Users
											}
											this.isLoadingDetailInfo = false
											this.loading = false
										} else {
											this.loading = false
										}
									},
									(error) => {
										this.isLoadingDetailInfo = false
										this.loading = false
										console.log(error)
									}
								)
							// this.openEditUser(this.modalTableData.UserId);
						} else {
							data.ResultMessage === null
								? (this.editTableUserError = '')
								: (this.editTableUserError = data.ResultMessage)
							let theme = ''
							this.translate
								.get('MERCHANT.THEME_ADDUSERS_ERROR')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.error(this.editTableUserError, theme)
							this.loading = false
							this.isLoadingDetailInfo = false
						}
					},
					(error) => {
						this.isLoadingDetailInfo = false
						this.loading = false
						let theme = ''
						this.translate
							.get('MERCHANT.THEME_ADDUSERS_MAINERROR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						console.log(error)
					}
				)
		} else {
			this.translate
				.get('MERCHANT.ACCOUNT_FIELDS_WRONG')
				.subscribe((text) => {
					this.editTableUserError = text
				}) // Text in component
		}
	}
	onInputNotNumber(event) {
		event.target.value = event.target.value.replace(/[^0-9.]/g, '')
	}

	onKeyDownOnlyDigit(event) {
		let allowCodes = [
			8, 9, 17, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56,
			57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110,
		]

		if (allowCodes.indexOf(event.keyCode) == -1) {
			event.preventDefault()
		}
	}
	focusFunction(event) {
		event.target.value == 0 ? (event.target.value = '') : event.target.value
	}
	onKeyDown(event) {
		let allowCodes = [
			8, 9, 17, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56,
			57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 190,
		]
		if (event.target.value.indexOf('.') != -1) {
			allowCodes.pop()
		}
		if (allowCodes.indexOf(event.keyCode) == -1) {
			event.preventDefault()
		}
	}
	convertOnBlur(searchValue, formName, formValue) {
		if (searchValue > 0) {
			this[formName].patchValue({
				[formValue]: parseFloat(searchValue)
					.toFixed(2)
					.replace(',', '.'),
			})
		} else {
			this[formName].patchValue({
				[formValue]: '0.00',
			})
		}

		if (formValue == 'BankDetails_DailyTurnover') {
			if (parseFloat(searchValue) > 9999999)
				this[formName].patchValue({
					[formValue]: '9999999.99',
				})
		}
	}

	openSettings(templateRef, row) {
		this.activeTab = 0
		this.finally_array = []
		this.editMerchantError = ''
		this.EditForm.reset()
		this.image_error = ''
		this.file_error = ''
		this.selectedBankCode = null
		this.selectedBranch = null
		this.modalDatas.CommissionsSettings = []
		this.bankBranchList = []
		this.isCommissionSettingsExpanded = false
		this.isBranchSettingsExpanded = true
		this.isBankSettingsExpanded = true
		if (this.loading === false) {
			this.loading = true
			this.openModal(templateRef)
			this.isLoadingDetailInfo = true
			this.administrationService.getMerchantInfo(row.Id).subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject

							if (data.ReturnObject.BankDetails !== null) {
								if (data.ReturnObject.MerchantBankCode !== null)
									this.selectedBankCode = data.ReturnObject.MerchantBankCode
								if (data.ReturnObject.BankDetails.BranchCode !== null)
									this.selectedBranch = data.ReturnObject.BankDetails.BranchCode

								this.modalDatas.DailyTurnover = data.ReturnObject.BankDetails.DailyTurnover ?
									(parseFloat(data.ReturnObject.BankDetails.DailyTurnover) / 100).toFixed(2) : '0.00'
							}
							this.modalDatas.Increment =
								new Date().getMilliseconds()
							//--------------------------------		

							this.EditForm.patchValue({
								WorkStyle: data.ReturnObject.WorkStyle.toString(),
								formc_TemplateSelector: 0
							})
						}
					} else {
						this.loading = false
						this.isLoadingDetailInfo = false
					}
					this.editDatasPush(this.modalDatas,false)	},
				(error) => {
					this.isLoadingDetailInfo = false
					this.loading = false
					console.log(error)
				},
				() => {
					this.administrationService
						.getMerchantCommissions(row.Id)
						.subscribe(
							(data) => {
								if (data.ResultCode === 0) {
									if (data.ReturnObject !== null) {
										this.modalDatas.Commissions =
											data.ReturnObject.CommissionSettings
										this.commissionSettingsList =
											data.ReturnObject.InstallmentSettings
										this.modalDatas.CommissionsSettings =
											this.commissionSettingsList.map(
												(x) =>
													new Object({
														InstallmentId:
															x.InstallmentId,
														Value: x.Value
															? (
																parseFloat(
																	x.Value
																) / 100
															).toFixed(2)
															: '0.00',
														changed: false,
														Type: x.Type,
													})
											)
										console.log(
											'Modal Datas::',
											this.modalDatas.CommissionsSettings
										)
										this.editDatasPush(this.modalDatas,true)
									}
								}

							},
							(error) => { }, () => {
								this.administrationService.getJobTitles()
									.subscribe((data) => {
										if (data.ResultCode === 0) {
											if (data.ReturnObject != null) {
												this.jobsList = data.ReturnObject


											}
										}
										this.loading = false
									}, (error) => { },
										() => {
											this.administrationService.getBanks().subscribe((data) => {
												if (data.ResultCode === 0) {
													if (data.ReturnObject !== null) {
														this.bankList = data.ReturnObject

														if (this.selectedBankCode != null && this.bankList.find(x => x.Code === this.selectedBankCode) != null)
															this.getBankBranch(this.bankList.find(x => x.Code === this.selectedBankCode).Key, this.selectedBranch, this.EditForm)
													}

												}
												this.editDatasPush(this.modalDatas,false)
											})

										})
							}
						)
				}

			)



			// Api for modal detail information
		}
	}
	removeFlag: boolean = true
	editDatasPush(datas, isCommission) {
		if (datas !== null) {
			if (!isCommission) {
				this.MerchantStatusBeforeChanged = datas.Status
				this.EditForm.patchValue({ ['Code']: datas.Code })
				if (this.bankList.find(x => x.Code === datas.MerchantBankCode) !== null)
					this.EditForm.patchValue({ ['BankDetails_Bank']: this.bankList.find(x => x.Code === datas.MerchantBankCode), })

				if (this.bankBranchList.find(x => x.Code === datas.BankDetails.BranchCode) !== null)
					this.EditForm.patchValue({ ['BankDetails_Branch']: this.bankBranchList.find(x => x.Code === datas.BankDetails.BranchCode), })

				this.EditForm.patchValue({ ['MerchantBankCode']: datas.MerchantBankCode, })
				this.EditForm.patchValue({ ['BankDetails_BankName']: datas.BankDetails.BankBranch, })
				this.EditForm.patchValue({ ['BankDetails_BranchName']: datas.BankDetails.BranchName, })
				this.EditForm.patchValue({ ['BankDetails_BlockedAccount']: datas.BankDetails.BlockedAccount, })
				this.EditForm.patchValue({ ['BankDetails_DailyTurnover']: datas.DailyTurnover, })
				this.EditForm.patchValue({ ['MerchantBankID']: datas.MerchantBankID, })
				this.EditForm.patchValue({ ['PaymentSystem']: datas.PaymentSystem })
				this.EditForm.patchValue({ ['Name']: datas.Name })
				this.EditForm.patchValue({ ['Description']: datas.Description })
				this.EditForm.patchValue({ ['Address']: datas.Address })
				this.EditForm.patchValue({ ['RegDate']: this.localizedDate.transform(datas.RegDate), })
				this.EditForm.patchValue({ ['Status']: datas.Status })
				this.EditForm.patchValue({ ['ContractNumber']: datas.ContractNumber, })
				this.EditForm.patchValue({ ['Member_Name']: datas.Member.Name })
				this.EditForm.patchValue({ ['Member_Email']: datas.Member.Email })


				if (this.jobsList.find(x => x.JobTitleKey === datas.Member.JobTitleKey) !== null)
					this.EditForm.patchValue({ ['Member_Job']: this.jobsList.find(x => x.JobTitleKey === datas.Member.JobTitleKey) })
				//здесь обновить Member_JobTitle по известному ключу
				this.EditForm.patchValue({ ['Member_JobTitleKey']: datas.Member.JobTitleKey })
				this.EditForm.patchValue({ ['Member_MobileNumber']: datas.Member.MobileNumber, })
				this.EditForm.patchValue({ ['BankDetails_AccountNumber']: datas.BankDetails.AccountNumber, })
				this.EditForm.patchValue({ ['BankDetails_BranchCode']: datas.BankDetails.BranchCode, })
				this.EditForm.patchValue({ ['BankDetails_IBAN']: datas.BankDetails.IBAN, })
				this.EditForm.patchValue({ ['BankDetails_Address']: datas.BankDetails.Address, }) 
				this.EditForm.patchValue({ ['BankDetails_IBAN']: datas.BankDetails.IBAN, })

			} else {
				if (this.modalDatas.Commissions.length) {
					this.modalDatas.Commissions.forEach((commission) => {

						this.EditForm.removeControl(
							'Commission_DirectFee_' + commission.ProviderId
						)
						this.EditForm.removeControl(
							'Commission_BlockedDays_' + commission.ProviderId
						)
						this.EditForm.removeControl(
							'Commission_Status_' + commission.ProviderId
						)
						commission.Changed = 0
						this.EditForm.addControl(
							'Commission_ServiceName_' + commission.ProviderId,
							new UntypedFormControl(commission.ProviderName)
						)
						this.EditForm.addControl(
							'Commission_DirectFee_' + commission.ProviderId,
							new UntypedFormControl(
								(commission.DirectFee / 100).toFixed(2),
								Validators.min(0)
							)
						) // Initialize form rules
						this.EditForm.addControl(
							'Commission_BlockedDays_' + commission.ProviderId,
							new UntypedFormControl(commission.BlockedDays)
						) // Initialize form rules
						this.EditForm.addControl(
							'Commission_Status_' + commission.ProviderId,
							new UntypedFormControl(commission.Status)
						) // Initialize form rules
					})
				}
				if (datas.CommissionsSettings.length > 0) {
					let _flag = this.removeFlag
					for (let i = 0; i < datas.CommissionsSettings.length; i++) {
						let _name =
							'Commission_Setting_' +
							datas.CommissionsSettings[i].InstallmentId,
							_val = datas.CommissionsSettings[i].Value
						if (_flag) {
							this.EditForm.addControl(_name, new UntypedFormControl(_val))
							if (i === datas.CommissionsSettings.length - 1) {
								_flag = false
							}
						} else {
							this.EditForm.patchValue({
								[_name]: _val,
							})
						}
					}
					this.removeFlag = _flag
					this.EditForm.updateValueAndValidity()
				}
			}
			for (var index in this.EditForm.value) {
				if (this.finally_array.find((x) => x.Key == index) == null) {
					var attr = this.EditForm.value[index]
					if (index === 'Status')
						console.log('logtstus')
					this.finally_array.push({
						Key: index,
						Value: attr,
						Level: index.split('_'),
						Changed: 0,
					})
				}
			}
		} else {
			for (var index in this.CreateMerchant.value) {
				var attr = this.CreateMerchant.value[index]
				this.finally_array.push({
					Key: index,
					Value: attr,
					Level: index.split('_'),
					Changed: 0,
				})
			}
		}
		this.isLoadingDetailInfo = false
	}

	makeChanged(target) {
		this.finally_array.find((x) => x.Key == target).Changed = 1
		if (target === 'Member_Job') {

			this.EditForm.controls['Member_JobTitle'].setValue(this.EditForm.controls['Member_Job'].value.JobTitle)
			this.EditForm.controls['Member_JobTitleKey'].setValue(this.EditForm.controls['Member_Job'].value.JobTitleKey)
			this.finally_array.find((x) => x.Key === 'Member_JobTitle').Changed = 1
			this.finally_array.find((x) => x.Key === 'Member_JobTitleKey').Changed = 1
		}
		else if (target === 'BankDetails_Bank') {
			this.selectedBranch = null
			this.selectedBankCode = this.EditForm.controls['BankDetails_Bank'].value.Code
			this.EditForm.patchValue({ ['BankDetails_Address']: this.EditForm.controls['BankDetails_Bank'].value.Address, })
			this.EditForm.patchValue({ ['PaymentSystem']: this.EditForm.controls['BankDetails_Bank'].value.PaymentSystem, })
			this.EditForm.patchValue({ ['MerchantBankCode']: this.EditForm.controls['BankDetails_Bank'].value.Code, })
			this.finally_array.find((x) => x.Key === 'BankDetails_Branch').Changed = 1
			this.getBankBranch(this.EditForm.controls['BankDetails_Bank'].value.Key, null, this.EditForm)
		}
		else if (target === 'BankDetails_Branch') {
			this.EditForm.patchValue({ ['BankDetails_BranchCode']: this.EditForm.controls['BankDetails_Branch'].value.Code })
			this.EditForm.patchValue({ ['MerchantBankCode']: this.EditForm.controls['BankDetails_Bank'].value.Code })
			this.EditForm.patchValue({ ['BankDetails_BranchName']: this.EditForm.controls['BankDetails_Branch'].value.Name })
			this.finally_array.find((x) => x.Key === 'BankDetails_Branch').Changed = 1
		}
	}
	makeChangeNewMerchant(target) {
		this.finally_array.find((x) => x.Key == target).Changed = 1
		if (target === 'Member_Job') {

			this.CreateMerchant.controls['Member_JobTitle'].setValue(this.CreateMerchant.controls['Member_Job'].value.JobTitle)
			this.CreateMerchant.controls['Member_JobTitleKey'].setValue(this.CreateMerchant.controls['Member_Job'].value.JobTitleKey)
			this.finally_array.find((x) => x.Key === 'Member_JobTitle').Changed = 1
			this.finally_array.find((x) => x.Key === 'Member_JobTitleKey').Changed = 1
		}
		else if (target === 'BankDetails_Bank') {
			this.selectedBranch = null
			this.selectedBankCode = this.CreateMerchant.controls['BankDetails_Bank'].value.Code

			this.CreateMerchant.patchValue({ ['BankDetails_Address']: this.CreateMerchant.controls['BankDetails_Bank'].value.Address, })
			this.CreateMerchant.patchValue({ ['PaymentSystem']: this.CreateMerchant.controls['BankDetails_Bank'].value.PaymentSystem, })
			this.CreateMerchant.patchValue({ ['MerchantBankCode']: this.CreateMerchant.controls['BankDetails_Bank'].value.Code, })
			this.getBankBranch(this.CreateMerchant.controls['BankDetails_Bank'].value.Key, null, this.CreateMerchant)

		}
		else if (target === 'BankDetails_Branch') {
			this.CreateMerchant.patchValue({ ['BankDetails_BranchCode']: this.CreateMerchant.controls['BankDetails_Branch'].value.Code })
			this.CreateMerchant.patchValue({ ['MerchantBankCode']: this.CreateMerchant.controls['BankDetails_Bank'].value.Code })
			this.CreateMerchant.patchValue({ ['BankDetails_BranchName']: this.CreateMerchant.controls['BankDetails_Branch'].value.Name })
		}
	}
	getBankBranch(bankKey, selectedBranch, formGroup) {
		this.bankBranchList = [];

		formGroup.patchValue({ ['BankDetails_BranchCode']: '' })
		formGroup.patchValue({ ['BankDetails_Branch']: '' })
		formGroup.patchValue({ ['BankDetails_BranchName']: '' })
		this.administrationService.getBankBranch(bankKey)
			.subscribe((data) => {
				if (data.ResultCode === 0 && data.ReturnObject !== null) {
					this.bankBranchList = data.ReturnObject
					if (this.bankBranchList.length > 0) {

						//	formGroup.patchValue({ ['MerchantBankCode']: this.bankList.find(x => x.Key === bankKey).Code })
						if (selectedBranch === null) {
							//	formGroup.patchValue({ ['BankDetails_Branch']: this.bankBranchList[0] })
							formGroup.patchValue({ ['BankDetails_BranchCode']: '' })
							formGroup.patchValue({ ['BankDetails_BranchName']: '' })
							//	formGroup.patchValue({ ['BankDetails_BranchName']: this.bankBranchList[0].Name })
						}
						else {
							formGroup.patchValue({ ['BankDetails_Branch']: this.bankBranchList.find(x => x.Code === this.selectedBranch) })
							formGroup.patchValue({ ['BankDetails_BranchCode']: this.bankBranchList.find(x => x.Code === this.selectedBranch).Code })
							formGroup.patchValue({ ['BankDetails_BranchName']: this.bankBranchList.find(x => x.Code === this.selectedBranch).Name })
						}

						this.finally_array.find((x) => x.Key === 'BankDetails_Branch').Changed = 1

					}
					 
				}
			})
	}
	commissionSettingChanged(_id) {
		if (
			this.modalDatas &&
			this.modalDatas.CommissionsSettings.length &&
			this.modalDatas.CommissionsSettings.filter(
				(x) => x.InstallmentId == _id
			)[0]
		) {
			this.modalDatas.CommissionsSettings.filter(
				(x) => x.InstallmentId == _id
			)[0].changed = true
		}
		//console.log('Changed', this.modalDatas.CommissionsSettings)
	}

	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}
	//----------------------- update parameters by data from template 
	onTemplateSelected(event) {
		console.log(event);
		if (event.value === 0)
			return;
		//------------------- get template
		this.merchantTemplateService.getItem(event.value).
			subscribe((data) => {
				console.log(data);
				if (data.ResultCode == 0) {
					console.log('template - ', event.value, data.ReturnObject);
					this.EditForm.patchValue({
						WorkStyle: data.ReturnObject.WorkStyle.toString()
					})

					//-----------			
					this.modalDatas.Commissions = data.ReturnObject.CommissionSettings
					this.commissionSettingsList = data.ReturnObject.InstallmentSettings
					this.modalDatas.CommissionsSettings =
						this.commissionSettingsList.map(
							(x) =>
								new Object({
									InstallmentId:
										x.InstallmentId,
									Value: x.Value
										? (
											parseFloat(
												x.Value
											) / 100
										).toFixed(2)
										: '0.00',
									changed: false,
									Type: x.Type,
								})
						)
					console.log(
						'Modal Datas::',
						this.modalDatas.CommissionsSettings
					)
					this.editDatasPush(this.modalDatas,true)
					//-------------
					data.ReturnObject.InstallmentSettings.forEach((item) => {
						this.commissionSettingChanged(item.InstallmentId)
					});
					this.modalDatas.Commissions.forEach((item) => {
						this.makeChanged('Commission_BlockedDays_' + item.ProviderId)
					});

				}
			});
	}

	//---------------------------
	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		; (<any>Object).values(formGroup.controls).forEach((control) => {
			control.markAsTouched()

			if (control.controls) {
				this.markFormGroupTouched(control)
			}
		})
	}

	private markFormGroupUnTouched(formGroup: UntypedFormGroup) {
		; (<any>Object).values(formGroup.controls).forEach((control) => {
			control.markAsUntouched()

			if (control.controls) {
				this.markFormGroupUnTouched(control)
			}
		})
	}
	onlyNumeric(val, e) {
		let selectorId = e.target.id
		var inputValue = <HTMLInputElement>document.getElementById(selectorId)
		var _val = val.replace(/[^\d]/g, '')
		inputValue.value = _val
	}

	newMerchant() {
		this.markFormGroupTouched(this.CreateMerchant)
		this.createMerchantError = ''
		if (this.CreateMerchant.valid === true) {
			this.loading = true
			let sended_datas = []
			this.finally_array.forEach((final) => {
				if (final.Changed == 1) {
					sended_datas.push(final)
					final.Value = this.CreateMerchant.value[final.Key]
				}
				if (final.Changed == 1 && final.Key == 'BankDetails_Branch') {

					sended_datas.push({
						Key: 'BankDetails_BranchCode',
						Value: this.CreateMerchant.controls['BankDetails_BranchCode'].value,
						Level: 'BankDetails_BranchCode'.split('_'),
						Changed: 0,
					})
					sended_datas.push({
						Key: 'BankDetails_BranchName',
						Value: this.CreateMerchant.controls['BankDetails_BranchName'].value,
						Level: 'BankDetails_BranchName'.split('_'),
						Changed: 0,
					})

				}

				if (final.Changed == 1 && final.Key == 'BankDetails_Bank') {
					sended_datas.push({
						Key: 'BankDetails_Address',
						Value: this.CreateMerchant.controls['BankDetails_Address'].value,
						Level: 'BankDetails_Address'.split('_'),
						Changed: 0,
					})
					sended_datas.push({
						Key: 'PaymentSystem',
						Value: this.CreateMerchant.controls['PaymentSystem'].value,
						Level: 'PaymentSystem'.split('_'),
						Changed: 0,
					})
					sended_datas.push({
						Key: 'MerchantBankCode',
						Value: this.CreateMerchant.controls['MerchantBankCode'].value,
						Level: 'MerchantBankCode'.split('_'),
						Changed: 0,
					})
				}
				if (final.Changed === 1 && final.Key === 'BankDetails_DailyTurnover') {
					final.Value = Number(this.CreateMerchant.value[final.Key]) * 100
				}





			})
			if (sended_datas.length) {
				this.administrationService
					.editMerchant(sended_datas, null, null)
					.subscribe(
						(data) => {
							if (data.ResultCode === 0) {
								this.CloseDialog()
								let body = ''
								let theme = ''
								this.translate
									.get('MERCHANT.BODY_NEWMERCHANT_SUCCESS')
									.subscribe((text) => {
										body = text
									}) // Text in component
								this.translate
									.get('MERCHANT.THEME_NEWMERCHANT_SUCCESS')
									.subscribe((text) => {
										theme = text
									}) // Text in component
								this.toastr.success(body, theme)
								this.loading = false
								this.getFirstDatas()
							} else {
								this.loading = false
								data.ResultMessage === null
									? (this.createMerchantError = '')
									: (this.createMerchantError =
										data.ResultMessage)
								let theme = ''
								this.translate
									.get('MERCHANT.THEME_NEWMERCHANT_ERROR')
									.subscribe((text) => {
										theme = text
									}) // Text in component
								this.toastr.error(
									this.createMerchantError,
									theme
								)
							}
						},
						(error) => {
							this.loading = false
							console.log(error)
						}
					)
			} else {
				this.loading = false
			}
		} else {
			this.translate.get('MERCHANT.WRONG_FIELDS').subscribe((text) => {
				this.createMerchantError = text
			}) // Text in component
		}
	}

	tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
		this.activeTab = tabChangeEvent.index
	}
	commissionSettingsList: CommissionSettingsItem[] = []

	ngOnInit() {
		let ls = new SecureLS()
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			Code: new UntypedFormControl(''),
			Name: new UntypedFormControl(''),
			CardplusId: new UntypedFormControl(''),
		}) // for header Filter
		// for (let i = 0; i < 25; i++) {
		//   this.commissionSettingsList.push({
		//     InstallmentId: i,
		//     Value: (i * 10).toFixed(2),
		//     Type: 1,
		//     changed: false
		//   })
		// }
		this.translate.get('REPORT.STATUS_INACTIVE').subscribe((text) => {
			this.States[0].viewValue = text
		})
		this.translate.get('REPORT.STATUS_ACTIVE').subscribe((text) => {
			this.States[1].viewValue = text
		})
		this.CreateMerchant = this.formBuilder.group({
			// IDNO: ["", [Validators.required, Validators.pattern(/^([0-9]{1,20})$/)]],
			Name: ['', Validators.required],
			Status: ['', Validators.required],
			Description: ['', Validators.required],
			ContractNumber: ['', Validators.required],
			Member_Name: ['', Validators.required],
			// Member_Surname: ["", Validators.required],
			Member_Email: [
				'',
				[
					Validators.pattern(
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					),
					Validators.required,
				],
			],
			Member_JobTitle: [''],
			Member_JobTitleKey: [''],
			Member_Job: ['', Validators.required],

			Member_MobileNumber: [
				'',
				[
					Validators.pattern(/^(([+][0-9]{1,}|([0-9])*))$/),
					Validators.required,
				],
			],
			// 'BankDetails_AccountName': ['', Validators.required],
			BankDetails_AccountNumber: ['', Validators.required],
			BankDetails_BranchCode: [{ value: '', disabled: true }, Validators.required],
			// BankDetails_BankCode: [
			//   "",
			//   [
			//     Validators.pattern(/^(\d|\w[^_]*)$/),
			//     Validators.maxLength(11),
			//   ],
			// ],
			// BankDetails_CountryId: ["", Validators.required],
			BankDetails_IBAN: [
				'',
				[
					Validators.required,
					Validators.pattern(/^(\d|\w[^_]*)$/),
					Validators.minLength(1),
					Validators.maxLength(34),
				],
			],

			BankDetails_Address: [{ value: '', disabled: true }, Validators.required],
			Address: ['', Validators.required],
			//	BankDetails_BankCode: ['', Validators.required],
			BankDetails_BankName: [{ value: '', disabled: true }, Validators.required],
			BankDetails_Branch: ['', Validators.required],
			BankDetails_BranchName: [{ value: '', disabled: true }, Validators.required],
			BankDetails_Bank: ['', Validators.required],
			BankDetails_BlockedAccount: ['', Validators.required],
			BankDetails_DailyTurnover: ['', [Validators.required, Validators.min(0), Validators.max(9999999.99), Validators.maxLength(10)]],
			MerchantBankCode: [{ value: '', disabled: true }],

			MerchantBankID: ['', Validators.required],
			PaymentSystem: [{ value: '', disabled: true }, Validators.required],




		})

		this.TableUser = this.formBuilder.group({
			UserEMail: ['', Validators.required],
			UserMobile: [
				'',
				[
					Validators.pattern(/^(([+][0-9]{1,}|([0-9])*))$/),
					Validators.required,
				],
			],
			UserName: ['', Validators.required],
			UserTitle: [''],
			Status: ['', Validators.required],
			Language: ['', Validators.required],
			// Type: ["", Validators.required],
		})

		this.EditForm = this.formBuilder.group({
			Code: [{ value: '', disabled: true }],
			// IDNO: ["", [Validators.required, Validators.pattern(/^([0-9]{1,20})$/)]],
			Name: [''],
			Description: [''],
			ContractNumber: [''],
			RegDate: [{ value: '', disabled: true }],
			Status: [''],
			Member_Name: [''],
			// Member_Surname: [""],
			Member_Email: [
				'',
				Validators.pattern(
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				),
			],
			Member_JobTitle: [''],
			Member_JobTitleKey: [''],
			Member_Job: ['',Validators.required],

			Member_MobileNumber: [
				'',
				Validators.pattern(/^(([+][0-9]{1,}|([0-9])*))$/),
			],

			BankDetails_AccountNumber: [''],
			BankDetails_BlockedAccount: [''],

			BankDetails_DailyTurnover: ['', [Validators.required, Validators.min(0), Validators.max(9999999.99), Validators.maxLength(10)]],

			BankDetails_BranchCode: ['', Validators.required],
			BankDetails_Branch: ['', Validators.required],
			BankDetails_BranchName: [{ value: '' ,disabled:true }, Validators.required],
			BankDetails_IBAN: [
				'',
				[
					Validators.pattern(/^(\d|\w[^_]*)$/),
					Validators.minLength(1),
					Validators.maxLength(34),
				],
			],
			BankDetails_Address: [{ value: '', disabled: true }],
			Address: [''],
			MerchantBankCode: [{ value: '', disabled: true }, Validators.required],
			BankDetails_BankName: [{ value: '', disabled: true }],
			BankDetails_Bank: [{ value: '' }],

			MerchantBankID: [{ value: '', disabled: true }],
			PaymentSystem: [{ value: '', disabled: true }],
			WorkStyle: [],
			formc_TemplateSelector: ['', Validators.required],
		})
		//this.loadMerchantPage()
		this.administrationService.getMerchantTemplate().
			subscribe((data) => {
				console.log(data);
				if (data.ResultCode == 0) {
					this.templateList = data.ReturnObject;
				}
			});
		//-------- get list 
		this.getFirstDatas();
		// Delete After test!
		// till here
	}
	//--------- add functionality for using template of commission
}
interface CommissionSettingsItem {
	InstallmentId: number
	Value: string
	Type: number
	changed?: boolean
}
