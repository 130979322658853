import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';



import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ChangePasswordComponent } from './changepassword.component';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [ CommonModule, FormsModule, ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,		
    RouterModule,
		TranslateModule ],
  declarations: [
    ChangePasswordComponent
  ]
})
export class SettingsModule { }
