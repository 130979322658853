import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {DateAdapter} from '@angular/material/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { AdministrationService } from '../../_services/administration.service';
import { ReportsDatasService } from '../../_services/reportsdata.service';
import { Globals } from '../../globals/globals';
/** Constants used to fill up our data base. */

export interface ItemList {
    Value: string;
    Name: string;
}


@Component({
    templateUrl: 'system-request.component.html',
    styleUrls: ['reports.scss'],
    providers: [DatePipe, Globals]
})
export class SystemRequestComponent implements OnInit {

    public paginationItems; // for display pagination from api
    public paginationItemPerPage; // for display pagination from api

    public transactionCurrentID;
    public transactionCurrentDealerName;
    public transactionCurrentServiceName;
    public currentPage = 0;
    isLoadingDetailInfo: boolean = false;
	modalDatas = {
        'Request' : {},
        'Response' : {},
    };// for display datas in modal

    //-------------------------------------
    providersList;
    Transactions_Datas;
    dealerListOptions: ItemList[]; // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];
    loading = false; // for disable fast response click
    pageEvent; // for fix error of pageEvent in Visual Studio
    panelOpenState = true;
    // For change pages

    services: ItemList[];

    account = ''; // Account input
    refStan = ''; // refStan input
    serviceSelected = ''; // Service selected
    userLogin = ''; // User Login input

    minDate = new Date(2000, 0, 1); // Date picker min date
    maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
    events: string[] = []; // Date picker
    dealerFControl = new UntypedFormControl(); // Dealer


    dealerFilteredOptions: Observable<ItemList[]>; // dealer
    currentTime = new Date();
    //----------------- list of columns 
    displayedColumns: string[] = ['ResponseCode', 'UserName', 'MethodType', 'CompanyId', 'SubCompanyId', 'Account', 'Amount', 'Currency', 'ExternalId', 'ExternalDate', 'RegDate'];

    dataSource = new MatTableDataSource(); // array of datas
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog,
        // Make service for post api datas
        private datePipe: DatePipe,
        private reportsDataService: ReportsDatasService,
        private providerService: ProviderService,
        private administrationService: AdministrationService,
        private dateAdapter: DateAdapter<Date>,
        private globals: Globals
    ) {
        //dateAdapter.setLocale('ru-RU'); // change this for change format date in select

        // Assign the data to the data source for the table to render
        // this.dataSource = new MatTableDataSource(users);
    }

    FilterForm: UntypedFormGroup; // Form initial
    //dealerDropValidator(controls): ValidatorFn {
    //    return (control: AbstractControl): { [key: string]: boolean } | null => {
    //        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
    //            return { 'dealer': true };
    //        }
    //        return null;
    //    };
    //}
    ngOnInit() {

        this.paginationItemPerPage = 10;
        this.InitProviderFilter();
        this.InitDealerFilter();

        this.FilterForm = new UntypedFormGroup({
            'Dealer': new UntypedFormControl(), //, [this.dealerDropValidator(this.options)]),
            'dateFrom': new UntypedFormControl({ value: this.currentTime, disabled: true }, Validators.required),
            'dateTo': new UntypedFormControl({ value: this.currentTime, disabled: true }, Validators.required),
            'Account': new UntypedFormControl(''),
            'RefStan': new UntypedFormControl(''),
            'UserName': new UntypedFormControl(''),
            'ServiceDrop': new UntypedFormControl(''),
        });
        this.getFirstDatas();
    }
    //----------------------- get a provider list and put to option's 
    InitProviderFilter() {

        let p = localStorage.getItem('providerAll');
        if (p === null) {
            this.providerService.AllList().pipe(first())
                .subscribe(
                    data => {
                        localStorage.setItem('providerAll', JSON.stringify(data));
                        this.services = data.map(x => ({ Value: x.ID.toString(), Name: x.Name }));
                        this.services.unshift({ Value: '', Name: 'All' });
                    },
                    error => {
                        console.log(error);
                    });
        } else {
            let prvs = JSON.parse(p);
            this.services = prvs.map(x => ({ Value: x.ID.toString(), Name: x.Name }));
            this.services.unshift({ Value: '', Name: 'All' });
        }
    }
    //----------------------- get a dealer list and put to option's 
    InitDealerFilter() {
        let dealers = localStorage.getItem('dealerAllList');
        if (dealers === null) {
            this.administrationService.getDealerDatasAsList().pipe(first())
                .subscribe(
                    data => {
                        localStorage.setItem('dealerAllList', JSON.stringify(data.ReturnObject));
                        this.dealerListOptions = data.ReturnObject.map(x => ({ Value: x.Id.toString(), Name: x.Name }));
                        this.dealerListOptions.unshift({ Value: null, Name: 'All' });
                        this.dealerFilteredOptions = this.dealerFControl.valueChanges
                            .pipe(
                                startWith<string | ItemList>(''),
                                map(value => typeof value === 'string' ? value : value.Name),
                                map(name => name ? this._filter(name) : this.dealerListOptions.slice())
                            );//dealer
                        this.dealerFControl.setValue(this.dealerListOptions[0]);
                    },
                    error => {
                        console.log(error);
                    });
        } else {
            let ds = JSON.parse(dealers);
            this.dealerListOptions = ds.map(x => ({ Value: x.Id.toString(), Name: x.Name }));
            this.dealerListOptions.unshift({ Value: null, Name: 'All' });
            this.dealerFilteredOptions = this.dealerFControl.valueChanges
                .pipe(
                    startWith<string | ItemList>(''),
                    map(value => typeof value === 'string' ? value : value.Name),
                    map(name => name ? this._filter(name) : this.dealerListOptions.slice())
                );//dealer
            this.dealerFControl.setValue(this.dealerListOptions[0]);
        }
    }
    //----------------------  
    public handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.paginationItemPerPage = e.pageSize;
        this.getFirstDatas();
    }

    getFirstDatas() {
        this.loading = true;
        let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
        let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
        // Take datas from form
        let dealerId = this.dealerFControl.value === null ? null : this.dealerFControl.value.Value;
        let filter = this.FilterForm.value;
        let currentPage = this.currentPage + 1;

        this.reportsDataService.getSystemDatas(start, finish, filter, dealerId, currentPage, this.paginationItemPerPage)
            .subscribe(
                data => {
                    if (data.ResultCode === 0) {
                        let obj = data.ReturnObject;
                        console.log(obj);
                        if (obj !== null) {
                            this.Transactions_Datas = obj.Items;
                            this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas
                            //this.dataSource.sort = this.sort; // activate sort after insert datas
                            // this.dataSource.paginator = this.paginator; // activate paginator after insert datas
                            this.paginationItems = obj.Pagination.ItemTotal;
                        }
                        else {
                            this.paginationItems = 0;
                            this.Transactions_Datas = [];
                            this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log(error);
                });
    }

    onSubmit() {
        this.getFirstDatas();
    }

    displayDealer(item?: ItemList): string | undefined {
        return item ? item.Name : undefined;
    }

    private _filter(item: string): ItemList[] {
        const filterValue = item.toLowerCase();
        return this.dealerListOptions.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }

    //----------------------
    PaginationCurentPageReset(e: any) {
        this.currentPage = 0;
    }
    //------------------- private helpers 
    // convenience getter for easy access to form fields
    get f() { return this.FilterForm.controls; }

    CloseDialog(): void {
        this.dialog.closeAll();
    }

    selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true;
		this.reportsDataService.getAdvanceSystem(row.Key)
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject;
							this.isLoadingDetailInfo = false;
							//console.log(this.modalDatas);
						}
						else {
							this.isLoadingDetailInfo = false;
						}
                    }
                    else{
                        // If pKey is wrong or not datas for this array, make Alert and close Modal
                        alert(data.ResultMessage);
                        this.CloseDialog();
                    }
				},
				error => {
					this.isLoadingDetailInfo = false;
					console.log(error);
				});
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		});
	}
}