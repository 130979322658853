import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../../_services'

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'

import { DataSource } from '@angular/cdk/table'
import { ReportsDatasService } from '../../../_services/reportsdata.service'
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { Globals } from '../../../globals/globals'

export interface Merchant {
	id: string
	Name: string
}

@Component({
	templateUrl: 'rating-report.component.html',
	styleUrls: ['../../reports/reports.scss'],
	providers: [DatePipe, Globals],
})
export class RatingReportComponent implements OnInit {
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	permissions

	modalDatas = {
		Id: '',
		MerchantName: '',
		ExternalId: '',
		CheckOutId: '',
		Amount: '',
		Fee: '',
		TotalAmount: '',
		CurrencyCode: '',
		RegDate: '',
		PayDate: '',
		ClearingDate: '',
		MoneyProviderName: '',
		CanceledDate: '',
		Status: 0,
		InfoJson: '',
		Note: '',
	}
	radioModel = 1
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner
	isDownloadingFile: boolean = false // spinner
	Info
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	// For change pages
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	currentTime = new Date()
	allPageLoad: boolean = false

	MerchantList: Merchant[] = [{ id: '', Name: '' }]

	displayedColumns: string[] = [
		'Date',
		'Rating',
		'AppVersion',
		'BundleVersion',
		'UserName',
		'Info',
	]

	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		public translate: TranslateService,
		private reportsDataService: ReportsDatasService,
		private datePipe: DatePipe,
		public route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private globals: Globals
	) {}
	FilterForm: UntypedFormGroup // Form initial
	appVersionsList = []
	bundleVersionsList = []
	ratingsList = [
		{
			text: 'All',
			value: null,
		},
		{
			text: '1',
			value: 1,
		},
		{
			text: '2',
			value: 2,
		},
		{
			text: '3',
			value: 3,
		},
		{
			text: '4',
			value: 4,
		},
		{
			text: '5',
			value: 5,
		},
	]
	statusLists = [
		{
			text: 'Init',
			value: 0,
			color: 'badge-warning',
		},
		{
			text: 'Pending',
			value: 1,
			color: 'badge-success',
		},
		{
			text: 'Error',
			value: 2,
			color: 'badge-secondary',
		},
		{
			text: 'Pay',
			value: 3,
			color: 'badge-success',
		},
		{
			text: 'Refund',
			value: 4,
			color: 'badge-danger',
		},
		{
			text: 'Cancel',
			value: 5,
			color: 'badge-secondary',
		},
		{
			text: 'Close',
			value: 6,
			color: 'badge-secondary',
		},
		{
			text: 'Refund partial',
			value: 7,
			color: 'badge-danger',
		},
	]
	filterStatusLists = [
		{
			text: 'All',
			value: 'null',
			color: '',
		},
		{
			text: 'Init',
			value: 0,
			color: 'badge-warning',
		},
		{
			text: 'Pay',
			value: 3,
			color: 'badge-success',
		},
		{
			text: 'Close',
			value: 6,
			color: 'badge-secondary',
		},
		{
			text: 'Refund',
			value: 4,
			color: 'badge-danger',
		},
		{
			text: 'Refund_partial',
			value: 7,
			color: 'badge-danger',
		},
	]
	filteredOptions: Observable<Merchant[]>
	displayFn(user?: Merchant): string | undefined {
		return user ? user.Name : undefined
	}

	private _filter(name: string): Merchant[] {
		const filterValue = name.toLowerCase()
		return this.MerchantList.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}
	fillBundleList() {
		this.reportsDataService.getBundleVersionsList().subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					this.bundleVersionsList = data.ReturnObject
					this.translate
						.get('REPORT.ALL_BUNDLEVERSIONS')
						.subscribe((total_versions) => {
							this.bundleVersionsList.unshift({
								Key: null,
								Name: total_versions,
							})
						})
					this.FilterForm.patchValue({
						['BundleVersion']: this.bundleVersionsList[0].Key,
					})
				}
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.allPageLoad = true
				this.getFirstDatas(this.radioModel)
			}
		)
	}
	fillAppList() {
		this.FilterForm.patchValue({
			['Ratings']: null,
		})
		this.reportsDataService.getAppVersionsList().subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					this.appVersionsList = data.ReturnObject
					this.translate
						.get('REPORT.ALL_APPVERSIONS')
						.subscribe((total_versions) => {
							this.appVersionsList.unshift({
								Key: null,
								Name: total_versions,
							})
						})
					this.FilterForm.patchValue({
						['AppVersion']: this.appVersionsList[0].Key,
					})
				}
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.allPageLoad = true
				this.fillBundleList()
			}
		)
	}
	download(format) {
		if (this.loading === false && this.isDownloadingFile === false) {
			let start = this.datePipe.transform(
				this.FilterForm.controls.dateFrom.value,
				'yyyy-MM-ddT00:00:00'
			)
			let finish = this.datePipe.transform(
				this.FilterForm.controls.dateTo.value,
				'yyyy-MM-ddT23:59:59'
			)
			this.isDownloadingFile = true
			this.reportsDataService
				.getRatingFile(
					start,
					finish,
					this.FilterForm.value.AppVersion,
					this.FilterForm.value.BundleVersion,
					this.FilterForm.value.Ratings,
					this.FilterForm.value.UserName,
					this.radioModel,
					format
				)
				.subscribe(
					(res) => {
						this.isDownloadingFile = false
						saveAs(
							res,
							'RatingReport_' +
								this.datePipe.transform(
									this.FilterForm.controls.dateTo.value,
									'yyyy-MM-dd'
								) +
								'.' +
								format,
							{
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							}
						)
					},
					(error) => {
						console.log(error)
						this.isDownloadingFile = false
					}
				)
		}
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	openInfo(templateRef, id) {
		this.loading = true
		this.isLoadingDetailInfo = true
		this.dialog.open(templateRef, {})
		this.reportsDataService.getTransactionInfo(id).subscribe(
			(data) => {
				console.log(data)
				if (data.ResultCode === 0) {
					this.modalDatas = data.ReturnObject
				}
				this.isLoadingDetailInfo = false
				this.loading = false
			},
			(error) => {
				this.loading = false
				this.isLoadingDetailInfo = false
				console.log(error)
			}
		)
	}
	getMonday(d) {
		d = new Date(d)
		var day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
		return new Date(d.setDate(diff))
	}
	getFirstDatas(period) {
		this.loading = true
		this.radioModel = period
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddT00:00:00'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddT23:59:59'
		)
		this.reportsDataService
			.getRatingReportDatas(
				start,
				finish,
				this.FilterForm.value.AppVersion,
				this.FilterForm.value.BundleVersion,
				this.FilterForm.value.Ratings,
				this.FilterForm.value.UserName,
				this.paginationItemPerPage,
				this.currentPage + 1,
				period
			)
			.subscribe(
				(data) => {
					let datas = []
					this.paginationItems = 0
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.Ratings
							this.paginationItems = data.ReturnObject.Fetch.Cnt
						}
					}
					this.dataSource = new MatTableDataSource(datas) // array of datas
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}
	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}

	ngOnInit() {
		let ls = new SecureLS() // for Secure Local storage
		if (ls.get('info') !== null) {
			this.Info = ls.get('info')
		}
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]
		this.translate.get('REPORT.FILTER_RATINGS_ALL').subscribe((text) => {
			this.ratingsList[0].text = text
			this.ratingsList[0].value = null
		})

		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			AppVersion: new UntypedFormControl(''),
			BundleVersion: new UntypedFormControl(''),
			Ratings: new UntypedFormControl(null),
			UserName: new UntypedFormControl(''),
			OperId: new UntypedFormControl(''),
		}) // for header Filter
		// this.MerchantsGets();
		this.fillAppList()
		// this.getFirstDatas(this.radioModel);
	}
}
