import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class SendNotificationDataService {
	private notificationSubject = new Subject<any>()
	notification$ = this.notificationSubject.asObservable()

	public SendNotificData(NotificData: any) {
		this.notificationSubject.next(NotificData)
	}

	saveNotoficationData(key: string, data: any) {
		sessionStorage.setItem(key, JSON.stringify(data))
	}
	resetNotoficationData(key: string) {
		sessionStorage.clear()
	}

	getStorageNotoficationData(key: string): any {
		const checkBox = sessionStorage.getItem(key)
		return checkBox ? JSON.parse(checkBox) : null
	}
}
