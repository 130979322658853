import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { map, first } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { Subject } from 'rxjs'

@Injectable()
export class ToolsService {
	constructor(
		private http: HttpClient,
		private authService: AuthenticationService
	) {}

	getBanks(code, name) {
		return this.http
			.get<any>(
				this.authService.basUrl +
					`/api/tools/getbanks?code=${code}&name=${name}`
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	postBanksNewUpdate(key, code, name, address, system) {
		let body = {
			Key: key,
			Name: name,
			Address: address,
			Code: code,
			PaymentSystem: system,
		}
		return this.http
			.post<any>(this.authService.basUrl + `/api/tools/Banks`, body, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': this.GetLang(),
				},
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getBankBranch(bankKey) {
		return this.http
			.get<any>(
				this.authService.basUrl +
					`/api/Merchant/GetBankBranch?bankKey=` +
					bankKey
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	postBranckNewUpdate(bankKey, key, code, name) {
		let body = {
			Key: key,
			Name: name,
			Code: code,
			BankKey: bankKey,
		}
		return this.http
			.post<any>(
				this.authService.basUrl + `/api/tools/BankBranch`,
				body,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	//------------------------------------------- private functions
	private GetLang() {
		switch (localStorage.getItem('currentLang')) {
			case 'ru':
				return 'ru-RU'
			case 'tr':
				return 'tr-TR'
		}
		return 'en-US'
	}
}
