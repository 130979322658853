import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Import Containers
import { DefaultLayoutComponent } from './containers'

import { P404Component } from './views/error/404.component'
import { P500Component } from './views/error/500.component'
import { LoginComponent } from './views/login/login.component'
import { RegisterComponent } from './views/register/register.component'

import { AuthGuard } from './_guards'
import { PaymentPageComponent } from './views/payments/payment-page.component'
import { UsersComponent } from './views/administation/users.component'
import { PaymentCancelComponent } from './views/payments/payment-cancel.component'
import { RoleGuard } from './_guards/role.guard'
import { TranslateComponent } from './views/administation/translate.component'
import { MerchantsComponent } from './views/administation/merchants.component'
import { DepositOperationsComponent } from './views/reports/deposit-operations.component'
import { RefundOperationComponent } from './views/reports/refund-operation/refund-operation.component'
import { WithdrawRequestsComponent } from './views/reports/withdraw-requests.component'
import { PSPAquairingComponent } from './views/psp/psp-aquairing.component'
import { TransactionReportComponent } from './views/reports/transaction-report.component'
import { CheckoutReportComponent } from './views/transactions/checkout-report/checkout-report.component'
import { RolesReportComponent } from './views/administation/roles-report.component'
import { ChangePasswordComponent } from './views/settings/changepassword.component'
import { InvoiceReportComponent } from './views/invoice/invoice-report/invoice-report.component'
import { RatingReportComponent } from './views/tools/rating-report/rating-report.component'
import { CampaignsComponent } from './views/tools/campaigns/campaigns.component'
// import { MessagingReportComponent } from "./views/messaging/messaging-report/messaging-report.component";
import { SalesReportComponent } from './views/sales/sales-report/sales-report.component'
import { OperationsLogComponent } from './views/customers/operations-log/operations-log.component'
import { CustomersReportComponent } from './views/customers/customers-report/customers-report.component'
import { OrderHistoryComponent } from './views/transactions/order-history/order-history.component'
import { StatementComponent } from './views/transactions/statement/statement.component'
import { RefundsReportComponent } from './views/transactions/refunds-report.component'
import { TransacReportComponent } from './views/reports/transac-report/transac-report.component'
import { TemplateComponent } from './views/tools/template/template.component'
import { BankEntityComponent } from './views/tools/bank-entity/bank-entity.component'
import { NotificationComponent } from './views/tools/notification/notification.component'
import { NewNotificationComponent } from './views/tools/notification/new-notification.component'
import { ApiNotificationComponent } from './views/tools/api-notification/api-notification.component'

export const routes: Routes = [
	{
		path: 'settings',
		redirectTo: 'change-password',
	},
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: '404',
		component: P404Component,
		data: {
			title: 'Page 404',
		},
	},
	{
		path: '500',
		component: P500Component,
		data: {
			title: 'Page 500',
		},
	},
	{
		path: 'login',
		component: LoginComponent,
		data: {
			title: 'Login Page',
		},
	},
	{
		path: 'register',
		component: RegisterComponent,
		data: {
			title: 'Register Page',
		},
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		data: {
			title: 'Home',
			pathCheck: null,
		},
		children: [
			{
				path: 'translate-system',
				component: TranslateComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'translate_system',
					title: 'System Translating',
				},
			},
			{
				path: 'change-password',
				component: ChangePasswordComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Change Password',
				},
			},
			{
				path: 'payments-history',
				component: TransactionReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Transaction Report',
					pathCheck: 'payments_history',
				},
			},
			{
				path: 'checkout-report',
				component: CheckoutReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Checkout Report',
					pathCheck: 'checkout_report',
				},
			},
			{
				path: 'account-statement',
				component: DepositOperationsComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Deposit Operation',
					pathCheck: 'account_statement',
				},
			},
			{
				path: 'refund-operation',
				component: RefundsReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Refund Operation',
					pathCheck: 'refund_operation',
				},
			},
			{
				path: 'transac-report',
				component: TransacReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Transaction Report',
					pathCheck: 'transaction_report',
				},
			},
			{
				path: 'order-history',
				component: OrderHistoryComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Order History',
					pathCheck: 'order_history',
				},
			},
			{
				path: 'statement',
				component: StatementComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Account Statement',
					pathCheck: 'account_statement',
				},
			},

			{
				path: 'operations-log',
				component: OperationsLogComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Operation log',
					pathCheck: 'operations_log',
				},
			},
			{
				path: 'customers-report',
				component: CustomersReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Customers report',
					pathCheck: 'customers_report',
				},
			},
			{
				path: 'roles-report',
				component: RolesReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Roles Report',
					pathCheck: 'roles_report',
				},
			},
			{
				path: 'psp-aquairing',
				component: PSPAquairingComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'psp_aquairing',
					title: 'PSP Aquairing',
				},
			},
			{
				path: 'sales-report',
				component: SalesReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Sales Report',
					pathCheck: 'sales_report',
				},
			},
			{
				path: 'rating-report',
				component: RatingReportComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'rating_report',
					title: 'Rating Report',
				},
			},
			{
				path: 'campaigns-report',
				component: CampaignsComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'campaign_report',
					title: 'Campaigns Report',
				},
			},
			{
				path: 'notification',
				component: NotificationComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'notification',
					title: 'Notification',
				},
			},
			{
				path: 'notification/new-notification',
				component: NewNotificationComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'notification',
					title: 'New Notification',
				},
			},
			{
				path: 'notification/edit-notification/:id',
				component: NewNotificationComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'notification',
					title: 'Edit Notification',
				},
			},
			{
				path: 'notification/view-notification/:id',
				component: NewNotificationComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'notification',
					title: 'View Notification',
				},
			},
			{
				path: 'api-notification',
				component: ApiNotificationComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'notification',
					title: 'Api Notification',
				},
			},
			{
				path: 'merchant-template',
				component: TemplateComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'merchant_template',
					title: 'Merchant template Report',
				},
			},
			{
				path: 'bank-entity',
				component: BankEntityComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'bank_entity',
					title: 'Bank entity mangment',
				},
			},
			{
				path: 'withdraw-requests',
				component: WithdrawRequestsComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Withdraw Requests',
					pathCheck: 'withdraw_request',
				},
			},
			{
				path: 'invoice-report',
				component: InvoiceReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Invoice Report',
					pathCheck: 'invoicing_report',
				},
			},
			// {
			//   path: "messaging-report",
			//   component: MessagingReportComponent,
			//   canActivate: [AuthGuard],
			//   data: {
			//     title: "Messaging Report",
			//     //   pathCheck: "invoice_report",
			//   },
			// },
			{
				path: 'users',
				canActivate: [AuthGuard],
				component: UsersComponent,
				data: {
					pathCheck: 'users',
					title: 'Users',
				},
			},
			{
				path: 'merchants',
				canActivate: [AuthGuard],
				component: MerchantsComponent,
				data: {
					pathCheck: 'merchants',
					title: 'Merchants',
				},
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./views/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'dashboard',
					title: 'Dashboard',
				},
			},
		],
	},
]

export const routing = RouterModule.forRoot(routes, {})
