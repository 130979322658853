import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../../_services'

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'

import { DataSource } from '@angular/cdk/table'
import { ReportsDatasService } from '../../../_services/reportsdata.service'
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { Globals } from '../../../globals/globals'

export interface Merchant {
	id: string
	Name: string
}

@Component({
	templateUrl: 'checkout-report.component.html',
	styleUrls: ['../../reports/reports.scss'],
	providers: [DatePipe, Globals],
})
export class CheckoutReportComponent implements OnInit {
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	permissions

	modalDatas = {
		Id: '',
		MerchantName: '',
		ExternalId: '',
		CheckOutId: '',
		Amount: '',
		Fee: '',
		TotalAmount: '',
		CurrencyCode: '',
		RegDate: '',
		PayDate: '',
		ClearingDate: '',
		MoneyProviderName: '',
		CanceledDate: '',
		Status: 0,
		InfoJson: '',
		Note: '',
	}
	requestIsTrue: boolean = false
	radioModel = 1
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner
	isDownloadingFile: boolean = false // spinner
	Info
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	// For change pages
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	currentTime = new Date()
	allPageLoad: boolean = false
	modalListOperation = {}
	modalListLogs = []
	modalRequestLogs = {}
	MerchantList: Merchant[] = [{ id: '', Name: '' }]
	totalAmounts: number = 0
	totalAmountsFee: number = 0

	displayedColumns: string[] = [
		'Id',
		'RegisteredDate',
		'ApprovedDate',
		'Type',
		'ExternalId',
		'Amount',
		'Fee',
		'Status',
		'UserId',
		'ReferenceId',
		'CardPan',
		'RRN',
	]

	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		public translate: TranslateService,
		private reportsDataService: ReportsDatasService,
		private datePipe: DatePipe,
		public route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private globals: Globals
	) {}
	FilterForm: UntypedFormGroup // Form initial

	statusList = [
		{
			text: 'All',
			value: 'null',
			color: 'badge',
		},
		{
			text: 'Init',
			value: 0,
			color: 'badge-success',
		},
		{
			text: 'Pending',
			value: 1,
			color: 'badge-warning',
		},
		{
			text: 'Canceled',
			value: 2,
			color: 'badge-secondary',
		},
		{
			text: 'Approved',
			value: 3,
			color: 'badge-success',
		},
		{
			text: 'Declined',
			value: 4,
			color: 'badge-danger',
		},
		{
			text: 'Problem',
			value: 5,
			color: 'badge-warning',
		},
		{
			text: 'Reversed',
			value: 6,
			color: 'badge-warning',
		},
		{
			text: 'Closed',
			value: 7,
			color: 'badge-success',
		},
		{
			text: 'RedirectTo3DSEC',
			value: 8,
			color: 'badge-warning',
		},
		{
			text: 'ClosedByTimeout',
			value: 9,
			color: 'badge-warning',
		},
	]

	statusRow = [
		{
			text: 'Init',
			color: 'badge-success',
		},
		{
			text: 'Pending',
			color: 'badge-warning',
		},
		{
			text: 'Canceled',
			color: 'badge-secondary',
		},
		{
			text: 'Approved',
			color: 'badge-success',
		},
		{
			text: 'Declined',
			color: 'badge-danger',
		},
		{
			text: 'Problem',
			color: 'badge-warning',
		},
		{
			text: 'Reversed',
			color: 'badge-warning',
		},
		{
			text: 'Closed',
			color: 'badge-success',
		},
		{
			text: 'RedirectTo3DSEC',
			color: 'badge-warning',
		},
		{
			text: 'ClosedByTimeout',
			color: 'badge-warning',
		},
	]

	filteredOptions: Observable<Merchant[]>
	displayFn(user?: Merchant): string | undefined {
		return user ? user.Name : undefined
	}

	private _filter(name: string): Merchant[] {
		const filterValue = name.toLowerCase()
		return this.MerchantList.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	download(format) {
		if (this.loading === false && this.isDownloadingFile === false) {
			let start = this.datePipe.transform(
				this.FilterForm.controls.dateFrom.value,
				'yyyy-MM-ddT00:00:00'
			)
			let finish = this.datePipe.transform(
				this.FilterForm.controls.dateTo.value,
				'yyyy-MM-ddT23:59:59'
			)
			this.isDownloadingFile = true
			this.reportsDataService
				.getCheckoutFile(
					start,
					finish,
					this.FilterForm.value.Id,
					this.FilterForm.value.AppId,
					this.FilterForm.value.UserId,
					this.FilterForm.value.ExternalId,
					this.FilterForm.value.ReferenceId,
					this.FilterForm.value.CardPan,
					this.FilterForm.value.RRN,
					this.FilterForm.value.Status,
					this.paginationItemPerPage,
					this.currentPage + 1,
					this.radioModel
				)
				.subscribe(
					(res) => {
						this.isDownloadingFile = false
						saveAs(
							res,
							'CheckoutReport_' +
								this.datePipe.transform(
									this.FilterForm.controls.dateTo.value,
									'yyyy-MM-dd'
								) +
								'.' +
								format,
							{
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							}
						)
					},
					(error) => {
						console.log(error)
						this.isDownloadingFile = false
					}
				)
		}
	}

	CloseDialog(idDialog: string): void {
		// this.dialog.closeAll();
		this.dialog.getDialogById(idDialog).close()
	}
	getMonday(d) {
		d = new Date(d)
		var day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
		return new Date(d.setDate(diff))
	}
	getFirstDatas(period) {
		this.loading = true
		this.radioModel = period
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddT00:00:00'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddT23:59:59'
		)
		this.reportsDataService
			.getCheckOutDatas(
				start,
				finish,
				this.FilterForm.value.Id,
				this.FilterForm.value.AppId,
				this.FilterForm.value.UserId,
				this.FilterForm.value.ExternalId,
				this.FilterForm.value.ReferenceId,
				this.FilterForm.value.CardPan,
				this.FilterForm.value.RRN,
				this.FilterForm.value.Status,
				this.paginationItemPerPage,
				this.currentPage + 1,
				period
			)
			.subscribe(
				(data) => {
					let datas = []
					this.paginationItems = 0
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.Operations
							this.paginationItems =
								data.ReturnObject.Pagination.TotalItems
							this.totalAmounts =
								data.ReturnObject.Pagination.TotalAmount
							this.totalAmountsFee =
								data.ReturnObject.Pagination.TotalFeeAmount
						}
					}
					this.dataSource = new MatTableDataSource(datas) // array of datas
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}
	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}

	openModal(id: string, templateRef: any): void {
		this.isLoadingDetailInfo = false
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			height: '650px',
			id: id,
		})
	}

	openDetails(idDialog: string, templateRef: any, id: number): void {
		this.openModal(idDialog, templateRef)
		this.isLoadingDetailInfo = true
		this.loading = true
		this.reportsDataService.getDetails(id).subscribe(
			(data) => {
				console.log(data)
				if (data.ResultCode === 0) {
					if (data.ReturnObject !== null) {
						this.modalListOperation = data.ReturnObject.Operation
						this.modalListLogs = data.ReturnObject.Logs
						console.log(this.modalListLogs)
					}
					this.isLoadingDetailInfo = false
					this.loading = false
				} else {
					this.loading = false
				}
			},
			(error) => {
				this.isLoadingDetailInfo = false
				this.loading = false
				console.log(error)
			}
		)
	}
	getRequest(idDialog: string, templateRef: any, key: string): void {
		this.openModal(idDialog, templateRef)
		this.isLoadingDetailInfo = true
		this.loading = true
		this.reportsDataService.getLog(key).subscribe(
			(data) => {
				console.log(data)
				if (data.ResultCode === 0) {
					if (data.ReturnObject !== null) {
						this.modalRequestLogs = data.ReturnObject
						this.requestIsTrue = true
					}
					this.isLoadingDetailInfo = false
					this.loading = false
				} else {
					this.loading = false
				}
			},
			(error) => {
				this.isLoadingDetailInfo = false
				this.loading = false
				console.log(error)
			}
		)
	}
	whatBadge(name) {
		for (let i = 0; i < this.statusList.length; i++) {
			if (this.statusList[i].value == name) {
				return this.statusList[i].color
			}
		}
	}
	whatText(name) {
		for (let i = 0; i < this.statusList.length; i++) {
			if (this.statusList[i].value == name) {
				return this.statusList[i].text
			}
		}
	}
	whatTypeText(name) {
		for (let i = 0; i < this.typesList.length; i++) {
			if (this.typesList[i].value == name) {
				return this.typesList[i].text
			}
		}
	}
	typesList = [
		{
			text: 'All',
			value: 'null',
			color: 'badge',
		},
		{
			text: 'Payment',
			value: 1,
			color: 'badge-success',
		},
		{
			text: 'PaymentByToken',
			value: 2,
			color: 'badge-warning',
		},
		{
			text: 'PaymentBy3D',
			value: 3,
			color: 'badge-secondary',
		},
		{
			text: 'CardLinking',
			value: 4,
			color: 'badge-secondary',
		},
		{
			text: 'Refund',
			value: -1,
			color: 'badge-secondary',
		},
		{
			text: 'Reverse',
			value: -2,
			color: 'badge-secondary',
		},
	]
	ngOnInit() {
		let ls = new SecureLS() // for Secure Local storage
		if (ls.get('info') !== null) {
			this.Info = ls.get('info')
		}
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]

		this.translate.get('REPORT.FILTER_STATUS_ALL').subscribe((text) => {
			this.statusList[0].text = text
		})
		this.translate.get('REPORT.FILTER_TYPE_ALL').subscribe((text) => {
			this.typesList[0].text = text
		})
		this.translate.get('REPORT.CHECKOUT_TYPE_PAYMENT').subscribe((text) => {
			this.typesList[1].text = text
		})
		this.translate
			.get('REPORT.CHECKOUT_TYPE_PAYMENTBYTOKEN')
			.subscribe((text) => {
				this.typesList[2].text = text
			})
		this.translate
			.get('REPORT.CHECKOUT_TYPE_PAYMENTBY3D')
			.subscribe((text) => {
				this.typesList[3].text = text
			})
		this.translate
			.get('REPORT.CHECKOUT_TYPE_CARDLINKING')
			.subscribe((text) => {
				this.typesList[4].text = text
			})
		this.translate.get('REPORT.CHECKOUT_TYPE_REFUND').subscribe((text) => {
			this.typesList[5].text = text
		})
		this.translate.get('REPORT.CHECKOUT_TYPE_REVERSE').subscribe((text) => {
			this.typesList[6].text = text
		})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_INIT')
			.subscribe((text) => {
				this.statusList[1].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_PENDING')
			.subscribe((text) => {
				this.statusList[2].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_CANCELED')
			.subscribe((text) => {
				this.statusList[3].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_APPROVED')
			.subscribe((text) => {
				this.statusList[4].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_DECLINED')
			.subscribe((text) => {
				this.statusList[5].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_PROBLEM')
			.subscribe((text) => {
				this.statusList[6].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_REVERSED')
			.subscribe((text) => {
				this.statusList[7].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_REDIRECTTO3D')
			.subscribe((text) => {
				this.statusList[8].text = text
			})
		this.translate
			.get('REPORT.FILTER_CHECKOUT_STATUS_CLOSEDBYTIMEOUT')
			.subscribe((text) => {
				this.statusList[9].text = text
			})
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			Id: new UntypedFormControl(''),
			ExternalId: new UntypedFormControl(''),
			AppId: new UntypedFormControl(''),
			UserId: new UntypedFormControl(''),
			Status: new UntypedFormControl(''),
			ReferenceId: new UntypedFormControl(''),
			RRN: new UntypedFormControl(''),
			CardPan: new UntypedFormControl(''),
		})

		this.FilterForm.patchValue({
			['Status']: this.statusList[0].value,
		})
	}
}
