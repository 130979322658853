import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { map, first } from "rxjs/operators"
import { AuthenticationService } from "./authentication.service"
import { Cacheable, CacheBuster } from "ngx-cacheable"
import { Subject } from "rxjs"

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
const cacheBuster$ = new Subject<void>()
@Injectable()
export class AdministrationService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  // _______________   Dealer page

  // @Cacheable({
  // 	cacheBusterObserver: cacheBuster$
  // })
  getDictionaryFile(
    form,
    format
  ) {
    let _format = format == "pdf" ? 0 : 1
    return this.http.get(
      this.authService.basUrl +
      `/api/Entity/Export?pTypeKey=` +
      form.Type +
      `&pName=` +
      form.Name +
      `&pCodeStr=` +
      form.Code +
      `&pCodeNum=` +
      form.NrCode +
      `&pOrderBy=` +
      form.Sort +
      `&ExportType=` +
      _format,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    )
  }

  getDealerDatas(dealerId, page, itemsPerPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
        `/api/dealer/Gets?id=` +
        dealerId +
        `&page=` +
        page +
        `&items=` +
        itemsPerPage
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }
  // @CacheBuster({
  // 	cacheBusterNotifier: cacheBuster$
  // })

  saveDealerInfo(data) {
    let body = {
      Id: data.DealerId,
      Name: data.DealerName,
      // 'InstListId': data.License,
      // 'FiscCode': data.FiscalCode,
      Email: data.Email,
      Address: data.Address,
      PhoneNumber: data.PhoneNumber,
      AbleCreateNewDealer: data.Subdealer,
      // 'WorkMode': data.WorkMode,
      // 'MinAmount': data.MinSum * 100,
      // 'MaxAmount': data.MaxSum * 100,
      // 'CheckAmountLimit': data.BillLimit,
      // 'BalanceAmount': data.CurrentDeposit,
      // 'CheckBalance': data.DepositCheck,
      // 'DealerCommission': data.,
      // 'CheckDepositLimit': data.MinDepositCheck,
      // 'LimitDeposit': data.SumMinDeposit,
      // 'SendPaymentSummary': data.,
      // 'Status': data.Status
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/dealer/Save`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        map((response) => {
          //Save Dealer Info if token exist
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  getDealerInfo(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/dealer/Get?id=` + id)
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  // For get datas in dealer tree
  // getDealerDatas() {
  // 	return this.http.get<any>(this.authService.basUrl + `/api/dealer/Gets`)
  // 		.pipe(map(response => {
  // 			return response;
  // 		}));
  // }
  // For get info about dealer by clicked on name in tree

  // For get datas in dealer like list
  getDealerDatasAsList() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/dealer/Gets/all`)
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  saveDeposit(data) {
    let body = {
      DealerId: data.Id,
      Amount: data.ChangeAmount * 100,
      Note: "Test Deposit",
    }
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/dealer/deposit` + data.Action,
        body,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .pipe(
        map((response) => {
          //Save Dealer Info if token exist
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  // _____________ Users Page

  getUsersList(
    UserName,
    UserLogin,
    UserEmail,
    State,
    CurrentPage,
    ItemsPerPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
        `/api/User/Gets?Type=&UserLogin=` +
        UserLogin +
        `&UserName=` +
        UserName +
        `&UserEmail=` +
        UserEmail +
        `&Status=` +
        State +
        `&userRole=&UserStatus=&CurPage=` +
        CurrentPage +
        `&ItemPerPage=` +
        ItemsPerPage
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  getUserInfo(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/User/get/` + id)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  getMerchantInfo(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/get/` + id)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }
getJobTitles(){
  return this.http
  .get<any>(this.authService.basUrl + `/api/Merchant/GetJobs/`)
  .pipe(
    map((response) => {
      if (this.authService.checkTokenResponse()) {
        return response
      }
    })
  )
}
getBanks(){
  return this.http
  .get<any>(this.authService.basUrl + `/api/Merchant/GetBanks/`)
  .pipe(
    map((response) => {
      if (this.authService.checkTokenResponse()) {
        return response
      }
    })
  )
}
getBankBranch(bankKey){
  return this.http
  .get<any>(this.authService.basUrl + `/api/Merchant/GetBankBranch?bankKey=`+bankKey)
  .pipe(
    map((response) => {
      if (this.authService.checkTokenResponse()) {
        return response
      }
    })
  )
}
  getMerchantCommissions(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/Commissions/` + id)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }
  resendCredentials(uKey,mKey) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Merchant/ResendCredentials?uKey=` +   uKey+ `&mKey=`+mKey
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  getMerchantUsers(key) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Merchant/UsersGet?MerchantKey=` + key
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  //---------------- get a list of template 
  getMerchantTemplate(){
    return this.http
    .get<any>(this.authService.basUrl + `/api/templates/list`)
    .pipe(
      map((response) => {
        if (this.authService.checkTokenResponse()) {
          return response
        }
      })
    )
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
  })
  getLanguages() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/Languages`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }


  getTypes() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/UserType`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  getUser(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/UserGet?id=` + id, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  saveUserEdit(datas, merchantKey, userKey, rolesQuantity) {
    let RoleListAll = []
    for (let i = 0; i < rolesQuantity; i++) {
      RoleListAll.push({
        Key: datas["Key_" + i],
        IsOn: datas["Value_" + i],
      })
    }
    let Data_inside = {
      Id: userKey,
      UserName: datas.UserName,
      UserEMail: datas.UserEMail,
      UserMobile: datas.UserMobile,
      UserStatus: datas.Status,
      Type: datas.Type,
      Language: datas.Language,
      MerchantKey: merchantKey,
      UserTitle: datas.UserTitle,
    }
    let body = {
      Info: Data_inside,
      RoleList: RoleListAll,
    }
    console.log(RoleListAll)
    console.log(body)
    return this.http
      .post<any>(this.authService.basUrl + `/api/Merchant/UserModify`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
  })
  getRolesUser() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/User/AllRoles/`)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }
  @Cacheable({
    cacheBusterObserver: cacheBuster$,
  })
  getRolesMerchant() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/AllRoles/`)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  changeStatus(userId: string, status: string) {
    let body = new HttpParams()
    body = body.set("UserId", userId)
    body = body.set("Status", status)

    return this.http
      .post<any>(this.authService.basUrl + `/api/User/ChangeStatus`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  editMerchant(newUserDatas, key, code) {
    let body = {
      BankDetails: {
        AccountName: null,
        AccountNumber: null,
        BankBranch: null,
        BankCode: null,
        CountryId: null,
        CountryName: null,
        IBAN: null,
        TVA: null,
      },
      Member: {
        Email: null,
        JobTitle: null,
        JobTitleKey: null,
        MobileNumber: null,
        Name: null,
        Surname: null,
      },
      AcquiringParameters: {
        LinkOk: null,
        LinkCancel: null,
        LinkCallBack: null,
        TimeGoBack: null,
      },
      ContractNumber: null,
      Address: null,
      Description: null,
      IDNO: null,
      Id: null, // dlea save
      Key: key,
      Name: null,
      Status: newUserDatas.Status,
      Code: code,
      MerchantBankCode: null,
      MerchantBankID: null,
      PaymentSystem: null,
    }

    newUserDatas.forEach((newData) => {
      if (newData.Level.length === 2) {
        body[newData.Level[0]][newData.Level[1]] = newData.Value
      } else {
        body[newData.Key] = newData.Value
      }
    })
    return this.http
      .post<any>(this.authService.basUrl + `/api/Merchant/Update`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  editCommission(newUserDatas, commissionSettingsList, id, workStyle) {
    newUserDatas.forEach((element) => {
      delete element.Changed
    })
    // newUserDatas.forEach(newData => {
    // 	if (newData.Level.length === 2) {
    // 		body[newData.Level[0]][newData.Level[1]] = newData.Value;
    // 	}
    // 	else {
    // 		body[newData.Key] = newData.Value;
    // 	}
    // });
    let body = {
      Settings: newUserDatas,
      MerchantId: id,
      Installments: commissionSettingsList,
      WorkStyle: workStyle
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/Merchant/Commissions`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  saveImageMerchant(key, type, image) {
    let body = {
      Key: key,
      ImageData: image,
      MimeType: type,
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/Merchant/Logo`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  saveFileMerchant(key, name, type, image) {
    let body = {
      Key: key,
      ImageData: image,
      MimeType: type,
      TypeName: name,
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/Merchant/LoadContract`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  editUser(defaultDatas, newUserDatas, rolesQuantity) {
    let RoleList = []
    console.log(newUserDatas)
    for (let i = 0; i < rolesQuantity; i++) {
      RoleList.push({
        Key: newUserDatas["Key_" + i],
        IsOn: newUserDatas["Value_" + i],
      })
    }
    console.log(RoleList)
    let Info = {
      Language: defaultDatas.Info.Language,
      UserAuthType: defaultDatas.Info.UserAuthType,
      Id: defaultDatas.Info.Id,
      Login: defaultDatas.Info.Login,
      UserName: newUserDatas.UserName,
      UserEMail: newUserDatas.UserEmail,
      UserMobile: newUserDatas.UserMobile,
      UserStatus: newUserDatas.Status,
      Type: defaultDatas.Info.Type,
    }

    return this.http
      .post<any>(
        this.authService.basUrl + `/api/User/EditNew`,
        { Info, RoleList },
        { headers: { "Content-Type": "application/json" } }
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  merchantEdit(newUserDatas) {
    let body = new HttpParams()

    return this.http
      .post<any>(this.authService.basUrl + `/api/User/EditNew`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  getMerchantList(Code, Name,CardplusId, CurrentPage, ItemsPerPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
        `/api/Merchant/Gets?Code=` +
        Code +
        `&Name=` +
        Name +
        `&CardplusId=` +
        CardplusId +
        `&FetchCount=` +
        ItemsPerPage +
        `&FetchPage=` +
        CurrentPage +
        `&Status=`
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  changePassword(
    userId: string,
    new_password: string,
    confirm_password: string
  ) {
    let body = new HttpParams()
    body = body.set("Key", userId)
    body = body.set("Password", new_password)
    body = body.set("PasswordConfirm", confirm_password)
    // let body = {
    //     "UserId": userId,
    //     "PasswordNew": new_password,
    //     "PasswordConfirm": confirm_password
    // };
    // var parameters = JSON.stringify({ UserId: userId, PasswordNew: new_password, PasswordConfirm: password });

    return this.http
      .post<any>(this.authService.basUrl + `/api/User/PasswordSet`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return response
        })
      )
  }

  getCities() {
    return this.http
      .get<any>(
        this.authService.basUrl +
        `/api/Entries/GetCityList?CurrentPage=1&ItemsPerPage=1000`
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  getSalePoints() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Entries/GetSalePointList`)
      .pipe(
        map((response) => {
          return response
        })
      )
  }
  createUser(newUserDatas, rolesQuantity) {
    let RoleList = []
    for (let i = 0; i < rolesQuantity; i++) {
      RoleList.push({
        Key: newUserDatas["Key_" + i],
        IsOn: newUserDatas["Value_" + i],
      })
    }
    let Info = {
      Login: newUserDatas.UserLogin,
      UserName: newUserDatas.UserName,
      UserEMail: newUserDatas.UserEmail,
      UserMobile: newUserDatas.UserMobile,
      UserStatus: 1,
    }

    return this.http
      .post<any>(
        this.authService.basUrl + `/api/User/EditNew`,
        { Info, RoleList },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }
  createMerchant(newUserDatas) {
    console.log(newUserDatas)
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/User/EditNew`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  // Translate page

  getFirstDropdown() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Entity/GetObjTypes`)
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  generateDropdown(id) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Entity/GetSysTypeList?objType=` + id
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  getTranslates(form, page, pagination) {
    return this.http
      .get<any>(
        this.authService.basUrl +
        `/api/Entity/GetSimpleList?TypeKey=` +
        form.Type +
        `&Name=` +
        form.Name +
        `&CodeStr=` +
        form.Code +
        `&CodeNum=` +
        form.NrCode +
        `&OrderBy=` +
        form.Sort +
        `&CurrentPage=` +
        page +
        `&Items=` +
        pagination,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  newTranslate(form, type_key, editor_eng, editor_my, editor_ru) {
    let body = {
      Key: "",
      TypeKey: type_key,
      Code: form.Code,
      CodeNum: form.NrCode,
      NameDef: form.Name,
      DescriptionDef: form.Description,
      StringSave: [],
    }

    if (editor_eng.length) {
      body.StringSave.push({
        Name: form.English,
        Description: editor_eng,
        LanguageCode: "en",
      })
    } else {
      body.StringSave.push({
        Name: form.English,
        Description: form.En_Desc,
        LanguageCode: "en",
      })
    }
    if (editor_my.length) {
      body.StringSave.push({
        Name: form.Myanmar,
        Description: editor_my,
        LanguageCode: "tr",
      })
    } else {
      body.StringSave.push({
        Name: form.Myanmar,
        Description: form.My_Desc,
        LanguageCode: "tr",
      })
    }
    if (editor_ru.length) {
      body.StringSave.push({
        Name: form.Russian,
        Description: editor_ru,
        LanguageCode: "ru",
      })
    } else {
      body.StringSave.push({
        Name: form.Russian,
        Description: form.Ru_Desc,
        LanguageCode: "ru",
      })
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/Entity/SaveSimple`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  changeTranslate(form, type_key, keys_api, editor_eng, editor_my, editor_ru) {
    let body = {
      Key: form.Key,
      TypeKey: type_key,
      Code: form.Code,
      CodeNum: form.NrCode,
      NameDef: form.Name,
      DescriptionDef: form.Description,
      StringSave: [],
    }
    let api_k = {
      en: "",
      tr: "",
      ru: "",
    }
    keys_api.Strings.forEach((lang) => {
      api_k[lang.LanguageCode] = lang.Key
    })

    if (editor_eng.length) {
      body.StringSave.push({
        Key: api_k.en,
        SimpleKey: form.Key,
        Name: form.English,
        Description: editor_eng,
        LanguageCode: "en",
      })
    } else {
      body.StringSave.push({
        Key: api_k.en,
        SimpleKey: form.Key,
        Name: form.English,
        Description: form.En_Desc,
        LanguageCode: "en",
      })
    }
    if (editor_my.length) {
      body.StringSave.push({
        Key: api_k.tr,
        SimpleKey: form.Key,
        Name: form.Myanmar,
        Description: editor_my,
        LanguageCode: "tr",
      })
    } else {
      body.StringSave.push({
        Key: api_k.tr,
        SimpleKey: form.Key,
        Name: form.Myanmar,
        Description: form.My_Desc,
        LanguageCode: "tr",
      })
    }
    if (editor_ru.length) {
      body.StringSave.push({
        Key: api_k.ru,
        SimpleKey: form.Key,
        Name: form.Russian,
        Description: editor_ru,
        LanguageCode: "ru",
      })
    } else {
      body.StringSave.push({
        Key: api_k.ru,
        SimpleKey: form.Key,
        Name: form.Russian,
        Description: form.Ru_Desc,
        LanguageCode: "ru",
      })
    }

    return this.http
      .post<any>(this.authService.basUrl + `/api/Entity/SaveSimple`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  editServices(data, key) {
    let body = {
      CompanyServices: data,
    }
    body.CompanyServices.forEach((service) => {
      service["Key"] = key
    })
    return this.http
      .put<any>(this.authService.basUrl + `/api/provider/EditServices`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  openEditTranslate(key) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Entity/GetSimple?Key=` + key, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  getEditStrings(key) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Entity/GetString?SimpleKey=` + key,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  getServiceInfo(key) {
    return this.http
      .get<any>(
        this.authService.basUrl +
        `/api/provider/GetServicesDetails?pKey=` +
        key,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  saveImage(simple_key, type_key, lang, image) {
    let body = {
      SimpleKey: simple_key,
      TypeKey: type_key,
      MimeType: image.type,
      LangCode: lang,
      Base64Data: image.code,
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/Entity/SaveImage`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  getImage(key, type, lang) {
    return this.http
      .get<any>(
        this.authService.basUrl +
        `/api/Entity/GetImage?SimpleKey=` +
        key +
        `&pType=` +
        type +
        `&pLangCode=` +
        lang,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
  })
  takeListCountries() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/Countries`)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU"
      case "tr":
        return "tr-TR"
    }
    return "en-US"
  }
}