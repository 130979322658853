import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { first } from 'rxjs/operators'
import { saveAs } from 'file-saver'
import { DatePipe } from '@angular/common'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'
import { ReportsDatasService } from '../../../_services/reportsdata.service'
import { untilDestroyed } from 'ngx-take-until-destroy'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { Globals } from '../../../globals/globals'
import { LocalizedDatePipe } from '../../../_helpers/custom.datepipe'

@Component({
	templateUrl: './invoice-report.component.html',
	styleUrls: ['../invoice.scss'],
	providers: [DatePipe, Globals, LocalizedDatePipe],
})
export class InvoiceReportComponent implements OnInit, OnDestroy {
	mainChartLoad: boolean = false
	isDownloadingFile: boolean = false
	loader: boolean = false // Display dashboard when loading datas
	Status: 0
	FilterForm: UntypedFormGroup // Form initial
	FilterFormTable: UntypedFormGroup
	pageEvent // for fix error of pageEvent in Visual Studio

	displayedColumns: string[] = [
		'TargetInvoice',
		'Created',
		'Expires',
		'FullName',
		'Amount',
		'Fee',
		'PayDate',
		'Status',
	]
	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator

	currentTime = new Date()

	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1)
	totalItems
	loading: boolean = false

	// Date picker max date
	constructor(
		private reportsService: ReportsDatasService,
		public dialog: MatDialog,
		public translate: TranslateService,
		private datePipe: DatePipe,
		private router: Router,
		public toastr: ToastrService,
		private globals: Globals,
		private localizedDate: LocalizedDatePipe
	) {
		translate.onLangChange
			.pipe(untilDestroyed(this))
			.subscribe((event: LangChangeEvent) => {})
	}

	radioModel = 1 // standart radio value for month
	// mainChart
	public itemsPerPage = 10 // for display pagination from api
	public currentPage = 0
	public currentItem
	public mainChartElements: Number
	MerchantList = [{ id: '', Name: '' }]

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.itemsPerPage = e.pageSize
		this.getDatasTable(this.radioModel)
	}
	openConfirmationDialog(dialogName, row) {
		this.currentItem = row
		this.openModal(dialogName)
	}
	openModal(templateRef) {
		this.dialog.open(templateRef)
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	modalLoading: boolean = false

	download(format) {
		this.isDownloadingFile = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddT00:00:00'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddT23:59:59'
		)
		let filter_status = null
		if (this.FilterForm.value.Status !== 'null') {
			filter_status = this.FilterForm.value.Status
		}
		let merchId = null
		if (this.FilterForm.value.MerchantId !== 'null') {
			merchId = this.FilterForm.value.MerchantId
		}
		let file_name = 'InvoiceReport_' + finish
		if (format == 'pdf') {
			this.reportsService
				.GetsInvoiceHistoryByAdminPdf(
					start,
					finish,
					merchId,
					filter_status,
					this.radioModel
				)
				.subscribe(
					(res) => {
						this.isDownloadingFile = false
						saveAs(res, file_name + '.' + format, {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						})
					},
					(error) => {
						console.log(error)
						this.isDownloadingFile = false
					}
				)
		} else {
			this.reportsService
				.GetsInvoiceHistoryByAdminExcel(
					start,
					finish,
					merchId,
					filter_status,
					this.radioModel
				)
				.subscribe(
					(res) => {
						this.isDownloadingFile = false
						saveAs(res, file_name + '.' + format, {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						})
						console.log('res', res)
					},
					(error) => {
						console.log(error)
						this.isDownloadingFile = false
					}
				)
		}
	}

	getMonday(d) {
		d = new Date(d)
		var day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
		return new Date(d.setDate(diff))
	}
	MerchantsGets() {
		this.reportsService.getMerchantList().subscribe(
			(data) => {
				console.log(data)
				if (data.ResultCode === 0) {
					this.MerchantList = data.ReturnObject
					this.translate
						.get('REPORT.ALL_MERCHANTS_LBL')
						.subscribe((text) => {
							this.MerchantList.unshift({
								id: 'null',
								Name: text,
							})
						})
					this.FilterForm.patchValue({
						['MerchantId']: this.MerchantList[0].id,
					})
				}
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.getDatasTable(this.radioModel)
			}
		)
	}
	getDatasTable(interval) {
		this.loading = true
		this.radioModel = interval
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddT00:00:00'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddT23:59:59'
		)
		let filter_status = null
		if (this.FilterForm.value.Status !== 'null') {
			filter_status = this.FilterForm.value.Status
		}
		let merchId = null
		if (this.FilterForm.value.MerchantId !== 'null') {
			merchId = this.FilterForm.value.MerchantId
		}
		this.reportsService
			.GetsInvoiceHistoryByAdmin(
				start,
				finish,
				merchId,
				filter_status,
				this.FilterForm.value.InvoiceNumber,
				this.FilterForm.value.InvoiceEmail,
				this.itemsPerPage,
				this.currentPage + 1,
				interval
			)
			.subscribe(
				(data) => {
					this.totalItems = 0
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							let items = data.ReturnObject.InvoiceList
							this.dataSource = new MatTableDataSource(items) // array of datas
							this.totalItems = data.ReturnObject.Cnt
						}
					} else {
						this.totalItems = 0
						this.dataSource = new MatTableDataSource([]) // array of datas
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				},
				() => {
					this.loader = true
				}
			)
	}

	ngOnInit(): void {
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			MerchantId: new UntypedFormControl('', Validators.required),

			Merchant: new UntypedFormControl(''),
			InvoiceNumber: new UntypedFormControl(''),
			InvoiceEmail: new UntypedFormControl(''),
			Status: new UntypedFormControl(
				this.statusLists[0].value,
				Validators.required
			),
		})

		this.FilterFormTable = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
		})
		this.MerchantsGets()

		this.translate.get('INVOICE.STATUS_ALL').subscribe((text) => {
			this.statusLists[0].text = text
		})
		this.translate.get('INVOICE.STATUS_NEW').subscribe((text) => {
			this.statusLists[1].text = text
		})
		this.translate.get('INVOICE.STATUS_SEND').subscribe((text) => {
			this.statusLists[2].text = text
		})
		this.translate.get('INVOICE.STATUS_PAID').subscribe((text) => {
			this.statusLists[3].text = text
		})
		this.translate.get('INVOICE.STATUS_CANCEL').subscribe((text) => {
			this.statusLists[4].text = text
		})
		this.translate.get('INVOICE.STATUS_EXPIRED').subscribe((text) => {
			this.statusLists[5].text = text
		})
		this.translate.get('INVOICE.STATUS_REJECTED').subscribe((text) => {
			this.statusLists[6].text = text
		})
		this.translate.get('INVOICE.STATUS_REMOVE').subscribe((text) => {
			this.statusLists[7].text = text
		})
	}
	ngOnDestroy(): void {}
	statusLists = [
		{
			text: 'All',
			value: '',
		},
		{
			text: 'New',
			value: 0,
		},
		{
			text: 'Send',
			value: 1,
		},
		{
			text: 'Paid',
			value: 2,
		},
		{
			text: 'Cancel',
			value: 3,
		},
		{
			text: 'Expired',
			value: 4,
		},
		{
			text: 'Rejected',
			value: 5,
		},
		{
			text: 'Remove',
			value: 10,
		},
	]
}
