import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../../_services'

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs'

import { environment } from '../../../../environments/environment'
import { DataSource } from '@angular/cdk/table'
import { ReportsDatasService } from '../../../_services/reportsdata.service'
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { Globals } from '../../../globals/globals'
import { ToastrService } from 'ngx-toastr'

export interface Type {
	id: string
	Name: string
}
export interface Merchant {
	id: string
	Name: string
}

@Component({
	templateUrl: 'campaigns.component.html',
	styleUrls: ['../../reports/reports.scss', './campaigns.component.scss'],
	providers: [DatePipe, Globals],
})
export class CampaignsComponent implements OnInit {
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	permissions

	image_error = ''
	uploadNow = false // state when photo is uploading

	currentLang: string = ''
	LangKey: string = ''
	modalKey: string = ''
	createdDate: string = ''
	stringKey: string = ''
	editMerchantError = ''
	code: any
	type: any
	imgInfoLink: string = '/api/Campaign/Image/'
	cardList = []
	//radioModel = 1;
	activeTab = 0
	public currentPage = 0
	public categoryType: string = ''
	isLoadingDetailInfo: boolean = true // spinner
	isDownloadingFile: boolean = false // spinner
	Info
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	// For change pages
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	currentTime = new Date()
	allPageLoad: boolean = false

	MerchantList: Merchant[] = [{ id: '', Name: '' }]

	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		public translate: TranslateService,
		private reportsDataService: ReportsDatasService,
		public toastr: ToastrService,
		private datePipe: DatePipe,
		public route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private globals: Globals
	) {}
	baseUrl: string = environment.apiUrl
	FilterForm: UntypedFormGroup // Form initial
	appVersionsList = []

	rand: number = 0
	tabSelect: number = 0
	CurrentLang: string = ''
	Id: string = ''
	selected: number = 0
	appType = []
	appTypeInfo = []
	tabStatus = []
	appStatus = [
		{
			text: 'All',
			value: 0,
		},
		{
			text: 'Active',
			value: 1,
		},
		{
			text: 'InActive',
			value: 2,
		},
	]

	statusRow = [
		{
			text: 'ALL',
			value: '0',
			color: '',
		},
		{
			text: 'SUCCESS',
			value: '1',
			color: 'badge-success',
		},
		{
			text: 'BLOCKED',
			value: '2',
			color: 'badge-warning',
		},
	]

	LangList = [
		{
			text: 'tr',
			value: '69ee6082-77d3-417c-b6f7-c64ed1b5c691',
		},
		{
			text: 'en',
			value: 'ae10158f-17cd-4974-ad98-c15774b2b3e8',
		},
		{
			text: 'ru',
			value: 'a270d233-1c45-4550-b48f-68c7424303c2',
		},
	]
	EditForm: UntypedFormGroup
	bundleVersionsList = []

	filteredOptions: Observable<Merchant[]>
	displayFn(user?: Merchant): string | undefined {
		return user ? user.Name : undefined
	}

	CardsList() {
		this.allPageLoad = true
		this.getFirstDatas()
	}
	fillCardList() {
		this.allPageLoad = true
		this.CardsList()
	}

	tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
		this.activeTab = tabChangeEvent.index
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	changeCulture(templateRef, id, lang, tabSelect) {
		this.loading = true
		this.isLoadingDetailInfo = true
		this.isLoadingDetailInfo = false
		// this.dialog.open(templateRef, {});
		this.allPageLoad = true
		this.loading = false
		if (this.tabSelect === 0) {
			this.reportsDataService.getCampaignsInfo(id, lang).subscribe(
				(data) => {
					if (data !== null) {
						this.EditForm.patchValue({
							Name: data.Name,
							DescriptionShort: data.DescriptionShort,
							DescriptionFull: data.DescriptionFull,
							Lang: data.LangKey,
							Status: data.Status,
						})
						this.stringKey = data.StringKey
						this.imgInfoLink = this.imgInfoLink + data.Key
						this.modalKey = data.Key
						this.loading = false
					}
					this.isLoadingDetailInfo = false
				},
				(error) => {
					this.loading = false
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		}
	}
	openInfo(templateRef, id, CurrentLang) {
		this.loading = true
		this.isLoadingDetailInfo = true
		this.dialog.open(templateRef, {})
		this.allPageLoad = true
		this.loading = false
		this.tabSelect = 0

		if (id !== 0) {
			this.reportsDataService.getCampaignsInfo(id, CurrentLang).subscribe(
				(data) => {
					if (data !== null) {
						this.EditForm.patchValue({
							Name: data.Name,
							DescriptionShort: data.DescriptionShort,
							DescriptionFull: data.DescriptionFull,
							Lang: data.LangKey,
							Status: data.Status,
						})
						this.tabStatus = this.appStatus.filter((status) => {
							return status.text !== 'All'
						})
						this.CurrentLang = data.LangKey
						this.Id = data.Key
						this.stringKey = data.StringKey
						this.imgInfoLink = this.imgInfoLink + data.Key
						this.modalKey = data.Key
						this.createdDate = data.StartDate
						this.loading = false
					}
					this.isLoadingDetailInfo = false
				},
				(error) => {
					this.loading = false
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		}
	}
	openInfoNew(templateRef, id) {
		this.image_error = ''
		this.loading = true
		this.isLoadingDetailInfo = true
		this.dialog.open(templateRef, {})
		this.isLoadingDetailInfo = false
		this.loading = false
		this.tabSelect = 1

		this.tabStatus = this.appStatus.filter((status) => {
			return status.text !== 'All'
		})
		this.EditForm.patchValue({
			Name: '',
			DescriptionShort: '',
			DescriptionFull: '',
			Lang: 'ae10158f-17cd-4974-ad98-c15774b2b3e8',
			Status: 1,
		})
		this.modalKey = ''
		this.createdDate = ''
		this.stringKey = ''
		this.isLoadingDetailInfo = false
		this.loading = false
	}
	submit(e, modalKey, stringKey, codeImg, mimeType) {
		this.isLoadingDetailInfo = true

		this.reportsDataService
			.AddOrUpdateCard(
				this.EditForm.value.DescriptionFull,
				this.EditForm.value.DescriptionShort,
				this.EditForm.value.Name,
				modalKey,
				stringKey,
				codeImg,
				mimeType,
				this.EditForm.value.Lang,
				this.EditForm.value.Status
			)
			.subscribe(
				(data) => {
					//console.log("Data after send::", data);
					if (data !== null) {
						this.CloseDialog()
						let body = ''
						let theme = ''
						this.translate
							.get('CAMPAIGNS.BODY_EDITMERCHANT_SUCCESS')
							.subscribe((text) => {
								body = text
							}) // Text in component
						this.translate
							.get('CAMPAIGNS.THEME_EDITMERCHANT_SUCCESS')
							.subscribe((text) => {
								theme = text
							}) // Text in component
						this.toastr.success(body, theme)
						this.loading = false
						this.getFirstDatas()
					} else {
						this.loading = false
						data === null
							? (this.editMerchantError = '')
							: (this.editMerchantError = 'Error')
						let theme = ''
						this.translate
							.get('CAMPAIGNS.THEME_EDITMERCHANT_ERROR')
							.subscribe((text) => {
								theme = text
							}) // Text in component
						this.toastr.error(this.editMerchantError, theme)
					}
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	previewImage(event, rand) {
		// console.log("begin");
		let file = event.target.files[0]
		// console.log(file);

		this.uploadNow = true
		this.image_error = null
		if (event.target.files.length) {
			this.image_error = ''
			this.uploadNow = true
			if (file.type !== 'image/png' && file.type != 'image/jpeg') {
				this.translate
					.get('PROFILE.IMG_FORMAT_ERROR')
					.subscribe((text) => {
						this.image_error = text
					}) // Text in component
				this.uploadNow = false
				// console.log("file size error format")
			} else if (file.size > 524288) {
				this.translate
					.get('PROFILE.IMG_SIZE_ERROR')
					.subscribe((text) => {
						this.image_error = text
					}) // Text in component
				this.uploadNow = false
				// console.log("file size big")
			} else {
				this.type = file.type
				let reader: any
				target: EventTarget
				reader = new FileReader()

				reader.readAsDataURL(event.target.files[0]) // read file as data url
				reader.onload = (event) => {
					// called once readAsDataURL is completed
					this.code = event.target.result.split(',')[1]
					let body = ''
					let theme = ''
					this.translate
						.get('CAMPAIGNS.BODY_LOGO_SUCCESS')
						.subscribe((text) => {
							body = text
						}) // Text in component
					this.translate
						.get('CAMPAIGNS.THEME_LOGO_SUCCESS')
						.subscribe((text) => {
							theme = text
						}) // Text in component
					this.toastr.success(body, theme)

					this.uploadNow = false
					;(<HTMLImageElement>(
						document.getElementById('preview_merchant')
					)).src = 'data:' + this.type + ';base64,' + this.code

					//);
					// console.log("file size success")
				}
			}
		}
	}

	GetLang() {
		this.reportsDataService.GetLangList().subscribe(
			(data) => {},
			(error) => {
				this.loading = false
				console.log(error)
			}
		)
	}

	getFirstDatas() {
		this.rand = Math.floor(Math.random() * Math.floor(100))
		this.loading = true
		this.code = ''
		let currentPage = this.currentPage + 1
		let filter_status = null
		let filter_text = null

		if (this.FilterForm.value.Status !== 'null') {
			filter_status = this.FilterForm.value.Status
		}

		if (this.FilterForm.value.Text !== 'null') {
			filter_text = this.FilterForm.value.Text
		}

		if (localStorage.getItem('currentLang')) {
			this.currentLang = localStorage.getItem('currentLang')
		} else {
			this.currentLang = this.translate.getBrowserLang()
		}

		if (filter_text) {
			currentPage = 1
		}
		this.reportsDataService
			.getCampaigns(
				filter_status,
				filter_text,
				currentPage,
				this.paginationItemPerPage,
				this.currentLang
			)
			.subscribe(
				(data) => {
					console.log(data)
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.cardList = data.ReturnObject.Items
							this.cardList.forEach((element) => {
								if (
									element.Name !== null &&
									element.Name.length >= 25
								) {
									let str = element.Name.slice(0, 25)
									element.Name = `${str}...`
								}
							})
							this.paginationItems = data.ReturnObject.Fetch.Cnt
							this.LangKey = data.ReturnObject.Items[0].LangKey
							// this.CurrentLang = data.ReturnObject[1].LangKey;
							//console.log( this.CurrentLang);
						}
						this.loading = false
					} else {
						this.cardList = []
						this.loading = false
					}
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}
	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas()
	}

	ngOnInit() {
		let ls = new SecureLS() // for Secure Local storage
		if (ls.get('info') !== null) {
			this.Info = ls.get('info')
		}
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]

		this.translate.get('CAMPAIGNS.FILTER_STATUS_ALL').subscribe((text) => {
			this.appStatus[0].text = text
		})
		this.translate
			.get('CAMPAIGNS.FILTER_STATUS_ACTIVE')
			.subscribe((text) => {
				this.appStatus[1].text = text
			})
		this.translate
			.get('CAMPAIGNS.FILTER_STATUS_INACTIVE')
			.subscribe((text) => {
				this.appStatus[2].text = text
			})
		this.translate.get('CAMPAIGNS.STATUSROW_SUCCESS').subscribe((text) => {
			this.statusRow[1].text = text
		})

		this.translate.get('CAMPAIGNS.STATUSROW_BLOCKED').subscribe((text) => {
			this.statusRow[2].text = text
		})

		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			AppVersion: new UntypedFormControl(''),
			BundleVersion: new UntypedFormControl(''),
			Ratings: new UntypedFormControl(null),
			UserName: new UntypedFormControl(''),
			OperId: new UntypedFormControl(''),
			appType: new UntypedFormControl(''),
			Status: new UntypedFormControl(''),
			Text: new UntypedFormControl(''),
		}) // for header Filter
		this.fillCardList()

		this.EditForm = new UntypedFormGroup({
			Name: new UntypedFormControl(''),
			DescriptionShort: new UntypedFormControl(''),
			DescriptionFull: new UntypedFormControl(''),
			Lang: new UntypedFormControl(''),
			Status: new UntypedFormControl(''),
		})
	}
}
