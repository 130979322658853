import { Injectable } from '@angular/core'
import { AuthenticationService } from './authentication.service'
import { HttpClient, HttpParams } from '@angular/common/http'
import { ApiNotificationFilter } from '../_models/api-notification.model'
import { map } from 'rxjs/operators'

@Injectable()
export class ApinotificationService {
	constructor(
		private http: HttpClient,
		private authService: AuthenticationService
	) {}

	getApiNotificationList(OUT: ApiNotificationFilter) {
		return this.http
			.get<any>(
				this.authService.basUrl + `/api/notification/GetNotifications`,
				{
					params: new HttpParams()
						.set('notificationType', OUT.NotificationType)
						.set('applcationType', OUT.AppType)
						.set('state', OUT.Status)
						.set('toUser', OUT.SearchHeader)
						.set('toType', OUT.CustomerType)
						.set('header', OUT.Header)
						.set('pStartDate', OUT.Date.DateFrom)
						.set('pEndDate', OUT.Date.DateTo)
						.set('itemPerPage', OUT.ItemsPerPage)
						.set('currentPage', OUT.CurrentPage)
						.set('pPeriodType', OUT.PeriodType),
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	exportExcel(OUT: ApiNotificationFilter) {
		return this.http.get(
			this.authService.basUrl +
				`/api/notification/DownloadNotificationExcel`,
			{
				params: new HttpParams()
					.set('notificationType', OUT.NotificationType)
					.set('applcationType', OUT.AppType)
					.set('state', OUT.Status)
					.set('toUser', OUT.SearchHeader)
					.set('toType', OUT.CustomerType)
					.set('header', OUT.Header)
					.set('pStartDate', OUT.Date.DateFrom)
					.set('pEndDate', OUT.Date.DateTo)
					.set('pPeriodType', OUT.PeriodType)
					.set('itemPerPage', OUT.ItemsPerPage)
					.set('currentPage', OUT.CurrentPage),
				responseType: 'blob',
				headers: {
					'Accept-Language': this.GetLang(),
				},
			}
		)
	}

	private GetLang() {
		switch (localStorage.getItem('currentLang')) {
			case 'ru':
				return 'ru-RU'
			case 'tr':
				return 'tr-TR'
		}
		return 'en-US'
	}
}
