import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "AmountPipe",
})
export class AmountPipe implements PipeTransform {
  transform(value: number, currency: string = ""): string {
    if (currency === undefined) currency = "";
    let newValue = (value / 100).toLocaleString(
      "en-US", // leave undefined to use the browser's locale,
      // or use a string like 'en-US' to override it.
      { minimumFractionDigits: 2 }
    );
    return newValue + " " + currency;
  }
}
