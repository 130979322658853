import { NgModule }      from '@angular/core';
import { AmountPipe } from './amount.pipe';
import { CommonModule } from '@angular/common';
import { AmountPipeEx } from './amountEx.pipe';
import { LocalizedDatePipe } from "./custom.datepipe";

@NgModule({
  declarations: [AmountPipe, AmountPipeEx, LocalizedDatePipe],
  imports: [CommonModule],
  providers: [AmountPipe, AmountPipeEx],
  exports: [AmountPipe, AmountPipeEx, LocalizedDatePipe],
})
export class CustomPipeModule {} 