import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class PaymentService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}
  getProductPage(serviceId: number) {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    return this.http
      .get<any>(this.authService.basUrl + `/api/payment/start/` + serviceId, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
        })
      );
  }

  checkPayment(serviceId: number, subService, items) {
    let body = {
      SubService: subService,
      Items: items,
    };
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/payment/check/` + serviceId,
        body,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  sendPayment(serviceId: number, data) {
    let body = data;
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/payment/pay/` + serviceId,
        body,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  downloadPayment(refstan) {
    return this.http.get(
      this.authService.basUrl + `/api/payment/TransactionPdf/` + refstan,
      {
        headers: { "Accept-Language": this.GetLang() },
        responseType: "blob",
      }
    );
  }
  getCancelPayments(dealerId, dateFrom, dateTo, curPage, itemPerPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/CancelTransactions?DateFrom=` +
          dateFrom +
          "&DateTo=" +
          dateTo +
          "&Account&UserName&DealerId=" +
          dealerId +
          "&CompanyId&Status&RefStan&CurPage=" +
          curPage +
          "&ItemPerPage=" +
          itemPerPage
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
