import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms"
import { Observable } from "rxjs"
import { map, startWith, first } from "rxjs/operators"
import { AlertService, UserService, ProviderService } from "../../../_services"
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator"
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table"
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog"
import { DataSource } from "@angular/cdk/table"
import { ReportsDatasService } from "../../../_services/reportsdata.service"
import { DatePipe } from "@angular/common"
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls"

import { CustomersService } from "../../../_services/customers.service"
import { TransactionService } from "../../../_services/transaction.service"
import { TranslateService } from "@ngx-translate/core"
import { saveAs } from "file-saver"
import { ToastrService } from "ngx-toastr"
import { Globals } from "../../../globals/globals"

import { ActivatedRoute } from "@angular/router"
import { $ } from "protractor"

@Component({
  templateUrl: "./operations-log.component.html",
  styleUrls: ["../../transactions/transactions.scss", "./operations-log.scss"],
  providers: [DatePipe, Globals],
})
export class OperationsLogComponent implements OnInit {
  public paginationItems // for display pagination from api
  public paginationItemPerPage // for display pagination from api
  allPageLoad: boolean = true
  isDownloadingFile: boolean = false
  permissions

  radioModel = 1
  public currentPage = 0
  isLoadingDetailInfo: boolean = true // spinner
  Info
  loading = false // for disable fast response click
  pageEvent // for fix error of pageEvent in Visual Studio
  panelOpenState = true
  // For change pages
  minDate = new Date(2000, 0, 1) // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
  events: string[] = [] // Date picker
  currentTime = new Date()
  AccountCodes

  displayedColumns: string[] = [
    "CustomerNumber",
    "PhoneNumber",
    "IdNumber",
    "Date",
    "Action",
    "Error",
    "Device",
    "Status",
  ]

  dataSource = new MatTableDataSource() // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private datePipe: DatePipe,
    private transactionService: TransactionService,
    private customersService: CustomersService,
    public toastr: ToastrService,
    private globals: Globals,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {}

  FilterForm: UntypedFormGroup // Form initial

  CloseDialog(): void {
    this.dialog.closeAll()
  }
  public Turnover = {
    BeginBalance: 0,
    EndBalance: 0,
    TotalExpenses: 0,
    TotalReceipt: 0,
  }

  showError(event) {
    var element = event.target
    element.classList.toggle("error_desc")
  }

  getFirstDatas(period) {
    this.loading = true
    this.radioModel = period
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    )
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    )
    let _ff = this.FilterForm.value
    this.customersService
      .getsOperationLog(
        start,
        finish,
        period,
        _ff.CustomerNumber,
        _ff.PhoneNumber,
        _ff.StatusType,
        _ff.Error,
        _ff.IdCardNumber,
        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .subscribe(
        (data) => {
          let datas = []
          this.paginationItems = 0

          if (data && data.ReturnObject !== null) {
            datas = data.ReturnObject.Customers
            this.paginationItems = data.ReturnObject.Pagination.TotalItems
          } else {
            datas = []
            this.paginationItems = 1
          }
          this.dataSource = new MatTableDataSource(datas) // array of datas
          this.loading = false
        },
        (error) => {
          this.loading = false
        },
        () => {
          this.allPageLoad = true
        }
      )
  }

  download(format) {
    if (this.loading === false && this.isDownloadingFile === false) {
      this.isDownloadingFile = true
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      )
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      )
      let _ff = this.FilterForm.value

      this.isDownloadingFile = true
      this.customersService
        .getsOperationLogFile(
          start,
          finish,
          this.radioModel,
          _ff.CustomerNumber,
          _ff.PhoneNumber,
          _ff.Error,
          _ff.IdCardNumber,
          format
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false
            saveAs(
              res,
              "OperationLogReport_" +
                this.datePipe.transform(
                  this.FilterForm.controls.dateTo.value,
                  "yyyy-MM-dd"
                ) +
                "." +
                format,
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }
            )
          },
          (error) => {
            console.log(error)
            this.isDownloadingFile = false
          }
        )
    }
  }

  statusLists = [
    {
      text: "All",
      value: "null",
    },
    {
      text: "Blocked",
      value: 0,
    },
    {
      text: "SUCCESS",
      value: 1,
    },
  ]

  statusRow = [
    {
      text: "BLOCKED",
      value: 0,
      color: "badge-warning",
    },
    {
      text: "SUCCESS",
      value: 1,
      color: "badge-success",
    },
  ]

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex
    this.paginationItemPerPage = e.pageSize
    this.getFirstDatas(this.radioModel)
  }

  ngOnInit() {
    this.paginationItemPerPage = 10
    let ls = new SecureLS() // for Secure Local storage

    this.permissions = ls.get("permissions")[this.route.data["value"].pathCheck]

    this.translate.get("OPERATION_LOG.STATUS_ALL").subscribe((text) => {
      this.statusLists[0].text = text
    })

    this.translate.get("OPERATION_LOG.STATUS_BLOCKED").subscribe((text) => {
      this.statusLists[1].text = text
    })
    this.translate.get("OPERATION_LOG.STATUS_SUCCESS").subscribe((text) => {
      this.statusLists[2].text = text
    })

    this.translate.get("OPERATION_LOG.STATUSROW_BLOCKED").subscribe((text) => {
      this.statusRow[0].text = text
    })

    this.translate.get("OPERATION_LOG.STATUSROW_SUCCESS").subscribe((text) => {
      this.statusRow[1].text = text
    })

    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      PhoneNumber: new UntypedFormControl(""),
      CustomerNumber: new UntypedFormControl(""),
      IdCardNumber: new UntypedFormControl(""),
      Error: new UntypedFormControl(""),
      StatusType: new UntypedFormControl(""),
    })

    this.FilterForm.patchValue({
      ["StatusType"]: this.statusLists[0].value,
    })

    this.getFirstDatas(this.radioModel)
  }
}
