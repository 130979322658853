import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../_services";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DataSource } from "@angular/cdk/table";
import { ReportsDatasService } from "../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TransactionService } from "../../_services/transaction.service";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { Globals } from "../../globals/globals";

@Component({
  templateUrl: "refunds-report.component.html",
  styleUrls: ["transactions.scss"],
  providers: [DatePipe, Globals],
})
export class RefundsReportComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private reportsDataService: ReportsDatasService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private transactionService: TransactionService,
    private formBuilder: UntypedFormBuilder,
    private globals: Globals
  ) {}
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  allPageLoad: boolean = false;
  isDownloadingFile: boolean = false;
  modalDatas = {
    RegDate: "",
    ExternalId: "",
    CurrencyCode: "",
    Id: "",
    Status: 0,
    Fee: 0,
    Amount: 0,
    MerchantName: "",
    MoneyProviderName: "",
    Note: "",
  };
  radioModel = 1;
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  Info;
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  AccountCodes;

  displayedColumns: string[] = [
    "MerchantID",
    "MerchantName",
    "CardPlusMerchantID",

    "ReturnDate",
    "PaymentDate",
    "ExternalIdStr",
    "PartnerAmount",
    "RefundAmount",
    "Fee",
    "Reason",
    "Type",
  ];
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  FilterForm: UntypedFormGroup; // Form initial
  RefundForm: UntypedFormGroup;

  CloseDialog(): void {
    this.dialog.closeAll();
  }
  makePDF(format) {
    if (this.loading === false && this.isDownloadingFile === false) {
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );
      this.isDownloadingFile = true;
      this.transactionService
        .getRefundPdf(
          start,
          finish,
          this.radioModel,
          this.FilterForm.value.OrderID,
          this.FilterForm.value.Merchant,
          this.FilterForm.value.MerchantCode,
          this.FilterForm.value.CPMerchantID,
          format
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(
              res,
              "RefundReport_" +
                this.datePipe.transform(
                  this.FilterForm.controls.dateTo.value,
                  "yyyy-MM-dd"
                ) +
                "." +
                format,
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }
            );
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  getFirstDatas(period) {
    this.loading = true;
    this.radioModel = period;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    this.transactionService
      .getRefundsReport(
        start,
        finish,
        period,
        this.FilterForm.value.OrderID,
        this.FilterForm.value.Merchant,
        this.FilterForm.value.MerchantCode,
        this.FilterForm.value.CPMerchantID,
        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .subscribe(
        (data) => {
          console.log(data);
          let datas = [];
          this.paginationItems = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Orders;
              this.paginationItems = data.ReturnObject.Fetch.Cnt;
            }
          }
          this.dataSource = new MatTableDataSource(datas); // array of datas
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
        }
      );
  }

  // openDetailsModal(templateRef, Id) {
  //     this.isLoadingDetailInfo = true;
  //     this.RefundForm.reset();
  //     const dialogRef = this.dialog.open(templateRef, {
  //         panelClass: 'modal_transaction',
  //     });
  //     this.transactionService.getAdvanceTransaction(Id)
  //         .pipe(first())
  //         .subscribe(
  //             data => {
  //                 console.log(data);
  //                 if (data.ResultCode === 0) {
  //                     this.modalDatas = data.ReturnObject;
  //                     this.RefundForm.removeControl('Amount');
  //                     this.RefundForm.addControl('Amount', new FormControl((this.modalDatas.Amount / 100).toFixed(2), [Validators.required, Validators.min(1), Validators.max(this.modalDatas.Amount / 100)])); // Initialize form rules
  //                     if (this.modalDatas.Status !== 3) {
  //                         this.RefundForm.disable();
  //                     }
  //                     else {
  //                         this.RefundForm.enable();
  //                     }
  //                 }
  //                 else {
  //                     this.CloseDialog();
  //                 }
  //                 this.isLoadingDetailInfo = false;
  //             },
  //             error => {
  //                 this.isLoadingDetailInfo = false;
  //                 console.log(error);
  //             });
  // }



  refundStatusList = [
    {
      text: "Full",
      value: 0,
      color: "badge-info",
    },
    {
      text: "Partial",
      value: 1,
      color: "badge-warning",
    },
  ];

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }
  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }
  MerchantsGets() {
    this.reportsDataService.getMerchantList().subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.merchantList = data.ReturnObject;
          }
        }
        this.translate.get("REPORT.FILTER_MERCHANT_ALL").subscribe((text) => {
          this.merchantList.unshift({
            id: "",
            Name: text,
            Description: "",
            Key: "",
          });
        });
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      },
      () => {
        this.FilterForm.patchValue({
          ["Merchant"]: this.merchantList[0].id,
        });
        this.allPageLoad = true;
        this.getFirstDatas(this.radioModel);
      }
    );
  }
  ngOnInit() {
    // console.log(this.permissions);
    this.paginationItemPerPage = 50;

    let ls = new SecureLS(); // for Secure Local storage
    if (ls.get("info") !== null) {
      this.Info = ls.get("info");
    }

    this.translate.get("REPORT.REFUND_FULL_TEXT").subscribe((text) => {
      this.refundStatusList[0].text = text;
    });
    this.translate.get("REPORT.REFUND_PARTIAL_TEXT").subscribe((text) => {
      this.refundStatusList[1].text = text;
    });

    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      OrderID: new UntypedFormControl(""),
      Merchant: new UntypedFormControl(""),
      MerchantCode: new UntypedFormControl(""),
      CPMerchantID: new UntypedFormControl(""),
    }); // for header Filter

    this.RefundForm = this.formBuilder.group({
      Amount: [null, [Validators.required, Validators.min(1)]],
    });
    this.MerchantsGets();
  }
  public merchantList: Merchant[] = [];
}
interface Merchant {
  Key: string;
  id: number | string;
  Name: string;
  Description: string;
}