import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, first } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { Subject } from "rxjs";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
@Injectable()
export class ProfileService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // Profile page on settings
  getProfile() {
    console.log("profile-get", this.authService.basUrl);

    return this.http
      .get<any>(this.authService.basUrl + `/api/Profile/Get`, {
        headers: {
          "Accept-Language": this.GetLang(),
          Origin: "https://cardplus-admin-test.paypoint.md",
        },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  profileChangePassword(
    old_password: string,
    new_password: string,
    confirm_password: string
  ) {
    let body = new HttpParams();

    body = body.set("PasswordOld", old_password);
    body = body.set("Password", new_password);
    body = body.set("PasswordConfirm", confirm_password);
    // let body = {
    //     "UserId": userId,
    //     "PasswordNew": new_password,
    //     "PasswordConfirm": confirm_password
    // };
    // var parameters = JSON.stringify({ UserId: userId, PasswordNew: new_password, PasswordConfirm: password });

    return this.http
      .post<any>(this.authService.basUrl + `/api/Profile/PasswordSet`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Accept-Language": this.GetLang(),
          Origin: "https://cardplus-admin-test.paypoint.md",
        },
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return response;
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
