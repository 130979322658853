import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, first } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { Cacheable, CacheBuster } from "ngx-cacheable";
import { Subject } from "rxjs";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
@Injectable()
export class TransactionService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // _______________   Dealer page

  getAccountCodes() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Accounting/Gets`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getStatementsDatas(
    AccountCode,
    period,
    DateStart,
    DateFinish,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Accounting/Statement?AccountCode=` +
          AccountCode +
          `&DateStart=` +
          DateStart +
          `&DateFinish=` +
          DateFinish +
          `&PeriodType=` +
          period +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getStatementsPDF(
    AccountCode,
    DateStart,
    DateFinish,
    period,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http.get(
      this.authService.basUrl +
        `/api/Accounting/StatementToPdf?AccountCode=` +
        AccountCode +
        `&DateStart=` +
        DateStart +
        `&DateFinish=` +
        DateFinish +
        `&PeriodType=` +
        period +
        `&FetchCount=` +
        ItemsPerPage +
        `&FetchPage=` +
        CurrentPage,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getTransactionReport(
    DateStart,
    DateFinish,
    period,
    payment_type,
    amount,
    orderid,
    ItemsPerPage,
    CurrentPage,
    Status
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Ecom/GetReport?DateStart=` +
          DateStart +
          `&DateEnd=` +
          DateFinish +
          "&OrderId=" +
          orderid +
          `&MoneyProviderId=` +
          payment_type +
          `&PeriodType=` +
          period +
          `&Amount=` +
          amount +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage +
          `&Status=` +
          Status,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  //Statement

  getsStatements(
    start,
    finish,
    period,
    ordernumber,
    amount,
    merchant,
    paytype,
    productType,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Statement/Gets?pStartDate=${start}&pEndDate=${finish}&PeriodType=${period}&pOrderNumber=${ordernumber}&pAmount=${amount}&pMerchantId=${merchant}&pPaymentMethod=${paytype}&pOperationType=${productType}&pFetchPage=${CurrentPage}&pFetchCount=${ItemsPerPage}`,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getsStatementFile(
    start,
    finish,
    period,
    ordernumber,
    amount,
    merchant,
    paytype,
    productType,
    format
  ) {
    let _format = format == "pdf" ? 0 : 1;
    return this.http.get(
      this.authService.basUrl +
        `/api/Statement/Export?pStartDate=${start}&pEndDate=${finish}&PeriodType=${period}&pOrderNumber=${ordernumber}&pAmount=${amount}&pMerchantId=${merchant}&pPaymentMethod=${paytype}&pOperationType=${productType}&ExportType=${_format}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  // Orders
  getsOrderHistory(
    DateStart,
    DateFinish,
    period,
    code,
    phone,
    status,
    productType,
    paymethod,
    merchant,
    salePaymentType,
    ItemsPerPage,
    CurrentPage
  ) {
    let _merchant = merchant === "null" ? "" : merchant;
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Order/Gets?pStartDate=${DateStart}&pEndDate=${DateFinish}&PeriodType=${period}&pCode=${code}&pPhoneNumber=${phone}&pStatus=${status}&pProductType=${productType}&pPaymentMethod=${paymethod}&pMerchantId=${_merchant}&FetchPage=${CurrentPage}&FetchCount=${ItemsPerPage}&SalePaymentType=${salePaymentType === 0? null:  salePaymentType}`,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getsOrderHistoryFile(
    DateStart,
    DateFinish,
    period,
    code,
    phone,
    status,
    productType,
    paymethod,
    merchant,
    salePaymentType,
    format
  ) {
    let _merchant = merchant === "null" ? "" : merchant;
    let _format = format == "pdf" ? 0 : 1;
    return this.http.get(
      this.authService.basUrl +
        `/api/Order/Export?pStartDate=${DateStart}&pEndDate=${DateFinish}&PeriodType=${period}&pCode=${code}&pMobilePhone=${phone}&pStatus=${status}&pProductType=${productType}&pPaymentMethod=${paymethod}&pMerchantId=${_merchant}&ExportType=${_format}&SalePaymentType=${salePaymentType === 0? null:  salePaymentType}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getMoneyProviders() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Acquiring/ProviderList`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getMerchants() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/List`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getAdvanceTransaction(id) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Ecom/GetDetail?OperationId=` + id,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getOrderItem(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Order/Get?pOrderNumber=` + id, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getAdvanceWithdraw(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Withdraw/Get/` + id, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getTransactionsFile(
    DateStart,
    DateFinish,
    period,
    payment_type,
    amount,
    orderid,
    ItemsPerPage,
    CurrentPage,
    Status,
    format
  ) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/Ecom/ExportPdf?DateStart=` +
          DateStart +
          `&DateEnd=` +
          DateFinish +
          "&OrderId=" +
          orderid +
          `&MoneyProviderId=` +
          payment_type +
          `&PeriodType=` +
          period +
          `&Amount=` +
          amount +
          `&Status=` +
          Status,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    } else {
      return this.http.get(
        this.authService.basUrl +
          `/api/Ecom/ExportExcel?DateStart=` +
          DateStart +
          `&DateEnd=` +
          DateFinish +
          "&OrderId=" +
          orderid +
          `&MoneyProviderId=` +
          payment_type +
          `&PeriodType=` +
          period +
          `&Amount=` +
          amount +
          `&Status=` +
          Status,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
  }

  getRefundsReport(
    start,
    finish,
    period,
    orderId,
    mercId,
    merchCode,
    CPMerchID,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Refund/Gets?pStartDate=` +
          start +
          `&pEndDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&pOrderId=` +
          orderId +
          `&pMerchantID=${mercId}&pCardPlusMerchantID=${CPMerchID}&pMerchantCode=${merchCode}` +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getRefundPdf(
    start,
    finish,
    period,
    orderId,
    merchId,
    merchCode,
    CPMerchID,
    format
  ) {
    let _format = format == "pdf" ? 0 : 1;
    return this.http.get(
      this.authService.basUrl +
        `/api/Refund/Export?pStartDate=` +
        start +
        `&pEndDate=` +
        finish +
        `&PeriodType=` +
        period +
        `&pOrderId=` +
        orderId +
        `&pMerchantID=${merchId}&pCardPlusMerchantID=${CPMerchID}&pMerchantCode=${merchCode}` +
        `&ExportType=${_format}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  makeRefund(id, type, amount, comment) {
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/Refund/RefundRequest`,
        {
          RefundType: type,
          OperationId: id,
          Amount: amount,
          Comment: comment,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "en":
        return "en-US";
      case "ru":
        return "ru-RU";
    }
    return "tr-TR";
  }
}
