import { Component, HostListener, OnInit, Input } from "@angular/core";
import { navItems } from "./../../_nav";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import * as screenfull from "screenfull";
import { MainService } from "../../_services/main.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import * as SecureLS from "secure-ls";
import { Title } from "@angular/platform-browser";
import { DateAdapter } from "@angular/material/core";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  mobileSidebarToggler;
  currentLang;
  dropdown1;
  dropdown2;
  dropdown3;
  dropdown4;
  dropdown5;
  dropdown6;
  dropdown7;

  constructor(
    public translate: TranslateService,
    private mainService: MainService,
    public route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private dateAdapter: DateAdapter<any>
  ) {
    if (localStorage.getItem("currentLang")) {
      this.currentLang = localStorage.getItem("currentLang");
    } else {
      this.currentLang = translate.getBrowserLang();
    }
    translate.addLangs(["en", "tr", "ru"]);
    const browserLang = this.currentLang; // This variable change lang
    translate.use(browserLang.match(/en|tr|ru/) ? browserLang : "tr");
   
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      localStorage.setItem("currentLang", this.currentLang);
      this.dateAdapter.setLocale(this.currentLang);
      console.log(this.currentLang);
    });
  }

  // Variables
  permissions = {};

  Info;
  showMenu: boolean = false;
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  // End Variables
  getCurrentTranslateRoute() {
    let str = this.titleService.getTitle().match(/.+\|/)[0].slice(0, -2),
      dms = "Cardplus Cep Console";
    this.translate.get(`GLOBAL.TITLE_CPADMIN`).subscribe((text) => {
      dms = text;
    });
    str.match(/GLOBAL\./)
      ? this.translate.get(str).subscribe((text) => {
          str = text;
        })
      : str;
    this.setTitle(`${str}  | ${dms}`);

    return str;
  }
  toggleFull() {
    if (screenfull && screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  getUserDatas() {
    this.mainService
      .getProductPage()
      .pipe()
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.Info = data.ReturnObject;
            console.log(this.Info);
          }
        },
        (error) => {
          console.log(error);
          this.showMenu = false;
        },
        () => {
          this.getRoles();
        }
      );
  }

  getRoles() {
    let ls = new SecureLS(); // for Secure Local storage
    this.mainService
      .getRoles()
      .pipe()
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            ls.set("permissions", data.ReturnObject);
          }
        },
        (error) => {
          console.log(error);
          this.showMenu = false;
        },
        () => {
          if (ls.get("permissions") !== null) {
            this.permissions = ls.get("permissions");
            this.showMenu = true;
            let path = this.route.children[0].data["value"].pathCheck;
            if (
              typeof this.permissions[path] === "undefined" &&
              typeof path !== "undefined"
            ) {
              this.router.navigate(["/dashboard"]);
            }
          } else {
            this.router.navigate(["/login"]);
          }
        }
      );
  }

  ngOnInit() {
    this.getUserDatas();
  }
}
