import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { Cacheable, CacheBuster } from "ngx-cacheable";
import { Subject } from "rxjs";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
const cacheBuster$ = new Subject<void>();

@Injectable()
export class ReportsDatasService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  //Sales
  GetsSales(
    start,
    finish,
    period,
    code,
    username,
    merchant,
    status,
    type,
    phone,
    itemsPerPage,
    currPage
  ) {
    let body = {};
    return this.http
      .get<any>(
        `${this.authService.basUrl}/api/Sale/Gets?pStartDate=${start}&pEndDate=${finish}&pCode=${code}&pUserName=${username}&pStatus=${status}&pMerchantKey=${merchant}&pType=${type}&pPhoneNumber=${phone}&PeriodType=${period}&FetchPage=${currPage}&FetchCount=${itemsPerPage}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  GetsSalesPdf(
    start,
    finish,
    period,
    code,
    username,
    merchant,
    status,
    type,
    phone,
    itemsPerPage,
    currPage
  ) {
    return this.http.get(
      `${this.authService.basUrl}/api/Sale/Export?pStartDate=${start}&FetchCount=${itemsPerPage}&FetchPage=${currPage}&pEndDate=${finish}&pCode=${code}&pUserName=${username}&pStatus=${status}&pMerchantKey=${merchant}&pType=${type}&pPhoneNumber=${phone}&PeriodType=${period}`,

      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  GetsSalesExcel(
    start,
    finish,
    merchant,
    status,
    itemsPerPage,
    currPage,
    period
  ) {
    return this.http.get(
      `/api/invoicing/ExportToExcel?` +
        `StartDate=` +
        start +
        `&EndDate=` +
        finish +
        `&MerchantId=` +
        merchant +
        `&State=` +
        status +
        `&FetchCount=` +
        itemsPerPage +
        `&FetchPage=` +
        currPage +
        `&period=` +
        period +
        "&BuyerEmail=&InvoiceNumber=",

      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  // Invoice

  GetsInvoiceHistoryByAdmin(
    start,
    finish,
    merchant,
    status,
    inumber,
    iemail,
    itemsPerPage,
    currPage,
    period
  ) {
    let body = {};
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Invoicing/Gets?` +
          `StartDate=` +
          start +
          `&EndDate=` +
          finish +
          `&MerchantId=` +
          merchant +
          `&State=` +
          status +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currPage +
          `&period=` +
          period +
          `&BuyerEmail=` +
          iemail +
          `&InvoiceNumber=` +
          inumber,

        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  //  getAppVersionsList() {
  //   return this.http
  //     .get<any>(this.authService.basUrl +`/api/Rating/GetsRatingEntity`, {
  //       headers: { "Accept-Language": this.GetLang() },
  //     })
  //     .pipe(
  //       map((response) => {
  //         if (this.authService.checkTokenResponse()) {
  //           return response;
  //         }
  //       })
  //     );
  // }
  GetsInvoiceHistoryByAdminPdf(start, finish, merchant, status, period) {
    return this.http.get(
      this.authService.basUrl +
        `/api/Invoicing/ExportToPdf?` +
        `StartDate=` +
        start +
        `&EndDate=` +
        finish +
        `&MerchantId=` +
        merchant +
        `&State=` +
        status +
        `&period=` +
        period +
        "&BuyerEmail=&InvoiceNumber=",

      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  GetsInvoiceHistoryByAdminExcel(start, finish, merchant, status, period) {
    return this.http.get(
      this.authService.basUrl +
        `/api/invoicing/ExportToExcel?` +
        `StartDate=` +
        start +
        `&EndDate=` +
        finish +
        `&MerchantId=` +
        merchant +
        `&State=` +
        status +
        `&period=` +
        period +
        "&BuyerEmail=&InvoiceNumber=",

      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  getAppVersionsList() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Rating/GetsRatingEntity`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getBundleVersionsList() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Rating/GetsRatingVersion`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  //Campaigns

  getCampaigns(status, text, currentPage, paginationItemPerPage, lang) {
    let langKey: string = "";

    if (lang === "tr") {
      langKey = "69ee6082-77d3-417c-b6f7-c64ed1b5c691";
    } else if (lang === "en") {
      langKey = "ae10158f-17cd-4974-ad98-c15774b2b3e8";
    } else if (lang === "ru") {
      langKey = "a270d233-1c45-4550-b48f-68c7424303c2";
    }

    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Campaign/Gets?&pStatus=${status}&pDescription=${text}&pFetchPage=${currentPage}&pFetchCount=${paginationItemPerPage}&pLanguageKey=${langKey}`,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // getTypeFilterList(key){
  //   return this.http
  //   .get<any>(
  //     this.authService.basUrl +
  //       "/api/Campaign/GetTypeFilter",
  //     { headers: { "Accept-Language": this.GetLang() } }
  //   )
  //   .pipe(
  //     map((response) => {
  //       if (this.authService.checkTokenResponse()) {
  //         return response;
  //       }
  //     })
  //   );

  // }

  getCampaignsInfo(key, lang) {
    //lang = lang ==="" ? null : lang;
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Campaign/Get?id=${key}&pLanguageKey=${lang}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  saveImageCard(key, type, image) {
    let body = {
      SimpleKey: key,
      Base64Data: image,
      MimeType: type,
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/Campaign/SaveImage`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  AddOrUpdateCard(
    descriptionfull,
    descriptionshort,
    name,
    key,
    stringKey,
    codeImage,
    mimeType,
    lang,
    status
  ) {
    const body = {
      Name: name,
      DescriptionShort: descriptionshort,
      DescriptionFull: descriptionfull,
      LanguageKey: lang,
      Key: key,
      Status: status,
      stringKey: stringKey,
      Request: {
        Base64Data: codeImage,
        MimeType: mimeType,
      },
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/Campaign/AddOrUpdate`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  GetLangList() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Campaign/GetLang`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getRatingReportDatas(
    start,
    finish,
    bundleVersion,
    appVersion,
    rating,
    username,
    itemsPerPage,
    currentPage,
    period
  ) {
    // if (rating == null) rating = 0;
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/rating/Gets?StartDate=` +
          start +
          `&EndDate=` +
          finish +
          `&pRatingVersionKey=` +
          appVersion +
          `&pRatingEntityKey=` +
          bundleVersion +
          `&pRatingValue=` +
          rating +
          `&pUserName=` +
          username +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currentPage +
          `&PeriodType=` +
          period,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // start,
  //           finish,
  //           this.FilterForm.value.AppVersion,
  //           this.FilterForm.value.BundleVersion,
  //           this.FilterForm.value.Ratings,
  //           this.FilterForm.value.UserName,
  //           period,
  //           format
  getRatingFile(
    start,
    finish,
    bundleVersion,
    appVersion,
    rating,
    username,
    period,
    format
  ) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/rating/ExportPdf?StartDate=` +
          start +
          `&EndDate=` +
          finish +
          `&pRatingVersionKey=` +
          appVersion +
          `&pRatingEntityKey=` +
          bundleVersion +
          `&pRatingValue=` +
          rating +
          `&pUserName=` +
          username +
          `&PeriodType=` +
          period,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/rating/ExportExcel?StartDate=` +
        start +
        `&EndDate=` +
        finish +
        `&pRatingVersionKey=` +
        appVersion +
        `&pRatingEntityKey=` +
        bundleVersion +
        `&pRatingValue=` +
        rating +
        `&pUserName=` +
        username +
        `&PeriodType=` +
        period,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  // CheckOut Report

  getCheckOutDatas(
    start,
    finish,
    id,
    appId,
    userId,
    externalId,
    referenceId,
    cardPan,
    RRN,
    status,
    itemsPerPage,
    currentPage,
    period
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Checkout/Gets?startDate=` +
          start +
          `&endDate=` +
          finish +
          `&periodType=` +
          period +
          `&appId=` +
          appId +
          `&externalId=` +
          externalId +
          `&referenceId=` +
          referenceId +
          `&rrn=` +
          RRN +
          `&id=` +
          id +
          `&status=` +
          status +
          `&cardPan=` +
          cardPan +
          `&userId=` +
          userId +
          `&currentPage=` +
          currentPage +
          `&itemsPerPage=` +
          itemsPerPage
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getDetails(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Checkout/Get?id=` + id)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getLog(key) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Checkout/GetLog?id=` + key)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getCheckoutFile(
    start,
    finish,
    id,
    appId,
    userId,
    externalId,
    referenceId,
    cardPan,
    RRN,
    status,
    itemsPerPage,
    currentPage,
    period
  ) {
    return this.http.get(
      this.authService.basUrl +
        `/api/Checkout/Export?startDate=` +
        start +
        `&endDate=` +
        finish +
        `&periodType=` +
        period +
        `&appId=` +
        appId +
        `&externalId=` +
        externalId +
        `&referenceId=` +
        referenceId +
        `&rrn=` +
        RRN +
        `&id=` +
        id +
        `&status=` +
        status +
        `&cardPan=` +
        cardPan +
        `&userId=` +
        userId +
        `&currentPage=` +
        currentPage +
        `&itemsPerPage=` +
        itemsPerPage,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  // Invoice

  getInvoiceHistory(
    period,
    start,
    finish,
    status,
    invoiceEmail,
    invoiceNumber,
    itemsPerPage,
    currPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/GetHistory/?PeriodType=` +
          period +
          `&DateStart=` +
          start +
          `&DateFinish=` +
          finish +
          `&Status=` +
          status +
          `&BuyerEmail=` +
          invoiceEmail +
          `&InvoiceNumber=` +
          invoiceNumber +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currPage
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // Transactions Datas

  // For get datas in all pages of table
  getTransactionsDatasCurrentPage(
    date_from,
    date_to,
    filter,
    dealerId,
    currentPage,
    item_per_page
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/Transactions?DateFrom=` +
          date_from +
          `&DateTo=` +
          date_to +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&DealerId=" +
          dealerId +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&Status=&RefStan=" +
          filter.RefStan +
          "&SalePointId=" +
          filter.ChannelDrop.Value +
          "&OpType=" +
          filter.PaidTypeDrop +
          "&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // For get advance datas in modal.
  getAdvanceTransaction(refstan) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/report/Transaction/` + refstan)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getFile(date_from, date_to, filter, currentPage, item_per_page, format) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/report/TransactionListToPDF?DateFrom=` +
          date_from +
          `&DateTo=` +
          date_to +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&DealerId=" +
          filter.Dealer +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&Status=&RefStan=" +
          filter.RefStan +
          "&SalePointId=" +
          filter.ChannelDrop +
          "&OpType=" +
          filter.PaidTypeDrop +
          "&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/report/TransactionListToExcel?DateFrom=` +
        date_from +
        `&DateTo=` +
        date_to +
        "&Account=" +
        filter.Account +
        "&UserName=" +
        filter.UserName +
        "&DealerId=" +
        filter.Dealer +
        "&CompanyId=" +
        filter.ServiceDrop +
        "&Status=&RefStan=" +
        filter.RefStan +
        "&SalePointId=" +
        filter.ChannelDrop +
        "&OpType=" +
        filter.PaidTypeDrop +
        "&CurPage=" +
        currentPage +
        "&ItemPerPage=" +
        item_per_page,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
    // .pipe(map(res => {
    //     console.log("getExcell !!!");
    //     console.log(res);
    //     return {
    //         filename: 'filename.pdf',
    //         data: res.blob()
    //     };
    // }));
  }

  // Problem-payments

  getProblemrequestDatas(
    dateFrom,
    dateTo,
    filter,
    dealerId,
    currentPage,
    item_per_page
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/ProblemPaymentsReport?DateFrom=` +
          dateFrom +
          `&DateTo=` +
          dateTo +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&Dealer=" +
          dealerId +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&Status=-1&RefStan=&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAdvanceProblem(refstan, dateFrom, dateTo) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/ProblemPaymentInfo?pRefStan=` +
          refstan +
          "&pDateStart=" +
          dateFrom +
          "&pDateFinish=" +
          dateTo
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // Deposit operations

  getAllDeposit(dealerId, dateFrom, dateTo, curPage, itemPerPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/DepositReport?pDealerId=` +
          dealerId +
          "&pDateStart=" +
          dateFrom +
          "&pDateFinish=" +
          dateTo +
          "&CurPage=" +
          curPage +
          "&ItemPerPage=" +
          itemPerPage
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // System Request

  getSystemDatas(
    dateFrom,
    dateTo,
    filter,
    dealerId,
    currentPage,
    item_per_page
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/SystemRequestsReport?DateFrom=` +
          dateFrom +
          `&DateTo=` +
          dateTo +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&DealerId=" +
          dealerId +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&ResponseCode=&ExternalId=&MethodType=&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // // For get advance datas in modal.
  getAdvanceSystem(pKey) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/report/SystemRequestInfo?pKey=` + pKey
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //NEW API

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
  })
  getAccountCodes() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Accounting/Gets`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getMerchantList() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/List`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getStatementsDatas(
    MerchantId,
    AccountCode,
    DateStart,
    DateFinish,
    period,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Accounting/Statement?MerchantId=` +
          MerchantId +
          `&AccountCode=` +
          AccountCode +
          `&DateStart=` +
          DateStart +
          `&DateFinish=` +
          DateFinish +
          `&PeriodType=` +
          period +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getStatementsPDF(
    AccountCode,
    DateStart,
    DateFinish,
    period,
    ItemsPerPage,
    CurrentPage,
    MerchantId
  ) {
    return this.http.get(
      this.authService.basUrl +
        `/api/Accounting/StatementToPdf?AccountCode=` +
        AccountCode +
        `&DateStart=` +
        DateStart +
        `&DateFinish=` +
        DateFinish +
        `&PeriodType=` +
        period +
        `&FetchCount=` +
        ItemsPerPage +
        `&FetchPage=` +
        CurrentPage +
        `&MerchantId=` +
        MerchantId,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getWithdrawDatas(
    MerchantId,
    DateStart,
    DateFinish,
    period,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Withdraw/Gets?MerchantId=` +
          MerchantId +
          `&StartDate=` +
          DateStart +
          `&FinishDate=` +
          DateFinish +
          `&PeriodType=` +
          period +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getWithdrawPDF(merchantId, start, finish, period, format) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/Withdraw/ExportPdf?StartDate=` +
          start +
          `&FinishDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&MerchantId=` +
          merchantId,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/Withdraw/ExportExcel?DateStart=` +
        start +
        `&DateEnd=` +
        finish +
        `&PeriodType=` +
        period +
        `&MerchantId=` +
        merchantId,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getAquairingPDF(
    start,
    finish,
    period,
    format,
    operId,
    state,
    MerchantId,
    ExternId
  ) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/AcquiringReports/ExportPdf?StartDate=` +
          start +
          `&EndDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&OperationId=` +
          operId +
          `&State=` +
          state +
          `&MerchCode=` +
          MerchantId +
          `&ExternalId=` +
          ExternId,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/Withdraw/ExportExcel?StartDate=` +
        start +
        `&EndDate=` +
        finish +
        `&PeriodType=` +
        period +
        `&OperationId=` +
        operId +
        `&State=` +
        state +
        `&MerchCode=` +
        MerchantId +
        `&ExternalId=` +
        ExternId,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getWithdrawInfo(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Withdraw/Get?RequestId=` + id, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  saveWithdrawInfo(id, rrn, text, state) {
    let body = {
      RequestId: id,
      RRN: rrn,
      Note: text,
      Action: state,
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/Withdraw/Proccess/`, body, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getTransactionsDatas(
    start,
    finish,
    period,
    itemsPerPage,
    currentPage,
    order,
    merchantId,
    status,
    bank,
    card,
    salePointType,
    merchantCode,
    rrn
  ) {
    if (merchantId === undefined) {
      merchantId = -1;
    }
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Payment/Gets?pStartDate=` +
          start +
          `&pEndDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&pFetchCount=` +
          itemsPerPage +
          `&pFetchPage=` +
          currentPage +
          `&pOrderNumber=` +
          order +
          `&pMerchantID=` +
          merchantId +
          `&pStatus=${status}&pBankId=${bank}&pPan=${card}&pRRN=${rrn}&SalePaymentType=${salePointType}`+
          `&pMerchantCode=${merchantCode}`,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getTransactionInfo(id) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Ecom/GetDetail?OperationId=` + id,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getTransactionItem(id, checkid) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Payment/Get?pPaymentID=` +
          id +
          "&pCheckoutID=" +
          checkid,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // start,
  //           finish,
  //           this.FilterForm.value.AppVersion,
  //           this.FilterForm.value.BundleVersion,
  //           this.FilterForm.value.Ratings,
  //           this.FilterForm.value.UserName,
  //           period,
  //           format
  getTransactionsFile(
    start,
    finish,
    period,
    operId,
    status,
    merchantId,
    format
  ) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/Ecom/ExportPdf?DateStart=` +
          start +
          `&DateEnd=` +
          finish +
          `&PeriodType=` +
          period +
          `&OperationID=` +
          operId +
          `&Status=` +
          status +
          `&MerchantId=` +
          merchantId,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/Ecom/ExportExcel?DateStart=` +
        start +
        `&DateEnd=` +
        finish +
        `&PeriodType=` +
        period +
        `&OperationID=` +
        operId +
        `&Status=` +
        status +
        `&MerchantId=` +
        merchantId,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  getPaymentFile(
    start,
    finish,
    period,
    operId,
    merchantId,
    status,
    bank,
    card,
    salePayType,
    merchantCode,
    rrn, 
    format
  ) {
    if (merchantId === undefined) {
      merchantId = -1;
    }
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/Payment/Export?pStartDate=` +
          start +
          `&pEndDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&pOrderNumber=` +
          operId +
          `&pMerchantID=` +
          merchantId +
          "&ExportType=" +
          0 +
          `&pStatus=${status}&pBankId=${bank}&pPan=${card}&pRRN=${rrn}&SalePaymentType=${salePayType}`+
          `&pMerchantCode=${merchantCode}`,

        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/Payment/Export?pStartDate=` +
        start +
        `&pEndDate=` +
        finish +
        `&PeriodType=` +
        period +
        `&pOrderNumber=` +
        operId +
        `&pMerchantID=` +
        merchantId +
        "&ExportType=" +
        1 +
        `&pStatus=${status}&pBankId=${bank}&pPan=${card}&pRRN=${rrn}&SalePaymentType=${salePayType}`+ 
         `&pMerchantCode=${merchantCode}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  getAquairingInfo(id) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/AcquiringReports/GetAcquiringDetails?OperationID=` +
          id,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getAquairingDatas(
    start,
    finish,
    period,
    operId,
    state,
    MerchantId,
    ExternId,
    ItemsPerPage,
    CurrentPage
  ) {
    let body = {
      StartDate: start,
      EndDate: finish,
      PeriodType: period,
      OperationId: operId,
      State: state,
      MerchCode: MerchantId,
      ExternalId: ExternId,
      Paging: {
        ItemsPerPage: ItemsPerPage,
        CurrentPage: CurrentPage,
      },
    };
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/AcquiringReports/GetAcquiringReport/`,
        body,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getRoles(code, orderBy, itemsPerPage, currentPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Administration/GetRoles?Code=` +
          code +
          `&OrderBy=` +
          orderBy +
          `&PageNumber=` +
          currentPage +
          `&ItemCount=` +
          itemsPerPage,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getRights() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Administration/GetRights`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getRoleInfo(id) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Administration/GetRoleInfo?RoleKey=` +
          id,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  saveRoles(previous_datas, new_datas, roleList, flag) {
    //flag = true - new, false = edit.
    let body;
    if (flag === false) {
      body = {
        Name: new_datas.Name,
        Order: previous_datas.Order,
        CodeNum: previous_datas.CodeNum,
        Description: new_datas.Description,
        Key: previous_datas.Key,
        Code: new_datas.Code,
        Rights: roleList,
      };
    } else {
      body = {
        Name: new_datas.Name,
        Order: new_datas.Order,
        CodeNum: new_datas.CodeNum,
        Description: new_datas.Description,
        Key: null,
        Code: new_datas.Code,
        Rights: roleList,
      };
    }
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/Administration/SaveRole/`,
        body,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
