import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {DateAdapter} from '@angular/material/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DataSource } from '@angular/cdk/table';
import { ReportsDatasService } from '../../_services/reportsdata.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../globals/globals';


@Component({
    templateUrl: 'psp-aquairing.component.html',
    styleUrls: ['../reports/reports.scss'],
    providers: [DatePipe, Globals]
})


export class PSPAquairingComponent implements OnInit {
    public paginationItems; // for display pagination from api
    public paginationItemPerPage; // for display pagination from api
    saveWithdrawError = '';
    permissions;
    modalDatas = {
        AcquiringOperation: {
            Amount: 0,
            AmountTotal: 0,
            Currency: 0,
            Description: "",
            ExpireDate: "",
            ExternalId: 0,
            Fee: 0,
            Id: 0,
            Info: null,
            Key: "",
            MerchantCode: "",
            PaidDate: "",
            RegDate: "",
            ReverseDate: null,
            State: 0,
            UserKey: "",
            UserLogin: "",
        },
        CheckoutList: null,
        InterationLogList: null
    };
    active_step = 1;
    activeLogo = {
        RequestBody: '',
        RequestContent: '',
        ResponseContent: ''
    };
    public currentPage = 0;
    isLoadingDetailInfo: boolean = true; // spinner
    isDownloadingFile: boolean = false; // spinner
    Info;
    radioModel = 1;
    loading = false; // for disable fast response click
    disableButtonSave = true; // for disable fast response click
    pageEvent; // for fix error of pageEvent in Visual Studio
    panelOpenState = true;
    // For change pages
    minDate = new Date(2000, 0, 1); // Date picker min date
    maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
    events: string[] = []; // Date picker
    currentTime = new Date();
    allPageLoad: boolean = false;
    MerchantList = [
        { id: '', Name: '' }
    ];

    displayedColumns: string[] = ['Id', 'MerchantCode', 'User', 'Amount', 'Fee', 'AmountTotal', 'RegDate', 'PayDate', 'Info', 'Status', 'ExternId'];

    openLogos(index) {
        this.activeLogo = {
            RequestBody: '',
            RequestContent: '',
            ResponseContent: ''
        };
        if (index === null) {
            this.active_step = 1;
        }
        else {
            this.activeLogo = this.modalDatas.InterationLogList[index];
            this.active_step = 2;
        }
    }

    statusLists = [
        {
            text: 'Init',
            color: 'badge-warning',
            value: 0
        },
        {
            text: 'Pending',
            color: 'badge-success',
            value: 1
        },
        {
            text: 'Error',
            color: 'badge-danger',
            value: 2
        },
        {
            text: 'Pay',
            color: 'badge-success',
            value: 3
        },
        {
            text: 'Refund',
            color: 'badge-danger',
            value: 4
        },
        {
            text: 'Cancel',
            color: 'badge-secondary',
            value: 5
        },
        {
            text: 'Close',
            color: 'badge-secondary',
            value: 6
        },
        {
            text: 'Refund Partial',
            color: 'badge-danger',
            value: 7
        },
    ];


    dataSource = new MatTableDataSource(); // array of datas
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog,
        // Make service for post api datas
        public translate: TranslateService,
        private reportsDataService: ReportsDatasService,
        private datePipe: DatePipe,
        public route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private globals: Globals
    ) {

    }
    FilterForm: UntypedFormGroup; // Form initial
    EditForm: UntypedFormGroup;

    MerchantsGets() {
        this.reportsDataService.getMerchantList().pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        this.MerchantList = data.ReturnObject;
                        this.translate.get('REPORT.ALL_MERCHANTS_LBL').subscribe((text) => {
                            this.MerchantList.unshift({
                                id: 'null',
                                Name: text
                            });
                        });
                        this.FilterForm.patchValue({ ['MerchantId']: this.MerchantList[0].id });
                    }
                },
                error => {
                    console.log(error);
                },
                () => {
                    this.allPageLoad = true;
                    this.getFirstDatas(this.radioModel);
                });
    }
    makePDF() {
        if (this.loading === false && this.isDownloadingFile === false) {
            let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
            let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
            this.isDownloadingFile = true;
            let merchId = null;
            if (this.FilterForm.value.MerchantId !== 'null') {
                merchId = this.FilterForm.value.MerchantId;
            }
            this.reportsDataService.getAquairingPDF(start, finish, this.radioModel, 'pdf', this.FilterForm.value.OperId, this.FilterForm.value.State, merchId, this.FilterForm.value.ExternId).pipe(first())
                .subscribe(
                    res => {
                        this.isDownloadingFile = false;
                        saveAs(res, "PspAquiring_" + this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-dd"
      ) + "." + "pdf", {
                          type: "application/pdf",
                        });
                    },
                    error => {
                        console.log(error);
                        this.isDownloadingFile = false;
                    });
        }
    }
    CloseDialog(): void {
        this.dialog.closeAll();
    }
    openInfo(templateRef, id) {
        this.active_step = 1;
        this.loading = true;
        this.disableButtonSave = true;
        this.isLoadingDetailInfo = true;
        const dialogRef = this.dialog.open(templateRef, {});
        this.reportsDataService.getAquairingInfo(id).pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        this.modalDatas = data.ReturnObject;
                    }
                    this.isLoadingDetailInfo = false;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this.isLoadingDetailInfo = false;
                    console.log(error);
                });
    }

    getFirstDatas(period) {
        this.loading = true;
        let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
        let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
        let merchId = null;
        this.radioModel = period;
        if (this.FilterForm.value.MerchantId !== 'null') {
            merchId = this.FilterForm.value.MerchantId;
        }
        this.reportsDataService.getAquairingDatas(start, finish, period, this.FilterForm.value.OperId, this.FilterForm.value.State, merchId, this.FilterForm.value.ExternId, this.paginationItemPerPage, this.currentPage + 1).pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    console.log(this.dataSource.data);
                    let datas = [];
                    this.paginationItems = 0;
                    if (data.ResultCode === 0) {
                        if (data.ReturnObject !== null) {
                            datas = data.ReturnObject.Operations;
                            this.paginationItems = data.ReturnObject.Paging.TotalItems;
                        }
                    }
                    this.dataSource = new MatTableDataSource(datas); // array of datas	
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log(error);
                });
    }

    //----------------------
    PaginationCurentPageReset(e: any) {
        this.currentPage = 0;
    }
    //------------------- private helpers 
    // convenience getter for easy access to form fields
    get f() { return this.FilterForm.controls; }

    public handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.paginationItemPerPage = e.pageSize;
        this.getFirstDatas(this.radioModel);
    }

    ngOnInit() {
        let ls = new SecureLS(); // for Secure Local storage
        if (ls.get('info') !== null) {
            this.Info = ls.get('info');
        }
        this.permissions = ls.get('permissions')[this.route.data['value'].pathCheck];

        this.paginationItemPerPage = 10;
        this.FilterForm = new UntypedFormGroup({
            'dateFrom': new UntypedFormControl({ value: this.currentTime, disabled: true }, Validators.required),
            'dateTo': new UntypedFormControl({ value: this.currentTime, disabled: true }, Validators.required),
            'MerchantId': new UntypedFormControl('', Validators.required),
            'OperId': new UntypedFormControl(''),
            'State': new UntypedFormControl(''),
            'ExternId': new UntypedFormControl('')
        }); // for header Filter
        this.EditForm = new UntypedFormGroup({
            'Description': new UntypedFormControl('', Validators.required),
            'RRN': new UntypedFormControl('', [Validators.required, Validators.pattern(/^([0-9]*)$/)]),
            'Status': new UntypedFormControl('', Validators.required),
        }); // for header Filter

        this.translate.get('REPORT.STATUS_INIT').subscribe((text) => { this.statusLists[0].text = text; }); // Text in component
        this.translate.get('REPORT.STATUS_PENDING').subscribe((text) => { this.statusLists[1].text = text; }); // Text in component
        this.translate.get('REPORT.STATUS_ERROR').subscribe((text) => { this.statusLists[2].text = text; }); // Text in component
        this.translate.get('REPORT.STATUS_PAY').subscribe((text) => { this.statusLists[3].text = text; }); // Text in component
        this.translate.get('REPORT.STATUS_REFUND').subscribe((text) => { this.statusLists[4].text = text; }); // Text in component
        this.translate.get('REPORT.STATUS_CANCEL').subscribe((text) => { this.statusLists[5].text = text; }); // Text in component
        this.translate.get('REPORT.STATUS_CLOSE').subscribe((text) => { this.statusLists[6].text = text; }); // Text in component
        this.translate.get('REPORT.STATUS_REFUNDPARTIAL').subscribe((text) => { this.statusLists[7].text = text; }); // Text in component

        this.MerchantsGets();

    }
}