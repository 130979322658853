import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../../_services'

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ToastrService } from 'ngx-toastr'

import { DataSource } from '@angular/cdk/table'
import { MerchantTemplateService } from '../../../_services/merchant-templates.service'
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { Globals } from '../../../globals/globals'
import * as internal from 'stream'

interface CommissionSettingsItem {
	InstallmentId: number
	Value: string
	Type: number
	changed?: boolean
}
@Component({
	templateUrl: 'template.component.html',
	styleUrls: [
		'template.component.scss',
		'../campaigns/campaigns.component.scss',
		'../../reports/reports.scss',
		'../../administation/administration.scss',
	],
	providers: [DatePipe, Globals],
})
export class TemplateComponent implements OnInit {
	public paginationTotalItems = 0 // for display pagination from api
	public paginationItemPerPage = 10 // for display pagination from api
	public paginationCurrentPage = 0

	permissions
	isLoadingDetailInfo: boolean = true // spinner
	loading = false // for disable fast response click
	whowing_action = 'list'
	// For change pages
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	currentTime = new Date()
	allPageLoad: boolean = false
	isCommissionSettingsExpanded: boolean = false
	finally_array = []
	radioBoxValue = 2
	commissionSettingsList: CommissionSettingsItem[] = []

	commissionSatusList = [
		{ Value: 0, Name: 'Disable' },
		{ Value: 1, Name: 'Enable' },
	]
	commissionTypeList = [
		{ Value: 0, Name: 'No' },
		{ Value: 1, Name: '%' },
		{ Value: 2, Name: 'Fixed' },
	]

	modalDatas = {
		Status: 0,
		Id: '0',
		Key: '',
		Code: '',
		Commissions: [],
		CommissionsSettings: [],
		Increment: new Date().getMilliseconds(),
		WorkStyle: 1,
	} // for display datas in modal

	displayedColumns: string[] = [
		'Id',
		'RegDate',
		'Name',
		'Description',
		'UserName',
		// "Status",
		'LastDateUpdate',
		'Actions',
	]

	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		public toastr: ToastrService,
		// Make service for post api datas
		public translate: TranslateService,
		private dataService: MerchantTemplateService,
		public route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder
	) {}
	FilterForm: UntypedFormGroup // Form initial
	ModelForm: UntypedFormGroup // Form initial
	//------------------------------------------ get list of records from service ----------------------------------
	getItems() {
		let filter_name = ''
		let filter_user_name = ''
		if (this.FilterForm.value.TemplateName !== undefined) {
			filter_name = this.FilterForm.value.TemplateName
		}

		if (this.FilterForm.value.UserName !== undefined) {
			filter_user_name = this.FilterForm.value.UserName
		}

		this.dataService
			.getItems(
				filter_user_name,
				filter_name,
				this.paginationItemPerPage,
				this.paginationCurrentPage + 1
			)
			.subscribe(
				(data) => {
					console.log(data)
					let datas = []
					if (data.ResultCode === 0 && data.ReturnObject !== null) {
						datas = data.ReturnObject.Items
						this.paginationTotalItems = data.ReturnObject.Fetch.Cnt
					}
					this.dataSource = new MatTableDataSource(datas) // array of datas
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	//-------------------------- pagination  handler
	public handlePage(e: any) {
		this.paginationCurrentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getItems()
	}
	//-------------- dialog handler
	CloseDialog(): void {
		this.dialog.closeAll()
	}

	openInfoNew(templateRef, id) {
		this.isLoadingDetailInfo = true
		this.modalDatas.CommissionsSettings = []
		this.dialog.open(templateRef, {})
		this.modalDatas.Id = id
		this.dataService.getItem(id).subscribe(
			(data) => {
				console.log(data)
				let datas = []
				if (data.ResultCode === 0 && data.ReturnObject !== null) {
					this.modalDatas.Commissions =
						data.ReturnObject.CommissionSettings
					this.commissionSettingsList =
						data.ReturnObject.InstallmentSettings
					this.modalDatas.CommissionsSettings =
						this.commissionSettingsList.map(
							(x) =>
								new Object({
									InstallmentId: x.InstallmentId,
									Value: x.Value
										? (parseFloat(x.Value) / 100).toFixed(2)
										: '0.00',
									changed: false,
									Type: x.Type,
								})
						)
					this.modalDatas.WorkStyle =
						data.ReturnObject.WorkStyle.toString()
					this.ModelForm.patchValue({
						TemplateDescription: data.ReturnObject.Description,
						TemplateName: data.ReturnObject.Name,
						WorkStyle: data.ReturnObject.WorkStyle.toString(),
					})
					//-------------------------
					this.DataRefresh(this.modalDatas)
				}

				this.isLoadingDetailInfo = false
			},
			(error) => {
				this.isLoadingDetailInfo = false
				console.log(error)
			}
		)
	}

	removeFlag: boolean = true
	DataRefresh(datas) {
		if (datas !== null) {
			if (this.modalDatas.Commissions.length) {
				this.modalDatas.Commissions.forEach((commission) => {
					this.ModelForm.removeControl(
						'Commission_DirectFee_' + commission.ProviderId
					)
					this.ModelForm.removeControl(
						'Commission_PointsDay_' + commission.ProviderId
					)
					this.ModelForm.removeControl(
						'Commission_Status_' + commission.ProviderId
					)

					commission.Changed = 0
					this.ModelForm.addControl(
						'Commission_ServiceName_' + commission.ProviderId,
						new UntypedFormControl(commission.ProviderName)
					)
					this.ModelForm.addControl(
						'Commission_DirectFee_' + commission.ProviderId,
						new UntypedFormControl(
							(commission.DirectFee / 100).toFixed(2),
							[Validators.min(0), Validators.max(100.0)]
						)
					) // Initialize form rules
					this.ModelForm.addControl(
						'Commission_PointsDay_' + commission.ProviderId,
						new UntypedFormControl(commission.BlockedDays, [
							Validators.max(999),
							Validators.min(0),
						])
					) // Initialize form rules
					this.ModelForm.addControl(
						'Commission_Status_' + commission.ProviderId,
						new UntypedFormControl(commission.Status)
					) // Initialize form rules
				})
			}
			//-------------
			if (datas.CommissionsSettings.length > 0) {
				let _flag = this.removeFlag
				for (let i = 0; i < datas.CommissionsSettings.length; i++) {
					let _name =
							'Commission_Setting_' +
							datas.CommissionsSettings[i].InstallmentId,
						_val = datas.CommissionsSettings[i].Value
					if (_flag) {
						this.ModelForm.addControl(
							_name,
							new UntypedFormControl(_val, [
								Validators.min(0),
								Validators.max(100),
							])
						)
						if (i === datas.CommissionsSettings.length - 1) {
							_flag = false
						}
					} else {
						this.ModelForm.patchValue({
							[_name]: _val,
						})
					}
				}
				this.removeFlag = _flag
				this.ModelForm.updateValueAndValidity()
			}
			for (var index in this.ModelForm.value) {
				var attr = this.ModelForm.value[index]
				this.finally_array.push({
					Key: index,
					Value: attr,
					Level: index.split('_'),
					Changed: 0,
				})
			}
		}
	}
	/*
commissionSettingChanged(_id) {
  
  if (
    this.modalDatas &&
    this.modalDatas.CommissionsSettings.length &&
    this.modalDatas.CommissionsSettings.filter(
      (x) => x.InstallmentId == _id
    )[0]
  ) {
    this.modalDatas.CommissionsSettings.filter(
      (x) => x.InstallmentId == _id
    )[0].changed = true
  }
  console.log('Changed', this.modalDatas.CommissionsSettings)
  
}
*/
	convertOnBlur(searchValue, formName, formValue) {
		if (searchValue > 0) {
			this[formName].patchValue({
				[formValue]: parseFloat(searchValue)
					.toFixed(2)
					.replace(',', '.'),
			})
		} else {
			this[formName].patchValue({
				[formValue]: '0.00',
			})
		}
	}

	onKeyDown(event) {
		let allowCodes = [
			8, 9, 17, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56,
			57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 190,
		]
		if (event.target.value.indexOf('.') != -1) {
			allowCodes.pop()
		}
		if (allowCodes.indexOf(event.keyCode) == -1) {
			event.preventDefault()
		}
	}

	onKeyDownOnlyDigit(event) {
		let allowCodes = [
			8, 9, 17, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56,
			57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110,
		]

		if (allowCodes.indexOf(event.keyCode) == -1) {
			event.preventDefault()
		}
	}

	onInputNotNumber(event) {
		event.target.value = event.target.value.replace(/[^0-9.]/g, '')
	}

	focusFunction(event) {
		event.target.value == 0 ? (event.target.value = '') : event.target.value
	}

	makeChanged(target) {
		this.finally_array.find((x) => x.Key == target).Changed = 1
	}
	onWorkStyleChanged(event) {
		console.log(event, event.value)
		this.modalDatas.WorkStyle = event.value.toString()
	}
	//------------------------- save object or update
	onSaveObject() {
		if (this.ModelForm.valid === true) {
			let _commissions = []
			let _installs = []
			//--------------- prepairing data of instls
			this.modalDatas.CommissionsSettings.forEach((e) => {
				let _name = 'Commission_Setting_' + e.InstallmentId.toString()
				//if(this.ModelForm.controls[_name].touched )
				_installs.push({
					InstallmentId: e.InstallmentId,
					Value: this.ModelForm.controls[_name].value.replace(
						/\D+/g,
						''
					),
					Type: 1,
				})
			})
			//--------------- prepairing data of commission -------------
			this.modalDatas.Commissions.forEach((e) => {
				let _name_fee =
					'Commission_DirectFee_' + e.ProviderId.toString()
				let _name_days =
					'Commission_PointsDay_' + e.ProviderId.toString()
				let _name_status =
					'Commission_Status_' + e.ProviderId.toString()
				_commissions.push({
					ProviderId: e.ProviderId,
					DirectFee: this.ModelForm.controls[_name_fee].value * 100,
					DirectFeeType: 1,
					BlockedDays: this.ModelForm.controls[_name_days].value,
					Status: this.ModelForm.controls[_name_status].value,
				})
			})
			//-------------- save data on the server, create request --------------------------
			this.dataService
				.saveSettings(
					this.modalDatas.Id,
					this.ModelForm.value['TemplateName'],
					this.ModelForm.value['TemplateDescription'],
					this.ModelForm.value['WorkStyle'],
					_commissions,
					_installs
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.CloseDialog()
							let theme = ''
							this.translate
								.get('TOOLS.TEMPLATE_SAVE_SUCCESS')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.success(data.ResultMessage, theme)
							this.loading = false
							this.getItems()
						} else {
							this.loading = false
							let theme = ''
							this.translate
								.get('TOOLS.TEMPLATE_SAVE_ERROR')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							this.toastr.error(data.ResultMessage, theme)
						}
					},
					(error) => {
						this.loading = false
						console.log(error)
					}
				)
		}
	}

	//-------------------------- init of controller  ------------------------------
	ngOnInit() {
		this.isLoadingDetailInfo = true

		this.FilterForm = new UntypedFormGroup({
			TemplateName: new UntypedFormControl(''),
			UserName: new UntypedFormControl(''),
		})

		this.ModelForm = this.formBuilder.group({
			// IDNO: ["", [Validators.required, Validators.pattern(/^([0-9]{1,20})$/)]],
			TemplateName: ['', Validators.required],
			TemplateDescription: ['', Validators.required],
			WorkStyle: ['', Validators.required],
		})

		/*new FormGroup({    
      TemplateName: new FormControl(""),
      TemplateDescription: new FormControl(""),
    });
    */

		console.log('init template')
		console.log('status', this.route.snapshot.queryParams['st'])

		console.log('name', this.route.snapshot.queryParams['name'])
		this.FilterForm.controls['TemplateName'].setValue(
			this.route.snapshot.queryParams['name']
		)

		console.log('user_name', this.route.snapshot.queryParams['user_name'])
		this.FilterForm.controls['UserName'].setValue(
			this.route.snapshot.queryParams['user_name']
		)

		console.log('p', this.route.snapshot.queryParams['p'])
		if (this.paginationItemPerPage != null)
			this.paginationCurrentPage =
				this.route.snapshot.queryParams['p'] - 1

		console.log('c', this.route.snapshot.queryParams['c'])
		if (this.paginationItemPerPage != null)
			this.paginationItemPerPage = this.route.snapshot.queryParams['c']

		let ls = new SecureLS() // for Secure Local storage

		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]

		//------------------ get data
		this.loading = true
		this.getItems()
	}
}
