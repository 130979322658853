import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../../_services";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DataSource } from "@angular/cdk/table";
import { ReportsDatasService } from "../../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
import { saveAs } from "file-saver";

/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../../../globals/globals";

export interface Merchant {
  Key: string;
  Name: string;
}

@Component({
  templateUrl: "sales-report.component.html",
  styleUrls: ["../../reports/reports.scss"],
  providers: [DatePipe, Globals],
})
export class SalesReportComponent implements OnInit {
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  saveWithdrawError = "";
  permissions;
  radioModel = 1;
  public itemsPerPage = 10; // for display pagination from api
  public currentPage = 0;

  modalDatas = {
    ID: "",
    MerchantName: "",
    OperationAccountingTypeName: "",
    Registered: "",
    Confirmed: "",
    Fee: "",
    Amount: "",
    Status: 0,
    Processes: "",
    CurrencyCode: "TRY",
    BankDetails: {
      AccountName: "",
      AccountNumber: "",
      BankBranch: "",
      BankCode: "",
      CountryName: "",
      IBAN: "",
      TVA: "",
    },
  };
  isLoadingDetailInfo: boolean = true; // spinner
  isDownloadingFile: boolean = false; // spinner
  Info;
  loading = false; // for disable fast response click
  disableButtonSave = true; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  allPageLoad: boolean = false;
  MerchantList: Merchant[] = [{ Key: "", Name: "" }];

  displayedColumns: string[] = [
    "MerchantName",
    "UserName",
    "Code",
    "Amount",
    "MobTelephone",
    "DateReg",
    "DatePaid",
    "DateExpired",
    "Description",
    "Status",
    "Type",
  ];
  filteredOptions: Observable<Merchant[]>;

  displayFn(user?: Merchant): string | undefined {
    return user ? user.Name : undefined;
  }

  private _filter(name: string): Merchant[] {
    const filterValue = name.toLowerCase();
    return this.MerchantList.filter(
      (option) => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  whatBadge(name) {
    for (let i = 0; i < this.statusLists.length; i++) {
      if (this.statusLists[i].value == name) {
        return this.statusLists[i].color;
      }
    }
  }
  whatText(name) {
    for (let i = 0; i < this.statusLists.length; i++) {
      if (this.statusLists[i].value == name) {
        return this.statusLists[i].text;
      }
    }
  }
  statusLists = [
    {
      text: "All",
      color: "",
      value: null,
    },
    {
      text: "Unknown",
      color: "badge-warning",
      value: 0,
    },
    {
      text: "New",
      color: "badge-primary",
      value: 1,
    },
    {
      text: "Error",
      color: "badge-danger",
      value: 2,
    },
    {
      text: "Success",
      color: "badge-success",
      value: 3,
    },
    {
      text: "Cancel",
      color: "badge-secondary",
      value: 4,
    },
  ];
  typeList = [
    {
      text: "Simple",
      value: 0,
    },
    {
      text: "Statis",
      value: 1,
    },
    {
      text: "Dynamic",
      value: 2,
    },
  ];
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private reportsDataService: ReportsDatasService,
    private datePipe: DatePipe,
    public route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private globals: Globals
  ) {}

  FilterForm: UntypedFormGroup; // Form initial
  EditForm: UntypedFormGroup;

  makePDF() {
    if (this.loading === false && this.isDownloadingFile === false) {
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );
      this.isDownloadingFile = true;
      this.reportsDataService
        .GetsSalesPdf(
          start,
          finish,
          this.radioModel,
          this.FilterForm.value.Code,
          this.FilterForm.value.UserName,
          this.FilterForm.value.MerchantId,
          this.FilterForm.value.Status,
          this.FilterForm.value.Type,
          this.FilterForm.value.Phone,
          this.itemsPerPage,
          this.currentPage + 1
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(res, "SalesReport_" + this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-dd"
      ) + "." + "pdf", {
              type: "application/pdf",
            });
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  MerchantsGets() {
    this.reportsDataService.getMerchantList().subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          this.MerchantList = data.ReturnObject;
          this.translate.get("SALES.ALL_MERCHANTS_LBL").subscribe((text) => {
            this.MerchantList.unshift({
              Key: null,
              Name: text,
            });
          });
          this.FilterForm.patchValue({
            ["MerchantId"]: this.MerchantList[0].Key,
            ["Status"]: this.statusLists[0].value,
          });
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.allPageLoad = true;
        this.getFirstDatas(this.radioModel);
      }
    );
  }
  getFirstDatas(period) {
    this.loading = true;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    this.radioModel = period;

    this.reportsDataService
      .GetsSales(
        start,
        finish,
        period,
        this.FilterForm.value.Code,
        this.FilterForm.value.UserName,
        this.FilterForm.value.MerchantId,
        this.FilterForm.value.Status,
        this.FilterForm.value.Type,
        this.FilterForm.value.Phone,

        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .subscribe(
        (data) => {
          let datas = [];
          this.paginationItems = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Sales;
              this.paginationItems = data.ReturnObject.Fetch.Cnt;
            }
          }
          this.dataSource = new MatTableDataSource(datas); // array of datas
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }

  ngOnInit() {
    // console.log(this.permissions);

    let ls = new SecureLS(); // for Secure Local storage
    if (ls.get("info") !== null) {
      this.Info = ls.get("info");
    }
    this.permissions = ls.get("permissions")[
      this.route.data["value"].pathCheck
    ];
    // Unknown = 0,
    //         New = 1,
    //         Error = 2,
    //         Success = 3,
    //         Cancel = 4
    this.paginationItemPerPage = 10;
    this.translate.get("SALES.STATUS_ALL").subscribe((text) => {
      this.statusLists[0].text = text;
    });
    this.translate.get("SALES.STATUS_UNKNOWN").subscribe((text) => {
      this.statusLists[1].text = text;
    }); // Text in component
    this.translate.get("SALES.STATUS_NEW").subscribe((text) => {
      this.statusLists[2].text = text;
    }); // Text in component
    this.translate.get("SALES.STATUS_ERROR").subscribe((text) => {
      this.statusLists[3].text = text;
    }); // Text in component
    this.translate.get("SALES.STATUS_SUCCESS").subscribe((text) => {
      this.statusLists[4].text = text;
    }); // Text in component
    this.translate.get("SALES.STATUS_CANCEL").subscribe((text) => {
      this.statusLists[5].text = text;
    });
    this.translate.get("SALES.TT_SIMPLE_TEXT").subscribe((text) => {
      this.typeList[0].text = text;
    });
    this.translate.get("SALES.TT_STATIC_TEXT").subscribe((text) => {
      this.typeList[1].text = text;
    });
    this.translate.get("SALES.TT_DYNAMIC_TEXT").subscribe((text) => {
      this.typeList[2].text = text;
    });

    this.translate.get("SALES.TT_ALL_TEXT").subscribe((text) => {
      this.typeList.unshift({
        text: text,
        value: null,
      });
    });
    // Text in component
    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      MerchantId: new UntypedFormControl(""),
      Code: new UntypedFormControl(""),
      UserName: new UntypedFormControl(""),
      Status: new UntypedFormControl(""),
      Type: new UntypedFormControl(""),
      Phone: new UntypedFormControl(""),
    }); // for header Filter
    this.EditForm = new UntypedFormGroup({
      Description: new UntypedFormControl("", Validators.required),
      RRN: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(/^([0-9]*)$/),
      ]),
    }); // for header Filter

    this.MerchantsGets();
  }
}
