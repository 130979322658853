import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from "./authentication.service";


@Injectable()
export class CustomersService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}


  //Customers report
  getsCustomers(   
    start,
    finish,
    period,
    customernumber,
    phonenumber,
    status,
    idcardnumber,
    ItemsPerPage,
    CurrentPage
    )
    
    {
   

        return this.http
      .get<any>(this.authService.basUrl + `/api/Customer/Gets?startDate=${start}&endDate=${finish}&periodType=${period}&login=${customernumber}&passportId=${idcardnumber}&phoneNumber=${phonenumber}&status=${status}&currentPage=${CurrentPage}&itemsPerPage=${ItemsPerPage}`,  {
        headers: {
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
      }


      getsCustomersFile(
        start,
        finish,
        period,
        customernumber,
        phonenumber,
        status,
        idcardnumber,
        format
      ) {
        let _format = format == "pdf" ? 0 : 1;
        return this.http.get(
          this.authService.basUrl +
            `/api/Customer/Export?startDate=${start}&endDate=${finish}&periodType=${period}&login=${customernumber}&passportId=${idcardnumber}&phoneNumber=${phonenumber}&status=${status}&exportType=${_format}`,
          {
            responseType: "blob",
            headers: { "Accept-Language": this.GetLang() },
          }
        );
      }

      changeStatus(   
        key,
        status
        )
        
        {
       
    
            return this.http
            .post<any>(this.authService.basUrl + `/api/Customer/ChangeStatus?userKey=${key}&userStatus=${status}`, {
              headers: { "Content-Type": "application/json" },
            })
            .pipe(
              map((response) => {
                if (this.authService.checkTokenResponse()) {
                  return response;
                }
              })
            );
        }


//CustomersLog Report
  getsOperationLog(   
    start,
    finish,
    period,
    customernumber,
    phonenumber,
    status,
    error,
    idcardnumber,
    ItemsPerPage,
    CurrentPage
    )
    
    {
   

        return this.http
      .get<any>(this.authService.basUrl + `/api/Customer/Logs?startDate=${start}&endDate=${finish}&periodType=${period}&login=${customernumber}&passportId=${idcardnumber}&phoneNumber=${phonenumber}&status=${status}&error=${error}&currentPage=${CurrentPage}&itemsPerPage=${ItemsPerPage}`,  {
        headers: {
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
      }



      getsOperationLogFile(
        start,
        finish,
        period,
        customernumber,
        phonenumber,
        error,
        idcardnumber,
        format
      ) {
        let _format = format == "pdf" ? 0 : 1;
        return this.http.get(
          this.authService.basUrl +
            `/api/Customer/ExportLogs?startDate=${start}&endDate=${finish}&periodType=${period}&login=${customernumber}&passportId=${idcardnumber}&phoneNumber=${phonenumber}&error=${error}&exportType=${_format}`,
          {
            responseType: "blob",
            headers: { "Accept-Language": this.GetLang() },
          }
        );
      }





      private GetLang() {
        switch (localStorage.getItem("currentLang")) {
          case "en":
            return "en-US";
        }
        return "tr-TR";
      }
}

